/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import FilterCheckbox from './FilterCheckbox';
import { DialogContainerFiltersProps } from '../../../../modules/dashboard/residents/domain/ResidentsFilters';
import { filterStyles } from './styles';

const CheckboxesList: FC<DialogContainerFiltersProps> = ({
  checkedItems,
  handleChange,
  filterCheckboxes,
  generateLeaseForms = false
}) => {
  return (
    <div style={filterStyles.CheckBoxListContainer}>
      {filterCheckboxes.map((filter: any, idx: number) => (
        <div
          key={idx}
          style={
            generateLeaseForms ? filterStyles.TypoAndContainerForms : filterStyles.TypoAndContainers
          }>
          <div style={filterStyles.FilterPadding}>
            <FilterCheckbox
              label={filter}
              checkedItems={checkedItems}
              handleChange={handleChange}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxesList;
