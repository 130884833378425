/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

// material
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';

interface ResidentSearchProps {
  handleSearch: () => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ResidentSearch = ({ handleSearch, handleKeyDown, handleChange }: ResidentSearchProps) => {
  const defautlSearch = () => {
    const activeFilters = JSON.parse(localStorage.getItem('activeFilters') || '{}');

    return activeFilters?.search;
  };

  return (
    <div style={{ paddingTop: '20px', display: 'flex' }}>
      <FormControl sx={{ m: 1, marginLeft: 0 }} variant="outlined">
        <OutlinedInput
          onKeyDown={handleKeyDown}
          size="small"
          id="outlined-adornment-community"
          placeholder="Unit or Resident Name"
          defaultValue={defautlSearch()}
          name="community"
          onChange={handleChange('community')}
          aria-describedby="outlined-community-helper-text"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <FormHelperText id="outlined-community-helper-text"></FormHelperText>
      </FormControl>
      <div style={{ paddingTop: '10px' }}>
        <Button variant="outlined" onClick={() => handleSearch()}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default ResidentSearch;
