import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

const TableSkeletonRow = ({
  columnCount,
  rowSkeletonHeight,
  hideFirstColumn
}: {
  columnCount: number;
  rowSkeletonHeight: number;
  hideFirstColumn: boolean;
}) => {
  return (
    <TableRow>
      {[...Array(columnCount)].map((_, columnIndex) => (
        <TableCell
          key={columnIndex}
          style={{
            display: hideFirstColumn && columnCount === 0 ? 'none' : 'table-cell',
            width: `${100 / (columnCount - (hideFirstColumn ? 1 : 0))}%`,
            verticalAlign: 'middle',
            textAlign: 'center'
          }}>
          <Skeleton
            variant="text"
            width={80}
            height={rowSkeletonHeight}
            style={{ display: 'inline-block', margin: '0 5px' }}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableSkeletonRow;
