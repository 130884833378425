import React, { FC } from 'react';
import { NYC421CardProps } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsInformationCard';
import { leaseDetailsCardStyles } from './styles';

const NYC421Card: FC<NYC421CardProps> = ({ isRenewalPlan }) => {
  return (
    <div style={leaseDetailsCardStyles.BoxStyleNYC}>
      <div style={leaseDetailsCardStyles.BottomSeparationNYC}>
        <div style={leaseDetailsCardStyles.TitleFontNYC}>421-a Surcharge</div>
        <div style={leaseDetailsCardStyles.BodyFontNYC}>
          {isRenewalPlan ? (
            <>
              <span style={leaseDetailsCardStyles.SupStyle}>
                <sup>* </sup>{' '}
              </span>
              The resident will be able to see the 421-a Surcharge{' '}
              <div style={leaseDetailsCardStyles.PaddingLeftNYC}>
                {' '}
                details after generating the lease in DocuSign. Information around the 421-a
                Surcharge pertaining to <br /> the renewal can be found on the{' '}
                <span style={leaseDetailsCardStyles.FontBoldNYC}>Cover Letter</span>,<br />
                <span style={leaseDetailsCardStyles.FontBoldNYC}> Renewal Lease Form</span> and{' '}
                <span style={leaseDetailsCardStyles.FontBoldNYC}> 421-a Rider</span>.
              </div>
            </>
          ) : (
            <>
              <span style={leaseDetailsCardStyles.SupStyle}>
                <sup>* </sup>{' '}
              </span>
              Note: This value may have a scheduled rate change{' '}
              <span style={leaseDetailsCardStyles.PaddingLeftNYC}>
                {' '}
                prior to renewal lease start.
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NYC421Card;
