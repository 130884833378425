/* eslint-disable */
import React, { FC, useState, Fragment, useEffect } from 'react';

// Material
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

// @ts-ignore
import { leaseDetailsStyles } from './styles';
import {
  DialogDetailsType,
  LeaseStatusCardProps,
  disableForcedCompleteByCommunity,
  displayHoverAction,
  isForceCompleteEnabled,
  isVoidEnabled
} from '../../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';
import { LeaseDetails } from '../../../../modules/details/leaseDetails/domain/LeaseDetails';
import Dialog, { DialogType } from '../../../../shared/dialog/Dialog';
import InfoToolTip from '../../../../shared/tooltip/InfoTooltip';
// @ts-ignore
import info from '../../../../assets/imgs/i-icon.png';
import { TextInfoToolTip } from './TextInfoToolTip';

//navigation
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { displayConfirmation } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsDialogs';

const LeaseStatusCard: FC<LeaseStatusCardProps> = ({
  leaseDetails,
  leaseDetailsQuery,
  voidLease,
  voidLeaseQuery,
  forcedLeaseQuery,
  displayLeaseDetailsViewLocally,
  forcedLease
}) => {
  const [dialogDetails, setDialogDetails] = useState<DialogDetailsType>({
    type: DialogType.Confirm,
    headerLabel: '',
    body: '',
    btnLabel1: 'Cancel',
    btnLabel2: 'Confirm',
    isOpen: false,
    btnLabel2Callback: () => Promise.resolve()
  });
  const { id } = useParams();

  const handleVoidDocuSign = (docuRefId: string | null) => {
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirmation?',
      body: (
        <div>
          Are you sure you want to void this <b>Lease in DocuSign?</b>
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Confirm',
      btnLabel2Callback: async () => {
        voidLease(id, docuRefId);
      },
      handleCloseConfirmDialog: () =>
        setDialogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
  };

  const handleCloseConfirmDialog = () => {
    setDialogDetails({
      ...dialogDetails,
      isOpen: false
    });
  };

  const handleChildError = (errorDetails: any) => {
    setDialogDetails({
      ...dialogDetails,
      ...errorDetails,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleChildSuccess: any = (
    successDetails: any,
    successAction: () => void,
    btnLabel2Callback: any
  ) => {
    successAction();
    setDialogDetails({
      ...dialogDetails,
      ...successDetails,
      btnLabel2Callback: btnLabel2Callback,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const displayConfirmationForceDialogs = () => {
    if (forcedLeaseQuery?.data === undefined) {
      return;
    }

    displayConfirmation({
      query: forcedLeaseQuery,
      successCode: 204,
      successMessage: 'Completed Successfully!',
      successContent: <div>This Lease has been successfully marked Complete.</div>,
      errorMessage: 'Failed to Complete!',
      errorContent: (
        <div>
          'This action cannot be completed in the <b>Lease Generated</b> state. First void the lease
          in DocuSign before marking it complete.'
        </div>
      ),
      successAction: displayLeaseDetailsViewLocally,
      refetchCallback: () => {
        window.location.reload();
      },
      handleChildSuccess,
      handleChildError
    });

    forcedLease(null);
  };

  const displayConfirmationDialogVoid = () => {
    if (voidLeaseQuery.data === undefined) {
      return;
    }

    displayConfirmation({
      query: voidLeaseQuery,
      successCode: 204,
      successMessage: 'Voided Successfully!',
      successContent: <div>This Lease has been successfully voided from DocuSign.</div>,
      errorMessage: 'Failed to Void!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: displayLeaseDetailsViewLocally,
      refetchCallback: () => {
        window.location.reload();
      },
      handleChildSuccess,
      handleChildError
    });

    voidLease(null);
  };

  const handleForcedComplete = () => {
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirmation?',
      body: <div>Are you sure you want to forcefully mark this Lease Complete?</div>,
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Confirm',
      btnLabel2Callback: async () => {
        await forcedLease(id);
      },
      handleCloseConfirmDialog: () =>
        setDialogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
  };

  useEffect(() => {
    displayConfirmationForceDialogs();
  }, [forcedLeaseQuery.data]);

  useEffect(() => {
    displayConfirmationDialogVoid();
  }, [voidLeaseQuery.data]);

  return (
    <Fragment>
      <Card variant="outlined" style={leaseDetailsStyles.CardLayout}>
        <CardContent>
          <h3 style={{ margin: '0px', font: 'normal normal 600 20px/24px ProximaNova' }}>
            Update Renewal Lease Status
          </h3>
          <p>
            The Lease Status can be updated based on the resident’s request and current status. You
            may either void the renewal lease in DocuSign or mark the renewal complete.
          </p>
          <p>
            Use <span style={leaseDetailsStyles.WarningText}>Void in DocuSign</span> if you need to
            make a change to a renewal offer within MRI after the resident has generated the Lease
            Agreement. This will void the DocuSign document and change the resident’s status from{' '}
            <strong>“Lease Generated”</strong> to <strong>“Your Renewal Plan is Ready”</strong>,
            provided the lease expiration date is not in the past.
          </p>
          <p>
            Use <span style={leaseDetailsStyles.SuccessText}>Forced Complete</span> if the resident
            signed the lease outside of the DocuSign workflow. This will reset the resident’s status
            on Lease Manager and on your dashboard to
            <strong>“Renewal Complete”</strong>.
          </p>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={5}>
            <Grid item>
              {!voidLeaseQuery.isFetching ? (
                <Button
                  variant="contained"
                  style={
                    leaseDetails !== undefined &&
                    isVoidEnabled(leaseDetails.resident?.status, leaseDetails.requireWetSignature)
                      ? leaseDetailsStyles.PrimaryButton
                      : leaseDetailsStyles.DisabledButton
                  }
                  disabled={
                    leaseDetails !== undefined &&
                    !isVoidEnabled(leaseDetails.resident?.status, leaseDetails.requireWetSignature)
                  }
                  onClick={() => handleVoidDocuSign(leaseDetails?.resident?.docusignRefId ?? null)}>
                  Void in DocuSign
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{ ...leaseDetailsStyles.DisabledButton, width: '139px', height: '36px' }}
                  disabled={true}>
                  <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                </Button>
              )}
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              {!forcedLeaseQuery.isFetching ? (
                <Button
                  variant="contained"
                  style={
                    leaseDetails !== undefined &&
                    isForceCompleteEnabled(
                      leaseDetails.resident?.status,
                      leaseDetails.requireWetSignature
                    ) &&
                    !disableForcedCompleteByCommunity(id ?? '0')
                      ? leaseDetailsStyles.PrimaryButton
                      : leaseDetailsStyles.DisabledButton
                  }
                  disabled={
                    (leaseDetails !== undefined &&
                      !isForceCompleteEnabled(
                        leaseDetails.resident?.status,
                        leaseDetails.requireWetSignature
                      )) ||
                    disableForcedCompleteByCommunity(id ?? '0')
                  }
                  onClick={handleForcedComplete}>
                  Forced Complete
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{ ...leaseDetailsStyles.DisabledButton, width: '139px', height: '36px' }}
                  disabled={true}>
                  <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                </Button>
              )}
              {leaseDetails !== undefined &&
                displayHoverAction(
                  leaseDetails.resident?.status,
                  leaseDetails.requireWetSignature
                ) && (
                  <div style={{ paddingLeft: '5px' }}>
                    <InfoToolTip
                      position={'top-start'}
                      icon={info}
                      widthIcon={16}
                      text={<TextInfoToolTip />}
                    />
                  </div>
                )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog {...dialogDetails} />
    </Fragment>
  );
};

export default LeaseStatusCard;
