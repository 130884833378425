import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';

import { styles } from './styles';
import leaseGeneratedIcon from '../../assets/imgs/statusIcons/Lease_Generated_Initiated.png';
const ChipStatus = (status, lastModified, isHeader) => {
  const addLabelStatus = (status) => {
    const LABEL_STATUS = {
      'renewal-ready': 'Renewal Ready',
      'Renewal Ready': 'Renewal Ready',
      'ntv-completed': 'NTV Completed',
      'NTV Completed': 'NTV Completed',
      'renewal-completed': 'Renewal Completed',
      'Renewal Completed': 'Renewal Completed',
      completed: 'Renewal Completed',
      initiated: 'Lease Initiated',
      'lease-initiated': 'Lease Initiated',
      'Lease Initiated': 'Lease Initiated',
      'lease-generated': 'Lease Generated',
      'Lease Generated': 'Lease Generated',
      'forced-mtm': 'Forced MTM',
      'Forced MTM': 'Forced MTM',
      'notice-to-vacate-no-offer': 'Renewal Not Ready',
      'renewal-not-ready': 'Renewal Not Ready',
      'Renewal Not Ready': 'Renewal Not Ready',
      'document-declined': 'Document Declined',
      'document-generated': 'Document Generated',
      'document-completed': 'Document Completed',
      'document-expired': 'Document Expired',
      'document-initiated': 'Document Initiated',
      'document-voided': 'Document Voided',
      HOU: 'HOU',
      LEGAL: 'LEGAL'
    };

    const validateStatus = (status) => Object.keys(LABEL_STATUS).includes(status);
    const DEFAULT_STATUS = status && validateStatus(status) ? LABEL_STATUS[status] : '';

    const getLabel = LABEL_STATUS[status] || DEFAULT_STATUS;
    return getLabel;
  };

  const getIconAndColor = (status) => {
    const ICON_COLOR_STATUS = {
      'Forced MTM': {
        color: '#FFF1F0',
        textColor: '#31343A',
        fontWeight: 'normal',
        borderColor: '#FFA39E',
        tag: 'pendo-f-mtm',
        icon: (
          <ErrorIcon style={{ color: '#FFA39E' }} sx={{ fontSize: isHeader ? '14px' : '12px' }} />
        )
      },
      'Renewal Ready': {
        text: 'Renewal Ready',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#E6F7FF',
        borderColor: '#91D5FF',
        tag: 'pendo-rr',
        icon: (
          <MoreHorizOutlinedIcon
            style={{ color: '#1890FF' }}
            sx={{ fontSize: isHeader ? '14px' : '12px' }}
          />
        )
      },
      'Renewal Completed': {
        text: 'Renewal Completed',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#F6FFED',
        borderColor: '#B7EB8F',
        tag: 'pendo-rc',
        icon: (
          <CheckCircleIcon
            style={{ color: '#52C41A' }}
            sx={{ fontSize: isHeader ? '14px' : '12px' }}
          />
        )
      },
      'Lease Generated': {
        text: 'Lease Generated',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#FA8C1640',
        borderColor: '#FA8C16',
        tag: 'pendo-lg',
        icon: <img src={leaseGeneratedIcon} style={{ width: '9px' }} />
      },
      'Lease Initiated': {
        text: 'Lease Initiated',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#FA8C1640',
        borderColor: '#FA8C16',
        tag: 'pendo-li',
        icon: <img src={leaseGeneratedIcon} style={{ width: '9px' }} />
      },
      'NTV Completed': {
        text: 'NTV Completed',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#F6FFED',
        borderColor: '#B7EB8F',
        tag: 'pendo-ntv-c',
        icon: (
          <CheckCircleIcon
            style={{ color: '#52C41A' }}
            sx={{ fontSize: isHeader ? '14px' : '12px' }}
          />
        )
      },
      'Renewal Not Ready': {
        text: 'Renewal Not Ready',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#EDEEEF',
        borderColor: '#BABCC0',
        tag: 'pendo-rnr',
        icon: (
          <CancelIcon style={{ color: '#878788' }} sx={{ fontSize: isHeader ? '14px' : '12px' }} />
        )
      },
      HOU: {
        text: 'HOU',
        textColor: '#FFFFFF',
        fontWeight: 'bold',
        color: '#F5222D',
        borderColor: '#F5222D'
      },
      LEGAL: {
        text: 'HOU',
        textColor: '#FFFFFF',
        fontWeight: 'bold',
        color: '#F5222D',
        borderColor: '#F5222D'
      },
      'Document Generated': {
        text: 'Document Generated',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#FA8C1640',
        borderColor: '#FA8C16',
        tag: 'pendo-dg',
        icon: <img src={leaseGeneratedIcon} style={{ width: '9px' }} />
      },
      'Document Initiated': {
        text: 'Document Initiated',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#FA8C1640',
        borderColor: '#FA8C16',
        tag: 'pendo-di',
        icon: <img src={leaseGeneratedIcon} style={{ width: '9px' }} />
      },
      'Document Expired': {
        color: '#FFF1F0',
        textColor: '#31343A',
        fontWeight: 'normal',
        borderColor: '#FFA39E',
        tag: 'pendo-de',
        icon: (
          <ErrorIcon style={{ color: '#FFA39E' }} sx={{ fontSize: isHeader ? '14px' : '12px' }} />
        )
      },
      'Document Declined': {
        color: '#FFF1F0',
        textColor: '#31343A',
        fontWeight: 'normal',
        borderColor: '#FFA39E',
        tag: 'pendo-dd',
        icon: (
          <ErrorIcon style={{ color: '#FFA39E' }} sx={{ fontSize: isHeader ? '14px' : '12px' }} />
        )
      },
      'Document Completed': {
        text: 'Document Completed',
        textColor: '#31343A',
        fontWeight: 'normal',
        color: '#F6FFED',
        borderColor: '#B7EB8F',
        tag: 'pendo-rc',
        icon: (
          <CheckCircleIcon
            style={{ color: '#52C41A' }}
            sx={{ fontSize: isHeader ? '14px' : '12px' }}
          />
        )
      },
      'Document Voided': {
        color: '#FFF1F0',
        textColor: '#31343A',
        fontWeight: 'normal',
        borderColor: '#FFA39E',
        tag: 'pendo-dd',
        icon: (
          <ErrorIcon style={{ color: '#FFA39E' }} sx={{ fontSize: isHeader ? '14px' : '12px' }} />
        )
      }
    };

    const DEFAUTL_COLOR = ICON_COLOR_STATUS[addLabelStatus(status)] || {
      text: status,
      color: '#F6FFED',
      borderColor: '#B7EB8F'
    };
    const iconAndColor = ICON_COLOR_STATUS[addLabelStatus(status)] || DEFAUTL_COLOR;
    return iconAndColor;
  };

  const getLastModified = () => {
    const validatedStatus = addLabelStatus(status);
    if (
      status !== 'initiated' &&
      validatedStatus !== 'Lease Initiated' &&
      validatedStatus !== 'Lease Generated' &&
      validatedStatus !== 'Document Initiated' &&
      validatedStatus !== 'Document Generated'
    ) {
      return <div style={{ fontSize: isHeader ? '14px' : '12px' }}>{validatedStatus} </div>;
    } else {
      return (
        <p style={{ fontSize: isHeader ? '14px' : '12px' }}>
          {validatedStatus} - <span style={{ fontWeight: 'bold' }}>{lastModified}</span>
        </p>
      );
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <Chip
        icon={getIconAndColor(status)?.icon}
        sx={{
          '& .MuiChip-label': {
            paddingLeft: `${status == 'HOU' || status == 'LEGAL' ? '5px' : '12px'}`,
            paddingRight: `${status == 'HOU' || status == 'LEGAL' ? '5px' : '12px'}`
          }
        }}
        className={`${getIconAndColor(status)?.tag}`}
        style={{
          ...styles.ChipStatusContainer,
          color: `${getIconAndColor(status)?.textColor}`,
          fontWeight: `${getIconAndColor(status)?.fontWeight}`,
          textTransform: 'capitalize',
          backgroundColor: `${getIconAndColor(status)?.color}`,
          borderColor: `${getIconAndColor(status)?.borderColor}`,
          height: isHeader ? '32px' : '19px'
        }}
        label={getLastModified()}
      />
    </Stack>
  );
};

export default ChipStatus;
