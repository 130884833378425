/* eslint-disable @typescript-eslint/no-explicit-any */

import { LeaseFormsRepository } from '../domain/LeaseFormsRepository';

export async function getForms(
  leaseFormsRepository: LeaseFormsRepository,
  leaseId: string | null,
  leaseFlow: string | null,
  leaseTerm: string | null
): Promise<any> {
  return await leaseFormsRepository.getForms(leaseId, leaseFlow, leaseTerm);
}
