/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState, useImperativeHandle, useRef } from 'react';

// Components / utils
import { FilterOptionsCheckboxes } from './FilterOptionsCheckboxes';
// @ts-ignore
import { timeToRefreshSession } from '../../../../components/signIn/auth';
import CloseBtn from '../../../leaseDetails/components/summaryCard/CloseBtn';
// @ts-ignore
import { updateKeyValueLocalStorage } from '../../../../utils/updateKeyValueLocalStorage';
import { filterCheckboxesCurrentLease } from '../../../../modules/dashboard/residents/domain/ResidentFilterResource';

// Material
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Divider } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import FilterListIcon from '@mui/icons-material/FilterList';

// Navigation
import { useNavigate } from 'react-router-dom';

// libraries
import dayjs from 'dayjs';
import { filterStyles } from './styles';

const Transition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement }, // Agregar el tipo correcto para 'props'
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FiltersContainerProps {
  fetchDataWithFilters: (requestTypes: any, filterBy: string) => void;
  tableQuery: any;
  clearFilters: any;
}

const FiltersContainer = React.forwardRef((props: FiltersContainerProps, ref) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    clearFilters();
  };

  useImperativeHandle(ref, () => {
    return {
      handleClear: handleClear
    };
  });

  const iniitalStateMap = [
    ['all', false],
    ['move-out-inquiry', false],
    ['transfer-apartments', false],
    ['occupancy-change', false],
    ['notice-to-vacate-no-offer', false],
    ['notice-to-vacate-offer', false],
    ['renewal-billing', false],
    ['lease-modification', false],
    ['process-move-out', false],
    ['rate-term', false],
    ['addons', false],
    ['renters-insurance', false],
    ['docusign', false],
    ['window-guard', false]
  ];

  const iniitalStateStatusMap = [
    ['all', false],
    ['forced-mtm', false],
    ['lease-generated', false],
    ['lease-initiated', false],
    ['ntv-completed', false],
    ['renewal-completed', false],
    ['renewal-not-ready', false],
    ['renewal-ready', false]
  ];

  const generateMonths = (isNTVDueDate = false) => {
    const currentDate = dayjs();
    const intialMonths: any = Array.from({ length: 9 }, () => []);

    isNTVDueDate
      ? intialMonths.map((_: any, idx: number) => {
          idx == 0
            ? intialMonths[idx].push('prior', false)
            : idx == 8
              ? intialMonths[idx].push('later', false)
              : intialMonths[idx].push(currentDate.add(idx - 1, 'month').format('MM/YYYY'), false);
        })
      : intialMonths.map((_: any, idx: number) => {
          idx == 8
            ? intialMonths[idx].push('later', false)
            : intialMonths[idx].push(currentDate.add(idx, 'month').format('MM/YYYY'), false);
        });
    return [['all', false], ...intialMonths];
  };

  const [initialStateCurrentLeaseMap, setInitialStateCurrentLeaseMap] = useState(generateMonths());
  const [initialStateNTVDueDate, setInitialStateNTVDueDate] = useState(generateMonths(true));
  const filterForLeaseEndMonth = useMemo(() => filterCheckboxesCurrentLease(false), []);
  const filterForNTVDueDate = useMemo(() => filterCheckboxesCurrentLease(true), []);
  useEffect(() => {
    setInitialStateCurrentLeaseMap(generateMonths());
    setInitialStateNTVDueDate(generateMonths(true));
  }, []);

  type CheckedItems = {
    requestTypesStatus: Map<string, boolean>;
    statusState: Map<string, boolean>;
    leaseEndMonths: Map<string, boolean>;
    ntvDueDateMonths: Map<string, boolean>;
  };

  const initialStateCheckedItems: CheckedItems = {
    requestTypesStatus: new Map<string, boolean>(iniitalStateMap as [string, boolean][]),
    statusState: new Map<string, boolean>(iniitalStateStatusMap as [string, boolean][]),
    leaseEndMonths: new Map<string, boolean>(initialStateCurrentLeaseMap as [string, boolean][]),
    ntvDueDateMonths: new Map<string, boolean>(initialStateNTVDueDate as [string, boolean][])
  };

  const [checkedItems, setCheckedItems] = useState<CheckedItems>(initialStateCheckedItems);

  const clearFilters = async () => {
    setCheckedItems(initialStateCheckedItems);
    updateKeyValueLocalStorage('checkedFilters', '');
    updateKeyValueLocalStorage('requestTypesFilters', '');
    updateKeyValueLocalStorage('statusFilter', '');
    updateKeyValueLocalStorage('leaseEndMonthsFilter', '');
    updateKeyValueLocalStorage('ntvDueDateMonthsFilter', '');

    props.clearFilters();
  };

  const getTotalSelected = (): any => {
    const checkedArrays = [
      checkedItems.requestTypesStatus,
      checkedItems.statusState,
      checkedItems.leaseEndMonths,
      checkedItems.ntvDueDateMonths
    ];

    const totalFilters = checkedArrays.reduce((acc: any, cur: any) => {
      const filtered = Array.from(cur)?.filter(([, value]: any) => value === true);
      return [...acc, ...filtered];
    }, []);

    return totalFilters.length;
  };

  const navigate = useNavigate();

  const debounceRef: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const onQuerySearched = (requestTypes: any, filterBy: string) => {
    if (debounceRef.current) clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      props.fetchDataWithFilters(requestTypes, filterBy);
    }, 500);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterBy: keyof CheckedItems
  ) => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    if (item == 'all') {
      [...checkedItems[filterBy].keys()].map((k) =>
        setCheckedItems({
          ...checkedItems,
          [filterBy]: new Map(checkedItems[filterBy].set(k, isChecked))
        })
      );
    } else {
      setCheckedItems({
        ...checkedItems,
        [filterBy]: new Map(checkedItems[filterBy].set(item, isChecked))
      });
    }

    onQuerySearched(filterByRequests(filterBy), filterBy);
  };

  const filterByRequests = (filterBy: keyof CheckedItems) => {
    let requestTypes = '';
    for (const [key, value] of checkedItems[filterBy]) {
      if (value) {
        requestTypes = `${requestTypes === '' ? key : requestTypes + ',' + key}`;
      }
    }
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }

    return requestTypes;
  };

  const findSelectectedFilters = (selectedFilters: any, filters: any) => {
    selectedFilters?.length > 0 &&
      selectedFilters?.split(',').forEach((k: any) => {
        if (filters.has(k)) {
          filters.set(k, true);
        }
      });
    return filters;
  };

  const setPrevValues = () => {
    const newRequestTypesStatus = new Map(checkedItems['requestTypesStatus']);
    const newStatusState = new Map(checkedItems['statusState']);
    const newCurrentLeaseState = new Map(checkedItems['leaseEndMonths']);
    const newNTVDueDateState = new Map(checkedItems['ntvDueDateMonths']);

    const activeFiltersString = localStorage.getItem('activeFilters');
    const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : null;

    setCheckedItems({
      requestTypesStatus: findSelectectedFilters(
        activeFilters?.checkedFilters + ',' + activeFilters?.requestTypesFilters,
        newRequestTypesStatus
      ),
      statusState: findSelectectedFilters(activeFilters?.statusFilter, newStatusState),
      leaseEndMonths: findSelectectedFilters(
        activeFilters?.leaseEndMonthsFilter,
        newCurrentLeaseState
      ),
      ntvDueDateMonths: findSelectectedFilters(
        activeFilters?.ntvDueDateMonthsFilter,
        newNTVDueDateState
      )
    });
  };

  useEffect(() => {
    setPrevValues();
  }, []);

  return (
    <div>
      <Badge color="primary" badgeContent={getTotalSelected()}>
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          sx={filterStyles.FilterButtonContainer}>
          <div style={filterStyles.FilterButtonContainer}>
            <FilterListIcon sx={{ width: '15px' }} />
          </div>
          <div style={filterStyles.FilterText}>Filters</div>
        </Button>
      </Badge>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          <div style={filterStyles.DialogCloseBtnContainer}>
            <div onClick={handleClose}>
              <CloseBtn withoutBorderRound={true} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <FilterOptionsCheckboxes
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            handleChange={handleChange}
            filterForLeaseEndMonth={filterForLeaseEndMonth}
            filterForNTVDueDate={filterForNTVDueDate}
          />
        </DialogContent>
        <Divider sx={{ backgroundColor: '#72767F' }} />

        <DialogActions sx={filterStyles.DialogClearBtnContainer}>
          <div style={{ paddingLeft: '1%' }}>
            <Button onClick={clearFilters} sx={{ ...filterStyles.TypoLabel, fontWeight: 600 }}>
              Clear All
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

FiltersContainer.displayName = 'Filter';

export default FiltersContainer;
