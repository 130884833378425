import React from 'react';
// @ts-expect-error @david-avalon needs to fix this
import ChipStatus from '../../../../shared/chipStatus/ChipStatus';

import {
  HeaderKey,
  getStyleForKey,
  matchHeaderTable,
  isHeaderKey,
  HelpRequestContentProps,
  calculateTimeDifference
} from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import { helpRequestStyles } from './styles';

const LeaseModificationContent = ({ tableBody }: HelpRequestContentProps) => {
  return (
    <>
      {Object.keys(tableBody).map((key, idx) => {
        if (!isHeaderKey(key)) return null;
        const styleByKey = getStyleForKey(key as HeaderKey);
        return (
          <React.Fragment key={idx}>
            <div style={styleByKey}>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li style={helpRequestStyles.contentHelpRequest}>
                  {tableBody[key] && matchHeaderTable(key as HeaderKey)}
                </li>
                {key === 'leaseModifications' && (
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {tableBody[key].split(',').map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {key === 'documentStatus' ? (
                  <li style={{ fontSize: '16px', color: '#31343A' }}>
                    {ChipStatus(
                      tableBody[key],
                      calculateTimeDifference(tableBody.helpRequestCreatedDate ?? '')
                    )}
                  </li>
                ) : (
                  key !== 'leaseModifications' && (
                    <li style={{ fontSize: '16px', color: '#31343A' }}>{tableBody[key]}</li>
                  )
                )}
              </ul>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default LeaseModificationContent;
