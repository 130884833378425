import axios, { AxiosRequestConfig } from 'axios';

const BASE_URL = process.env.REACT_APP_ASSOCIATE_URL;
const API_KEY = process.env.REACT_APP_ASSOCIATE_LEASE_FORMS_API_KEY;

function getAccessToken() {
  return {
    accessToken: localStorage.getItem('access_token'),
    idToken: localStorage.getItem('id_token')
  };
}

const associateFormsAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY
  }
});

associateFormsAPI.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: AxiosRequestConfig): any => {
    const { accessToken, idToken } = getAccessToken();

    if (accessToken && idToken && config.headers) {
      config.headers['X-Access-Token'] = accessToken;
      config.headers['X-Id-Token'] = idToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default associateFormsAPI;
