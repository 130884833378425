import React, { FC } from 'react';
import { Skeleton } from '@mui/material';

interface LeaseCardSkeleton {
  width: number;
  height: number;
}

const LeaseCardSkeleton: FC<LeaseCardSkeleton> = ({ width, height }) => {
  return <Skeleton variant="rectangular" width={width} height={height} />;
};

export default LeaseCardSkeleton;
