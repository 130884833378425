import axios, { AxiosRequestConfig } from 'axios';

const BASE_URL = process.env.REACT_APP_ASSOCIATE_URL;
const API_KEY = process.env.REACT_APP_ASSOCIATE_API_KEY;

function getIdToken(): string | null {
  const idToken = localStorage.getItem('id_token');

  if (!idToken) {
    return null;
  }

  return idToken;
}

const associateAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY
  }
});

associateAPI.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: AxiosRequestConfig): any => {
    const idToken = getIdToken();

    if (idToken && config.headers) {
      config.headers.Authorization = idToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default associateAPI;
