import React, { useState, FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

/* eslint-disable */
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
// @ts-ignore
import dollar from '../../../../assets/imgs/detailsIcons/dollar_circle_cost_icon.png';
// @ts-ignore
import star from '../../../../assets/imgs/detailsIcons/yellowstar_icon.png';
// @ts-ignore
import exclamation from '../../../../assets/imgs/detailsIcons/exclamation_circle.png';

import { withStyles } from '@mui/styles';

import { styled } from '@mui/material/styles';
// @ts-ignore
import { SummaryCardstyles } from './styles';
import { Container } from '@mui/material';
// @ts-ignore
import { formatCurrency } from '../../../../utils/formatCurrency';

declare module '@mui/material/styles' {
  interface Palette {
    headerBackground: {
      main: string;
    };
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.headerBackground.main,
    color: theme.palette.common.white
  }
}));

const StyledTableRow = withStyles(() => ({
  root: {
    maxHeight: '30px'
  }
}))(TableRow);

const StyleTableCell = withStyles(() => ({
  root: {
    padding: 0
  }
}))(TableCell);

const StyleTableHead = withStyles(() => ({
  root: {
    border: '1px solid #707070'
  }
}))(TableHead);

interface OffersProps {
  bestRate: boolean;
  concession: number;
  selected: boolean;
  term: string;
  totalRenewalRate: number;
}
interface ListeTermProps {
  offers: OffersProps[];
  onTermSelected: (e: string) => void;
  isNYC421a: boolean;
  isBlockedMTM: boolean;
}

const ListTerms: FC<ListeTermProps> = ({ offers, onTermSelected, isNYC421a, isBlockedMTM }) => {
  const [selectedTerm, setSelectedTerm] = useState('');

  const handleSelectTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTermSelected(event.target.value);
    setSelectedTerm(event.target.value);
  };

  return (
    <Container sx={{ width: '550px' }}>
      <TableContainer component={Paper} sx={{ maxWidth: '95%', border: '1px solid #707070' }}>
        <Table aria-label="simple table" stickyHeader>
          <StyleTableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  borderBottom: '1px solid #707070',
                  borderRight: 0,
                  padding: '10px 10px 10px 15px'
                }}>
                <div style={{ ...SummaryCardstyles.fontHeader, textAlign: 'center' }}>
                  Lease Term <div>(Months) </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  borderBottom: '1px solid #707070',
                  borderRight: 0,
                  borderLeft: 0,
                  padding: '10px'
                }}>
                <div style={SummaryCardstyles.fontHeader}>Concession</div>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  borderBottom: '1px solid #707070',
                  borderRight: 0,
                  borderLeft: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px 0px 10px 10px'
                }}>
                <div
                  style={{
                    ...SummaryCardstyles.fontHeader,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '110px'
                  }}>
                  <div>Renewal</div> <div>TOTAL Monthly</div>{' '}
                  <div style={{ fontSize: '10px', display: 'inline-block' }}>
                    (Includes Other Charges)
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  borderBottom: '1px solid #707070',
                  borderRight: 0,
                  borderLeft: 0,
                  padding: 0
                }}>
                <div style={{ width: '30px' }}></div>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  borderBottom: '1px solid #707070',
                  borderLeft: 0,
                  padding: '10px 15px 10px 0'
                }}>
                <div style={{ ...SummaryCardstyles.fontHeader, textAlign: 'center' }}>
                  Select Term
                </div>
              </StyledTableCell>
            </TableRow>
          </StyleTableHead>
          <TableBody>
            {offers.length &&
              offers?.map((offer: any, idx: number) => (
                <StyledTableRow
                  key={idx}
                  sx={{
                    // '&:last-child td, &:last-child th': { border: 0 },
                    backgroundColor: `${idx % 2 === 0 ? '#F6F6F7' : ''}`
                  }}>
                  <StyleTableCell
                    align="center"
                    component="th"
                    scope="offer"
                    sx={{
                      border: `${offer.term === selectedTerm ? '1px solid #C64D5B' : ''}`,
                      backgroundColor: `${offer.term === selectedTerm ? '#12BABA14' : ''}`,
                      borderRight: 0,
                      borderLeft: `${offer.term === selectedTerm ? '5px solid #C64D5B' : ''}`
                    }}>
                    <div style={{ fontSize: '16px', display: 'flex', justifyContent: 'center' }}>
                      {isBlockedMTM && offer.term === 'MTM' ? (
                        <div
                          style={{
                            padding: '0 0 0 3px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                          }}>
                          <div style={{ paddingRight: '15%' }}>{offer.term}</div>
                          <img src={exclamation} alt="star-icon" width={14} />
                        </div>
                      ) : (
                        offer.term
                      )}
                    </div>
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    sx={{
                      border: `${offer.term === selectedTerm ? '1px solid #C64D5B' : ''}`,
                      backgroundColor: `${offer.term === selectedTerm ? '#12BABA14' : ''}`,
                      borderRight: 0,
                      borderLeft: 0
                    }}>
                    {offer.concession > 0 && (
                      <div style={SummaryCardstyles.centerRows}>
                        <span
                          style={{
                            ...SummaryCardstyles.centerRows,
                            background: '#FFFBB8',
                            width: 'fit-content'
                          }}>
                          <div style={{ padding: '0 0 0 3px' }}>
                            <img src={star} alt="star-icon" width={10} />
                          </div>
                          <div style={{ padding: '0 5px', fontSize: '14px', fontWeight: 600 }}>
                            ${formatCurrency(offer.concession)}
                          </div>
                        </span>
                      </div>
                    )}
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    sx={{
                      border: `${offer.term === selectedTerm ? '1px solid #C64D5B' : ''}`,
                      backgroundColor: `${offer.term === selectedTerm ? '#12BABA14' : ''}`,
                      borderRight: 0,
                      borderLeft: 0
                    }}>
                    <div style={{ fontSize: '16px', fontWeight: 600 }}>
                      $
                      {`${formatCurrency(offer.totalRenewalRate)} ${
                        isNYC421a ? '+ 421-a Surcharge' : ''
                      }`}
                    </div>
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    sx={{
                      border: `${offer.term === selectedTerm ? '1px solid #C64D5B' : ''}`,
                      backgroundColor: `${offer.term === selectedTerm ? '#12BABA14' : ''}`,
                      borderRight: 0,
                      borderLeft: 0
                    }}>
                    {!isNYC421a && offer.bestRate === true && (
                      <div style={{ ...SummaryCardstyles.centerRows }}>
                        <img src={dollar} alt="star-icon" width={15} />
                        <div style={{ ...SummaryCardstyles.bestRateText, paddingLeft: '5px' }}>
                          Best Rate
                        </div>
                      </div>
                    )}
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    sx={{
                      border: `${offer.term === selectedTerm ? '1px solid #C64D5B' : ''}`,
                      backgroundColor: `${offer.term === selectedTerm ? '#12BABA14' : ''}`,
                      borderRight: `${offer.term === selectedTerm ? '1px solid #C64D5B' : ''}`,
                      borderLeft: 0
                    }}
                    style={SummaryCardstyles.centerRows}>
                    <div>
                      <Radio
                        checked={selectedTerm.split(' ')[0] === offer.term}
                        value={offer.term}
                        onChange={handleSelectTerm}
                        size="small"
                        sx={{
                          '&, &.Mui-checked': {
                            color: '#C64D5B'
                          },
                          '&, &.Mui-unchecked': {
                            color: '#808286'
                          }
                        }}
                      />
                    </div>
                  </StyleTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListTerms;
