type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

const fontHeader = {
  fontFamily: 'ProximaNova',
  fontWeight: 'bold',
  fontSize: '12px',
  color: '#31343A',
  lineHeight: '1.2'
};

const table = {
  width: '100%',
  fontFamily: 'ProximaNova'
};

const tableHeader = {
  background: '#C64D5B14',
  border: 'solid 1px #707070'
};

const headerTitle = {
  padding: '12px',
  textAlign: 'center'
};

const tableBody = {
  border: '1px solid #979797'
};

const closeIcon = {
  height: '20px',
  width: '20px',
  fontWeight: 300,
  color: '#979AA0'
};

const closeIconNoBorder = {
  height: '25px',
  width: '25px',
  fontWeight: 300,
  color: '#72767F'
};

const closeContainerBtn = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  border: '1px solid #979AA0',
  display: 'flex',
  flexDirection: 'column' as FlexDirection,
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
};

const centerRows = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const bestRateText = {
  color: '#12BABA',
  fontSize: '14px',
  fontWeight: 600
};

const confirmSelectionBtn = {
  padding: '10px',
  paddingTop: '10%',
  display: 'flex',
  justifyContent: 'center',
  justifyItems: 'center'
};

const borderSelection = {
  border: '2px solid red',
  borderRight: 0
};

const PrimaryButton = {
  font: 'ProximaNova',
  background: '#31343A',
  // textTransform: 'capitalize',
  color: '#FFFFFF',
  lineHeight: '1.75'
};

const GeneralButton = {
  ...PrimaryButton,
  width: '400px',
  height: '47px',
  // textTransform: 'capitalize',
  fontFamily: 'ProximaNova',
  fontSize: '20px'
};

const DisabledButton = {
  background: '#BABCC0',
  // textTransform: 'capitalize',
  color: '#FFFFFF',
  border: 0,
  pointerEvents: 'none'
};

const DisableGeneralButton = {
  ...GeneralButton,
  background: '#BABCC0',
  // textTransform: 'capitalize',
  color: '#FFFFFF',
  border: 0
  // pointerEvents: 'none'
};

export const SummaryCardstyles = {
  fontHeader,
  table,
  headerTitle,
  tableHeader,
  centerRows,
  bestRateText,
  tableBody,
  closeContainerBtn,
  closeIconNoBorder,
  closeIcon,
  confirmSelectionBtn,
  borderSelection,
  GeneralButton,
  DisableGeneralButton,
  DisabledButton
};
