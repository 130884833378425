const DisabledButton = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '36px',
  backgroundColor: '#31343A66',
  border: 'none',
  boxShadow: 'none',
  cursor: 'none'
};

export const fullLeaseStyles = {
  DisabledButton
};
