/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createResidentAPIRepository } from '../../../../modules/dashboard/residents/infrastructure/APIResidentRepository';
import { getResidents } from '../../../../modules/dashboard/residents/application/getResidents';
// @ts-ignore
import { updateKeyValueLocalStorage } from '../../../../utils/updateKeyValueLocalStorage';
// limit, communityCodes, orderDirection, orderBy

const repository = createResidentAPIRepository();

export const useTableAssociate = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [limit, setLimit] = useState(20);
  const [communityCodes, setCommunityCodes] = useState<string[]>();
  const [helpRequest, setHelpRequest] = useState<string>();
  const [requestTypes, setRequestTypes] = useState<string>();
  const [statuses, setStatuses] = useState<string>();
  const [leaseEndMonths, setLeaseEndMonth] = useState<string>();
  const [ntvDueDateMonths, setNtvDueDateMonths] = useState<string>();
  // const [orderByAndOrderDirection, setOrderByAndOrderDirection] = useState<string[]>();
  const [orderBy, setOrderBy] = useState<string>();
  const [orderDirection, setOrderDirection] = useState<string>();

  const tableQuery = useQuery({
    queryKey: [
      'associateTable',
      {
        search,
        page,
        communityCodes,
        limit,
        helpRequest,
        requestTypes,
        statuses,
        leaseEndMonths,
        ntvDueDateMonths,
        orderBy,
        orderDirection
      }
    ],
    queryFn: () =>
      getResidents(repository, {
        search,
        page,
        communityCodes,
        limit,
        helpRequest,
        requestTypes,
        statuses,
        leaseEndMonths,
        ntvDueDateMonths,
        orderBy,
        orderDirection
      })
  });

  const nextPage = (p: number) => {
    // @ts-ignore
    if (tableQuery.data?.results?.length == 0 || p === page) return;
    setPage(p);
  };

  const searchByLease = (searchTerm: string) => {
    setSearch(searchTerm.trim());
    setPage(1);
    updateKeyValueLocalStorage('search', searchTerm.trim());
  };

  const searchByCommunityCodes = (communityCodesSelected: string[]) => {
    setCommunityCodes(communityCodesSelected);
  };

  const itemsByPage = (limitSelected: number) => {
    if (limit !== limitSelected) {
      setLimit(limitSelected);
    }
    setPage(1);
  };


  const checkedHelpRequestFilters = (
    requestTypesFilters: string,
    statusFilters: string,
    leaseEndMonthsFilter: string,
    ntvDueDateMonthsFilter: string
  ) => {
    const helpRequestTypes = requestTypesFilters
      ?.split(',')
      .filter((item: string) => !item.startsWith('notice-to-vacate'))
      .join(',');

    const requestTypes = requestTypesFilters
      ?.split(',')
      .filter((item) => item.startsWith('notice-to-vacate'))
      .join(',');

    const updateLocalStorageAndState = async (
      key: string,
      value: string,
      setState: (value: string) => void
    ) => {
      try {
        await updateKeyValueLocalStorage(key, value);
        setState(value);
      } catch (error) {
        console.error(`Error updating ${key}:`, error);
      }
    };

    const updateAllFilters = async () => {
      await Promise.all([
        updateLocalStorageAndState('checkedFilters', helpRequestTypes, setHelpRequest),
        updateLocalStorageAndState('requestTypesFilters', requestTypes, setRequestTypes),
        updateLocalStorageAndState('statusFilter', statusFilters, setStatuses),
        updateLocalStorageAndState('leaseEndMonthsFilter', leaseEndMonthsFilter, setLeaseEndMonth),
        updateLocalStorageAndState(
          'ntvDueDateMonthsFilter',
          ntvDueDateMonthsFilter,
          setNtvDueDateMonths
        )
      ]);
      setPage(1);
    };

    updateAllFilters();
  };

  const clearFilters = () => {
    setHelpRequest('');
    setRequestTypes('');
    setStatuses('');
    setLeaseEndMonth('');
    setNtvDueDateMonths('');
    setPage(1);
    updateKeyValueLocalStorage('checkedFilters', '');
    updateKeyValueLocalStorage('requestTypesFilters', '');
    updateKeyValueLocalStorage('statusFilter', '');
    updateKeyValueLocalStorage('leaseEndMonthsFilter', '');
    updateKeyValueLocalStorage('ntvDueDateMonthsFilter', '');
  };

  const orderByAndOrderDirectionFilter = (orderBy: string, orderDirection: string) => {
    setOrderBy(orderBy);
    setOrderDirection(orderDirection);
  };

  return {
    tableQuery,
    //Getter
    page,
    communityCodes,
    limit,
    helpRequest,
    requestTypes,
    statuses,
    //Methods,
    nextPage,
    searchByLease,
    searchByCommunityCodes,
    itemsByPage,
    checkedHelpRequestFilters,
    orderByAndOrderDirectionFilter,
    clearFilters
  };
};
