import { OccupantDetail } from '../../leaseDetails/domain/LeaseDetails';

export interface FormsProps {
  formId: number;
  formName: string;
  formGroupName: string;
  formTitle: string;
  formDisclaimer: null;
  formHeader: string;
  formSchema: FormSchema;
  docusignActionName: string;
  required: boolean;
  selected?: boolean;
  leaseFlows: LeaseFlows[];
  leaseTypes: LeaseTypes[];
}

export interface LeaseFlows {
  name: string;
}

export interface LeaseTypes {
  name: string;
}

export interface FormSchema {
  version: number;
  fields: Field[];
}

export interface Field {
  fieldName: string;
  label: string;
  required: boolean;
  fieldType: string;
  xmlPath: string;
  fieldValues: FieldValue[];
  sortOrder: number;
  value: string;
  selected?: boolean;
}

export interface FieldValue {
  option: string;
  value: string;
}

export enum OccupantTypes {
  ResponsibleResidents = 'Responsible Resident',
  Guarantor = 'Guarantor'
}

export const getResponsableResidents = (occupantDetails: OccupantDetail[]) => {
  const occupants = occupantDetails?.filter(
    (occupant) =>
      occupant.type === OccupantTypes.ResponsibleResidents ||
      occupant.type === OccupantTypes.Guarantor
  );
  return occupants;
};

const montgomeryCommunities = ['MD006', 'MD007', 'MD015', 'MD016', 'MD017', 'MD018', 'MD031'];

export const isMontgomeryCommunity = (leaseId: string) =>
  montgomeryCommunities.some((community) => leaseId.includes(community));

const currencyConversion = (
  input: string,
  setUpdateValue: (e: string) => void,
  setError: (val: boolean) => void
) => {
  const cleanedInput = input?.replace(/[^0-9.-]/g, '');

  const [integerPart, decimalPart] = cleanedInput.split('.');

  const formattedInteger = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '';

  const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : '';

  const result =
    decimalPart !== undefined ? `${formattedInteger}.${truncatedDecimalPart}` : formattedInteger;
  if ((!/^\d*\.?\d*$/.test(input) && !result.length) || !result.length) {
    setError(true);
    setUpdateValue('');
    return;
  } else {
    setError(false);
  }

  setUpdateValue(result || '');
  return result;
};

const percentageConversion = (
  input: string,
  setUpdateValue: (e: string) => void,
  setError: (val: boolean) => void
) => {
  const newValue = input;
  if (/^\d*\.?\d{0,2}$/.test(newValue)) {
    if (parseFloat(newValue) >= 100) {
      setUpdateValue('100');
    } else {
      setUpdateValue(`${newValue}`);
      setError(false);
    }
  }
};

const onlyNumbers = (
  input: string,
  setUpdateValue: (e: string) => void,
  setError: (val: boolean) => void
) => {
  const newValue = input;
  if (/^[0-9]+$/.test(newValue)) {
    setUpdateValue(`${newValue}`);
    setError(false);
  }
};

export const validationLayer = (
  e: string,
  validationPattern: string,
  setUpdateValue: (e: string) => void,
  setError: (val: boolean) => void
) => {
  if (validationPattern?.includes('$')) {
    currencyConversion(e, setUpdateValue, setError);
  } else if (validationPattern?.includes('%')) {
    percentageConversion(e, setUpdateValue, setError);
  } else if (validationPattern == '#') {
    onlyNumbers(e, setUpdateValue, setError);
  } else {
    setUpdateValue(e);
    if (!e.length) {
      return setError(true);
    }
    setError(false);
  }
};
