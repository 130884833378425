import React from 'react';
import { leaseDetailsCardStyles } from './styles';
import { formatCurrency } from '../../../../utils/formatCurrency';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AdditionalCommonArea = ({ additionalCaaFee }: any) => {
  return (
    <div>
      <div
        style={{
          ...leaseDetailsCardStyles.Subtitle,
          display: 'flex',
          justifyItems: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}>
        <div style={{ padding: '10px 2px', width: '90%' }}>Additional Common Area Amenity Fee*</div>
        <div
          style={{
            ...leaseDetailsCardStyles.ValueField,
            width: '10%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          ${formatCurrency(additionalCaaFee)}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingTop: '1.8%' }}>
          <div style={leaseDetailsCardStyles.Separation}></div>
        </div>
        <span
          style={{
            ...leaseDetailsCardStyles.LabelField,
            ...leaseDetailsCardStyles.DescriptionText
          }}>
          You have selected a lease term greater than 12 months. The resident will be billed an
          additional amount in month 13 of the lease.
        </span>
      </div>
      <div
        style={{
          ...leaseDetailsCardStyles.DescriptionText,
          paddingLeft: '8px',
          paddingTop: '10px'
        }}>
        <div>
          * Details are provided in the Long Term Lease Amenity
          <div style={{ paddingLeft: '6px' }}>Fee Addendum in the Lease.</div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalCommonArea;
