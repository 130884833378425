import { HelpRequestRepository } from '../domain/HelpRequestRepository';

export async function putNoticeToVacate(
  helpRequestRepository: HelpRequestRepository,
  leaseId: string | null,
  enteredInMri: boolean,
  ntvId: string | null,
  helpRequestRefId: string | null
): Promise<number> {
  return await helpRequestRepository.putNoticeToVacate(
    leaseId,
    enteredInMri,
    ntvId,
    helpRequestRefId
  );
}
