/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createAPIFormsRepository } from '../../../../../modules/details/leaseForms/infrastructure/APIFormsRepository';
import { postGenerateForms } from '../../../../../modules/details/leaseForms/application/postForms';

const repository = createAPIFormsRepository();

export const useGenerateForms = () => {
  const [leaseId, setLeaseId] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  const generateFormsQuery = useQuery({
    queryKey: ['generateForms', leaseId],
    queryFn: () => postGenerateForms(repository, leaseId, data),
    enabled: !!leaseId,
    refetchOnWindowFocus: false,
    gcTime: 0
  });

  const generateForms = (leaseId: string | null, data: any) => {
    setLeaseId(leaseId);
    setData(data);
  };

  return {
    generateFormsQuery,
    // Methods
    generateForms
  };
};
