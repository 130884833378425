/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState } from 'react';

// Components
import LeaseHeader from '../../../shared/header/LeaseHeader';
import LeaseDetailsSubHeader from '../components/leaseDetails/LeaseDetailsSubHeader';
import LeaseStatusCard from '../components/leaseDetails/LeaseStatusCard';
import LeaseInformationCard from '../components/leaseDetails/LeaseInformationCard';
import ResolveHelpRequest from '../components/helpRequest/ResolvedHelpRequest';
import OccupantDetails from '../components/occupantDetails/OccupantDetails';
import SummaryCard from '../components/summaryCard/SummaryCard';
import AccordionSkeleton from '../components/helpRequest/AccordionSkeleton';
import LeaseCardSkeleton from '../components/leaseDetails/LeaseCardSkeleton';
import ErrrorScreen from '../../../shared/errorScreen/ErrrorScreen';
import HelpRequest from '../components/helpRequest/HelpRequest';
import ManualLeaseGeneration from '../components/leaseDetails/ManualLeaseGeneration';
import Dialog, { DialogType } from '../../../shared/dialog/Dialog';
import Button from '@mui/material/Button';

// utils
// @ts-ignore
import { clearLocalStorage } from '../../../utils/clearLocalStorage';
import { isEmptyObject } from '../../../utils/common';
import { validatePlanProperties } from '../../../modules/details/leaseDetails/domain/LeaseDetailsInformationCard';
// @ts-ignore
import { timeToRefreshSession } from '../../../components/signIn/auth';
import dayjs from 'dayjs';

// Routes
import { useNavigate, useParams } from 'react-router-dom';

//hooks
import { useForcedLease } from '../components/leaseDetails/hooks/useForcedLease';
import { useVoidLease } from '../components/leaseDetails/hooks/useVoidLease';
import { useOffers } from '../components/leaseDetails/hooks/useOffers';
import { useLeaseDetails } from '../components/leaseDetails/hooks/useLeaseDetails';
import { useResolveHelpRequest } from '../components/helpRequest/hooks/useResolveHelpRequest';
import { useGenerateLease } from '../components/leaseDetails/hooks/useGenerateLease';

// @ts-ignore
import { styles } from './styles';

// Domain
import {
  filterHelpRequestActions,
  filterNTVRequested,
  getAnswers,
  orderResolvedRequestsByDate
} from '../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import {
  filterResposibleAndGuarantors,
  listFullName,
  missingOccupantEmail,
  missingPhoneNumber,
  numberMissingEmails,
  quantityMissingEmails
} from '../../../modules/details/leaseDetails/domain/LeaseDetailsSubheader';
import { DialogDetailsType } from '../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';
import {
  HelpRequestItem,
  NoticeToVacateItem
} from '../../../modules/details/helpRequest/domain/HelpRequestRepository';
import {
  LeaseDetails,
  includesCommunity
} from '../../../modules/details/leaseDetails/domain/LeaseDetails';
import { displayConfirmation } from '../../../modules/details/leaseDetails/domain/LeaseDetailsDialogs';
import {
  IWindowGuardMultiple,
  windowGuardForms
} from '../../../modules/details/leaseDetails/domain/LeaseDetailsGuardForm';

import { AssociateContext } from '../../../context/AssociateContext';
import WindowGuardForm from '../../../shared/windowGuardForm/WindowGuardForm';

const customParseFormat = require('dayjs/plugin/customParseFormat');

interface Offer {
  term: string;
  totalRenewalRate: number;
  concession: number;
  selected: boolean;
  bestRate: boolean;
}

const getGuardrailLink = (leaseDetails: LeaseDetails) =>
  `${process.env.REACT_APP_GUARDIAN_LINK}&Community=${leaseDetails.resident.leaseId.substring(
    0,
    5
  )}&namegroup=${leaseDetails.resident.nameGroup}`;

dayjs.extend(customParseFormat);

const LeaseDetailsView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id = '0' } = params;

  // queries
  const { leaseDetailsQuery } = useLeaseDetails(id);
  const { offersQuery, offerSelectedQuery, offerSelected } = useOffers(id);
  const { voidLeaseQuery, voidLease } = useVoidLease();
  const { forcedLeaseQuery, forcedLease } = useForcedLease();
  const { generateLeaseQuery, generateLease } = useGenerateLease();
  const {
    resolveHelpRequestQuery,
    resolveNoticeToVacateQuery,
    resolveProcessMoveOutQuery,
    resolveNoticeToVacate,
    resolveHelpRequest,
    resolveProcessMoveOut,
    resendEmailQuery,
    resendEmail
  } = useResolveHelpRequest();

  // state
  const [userName, setUserName] = useState<string>('');
  const [open, setOpen] = useState(false);

  const [dialogDetails, setDialogDetails] = useState<DialogDetailsType>({
    type: DialogType.Confirm,
    headerLabel: '',
    body: '',
    btnLabel1: 'Cancel',
    btnLabel2: 'Confirm',
    isOpen: false,
    btnLabel2Callback: () => Promise.resolve()
  });

  const {
    updateFormStatus,
    isValidForm,
    addendumsDetails,
    leaseDetails,
    multipleWindowGuardForm,
    answersWindowGuard,
    singleWindowGuardForm,
    prepFormValue,
    addendumPayload
  } = useContext<any>(AssociateContext);

  const handleLogout = () => {
    clearLocalStorage(['activeFilters', 'communities']);
    localStorage.removeItem('formsView');
    localStorage.removeItem('isFormRendered');
    navigate('/');
  };

  const getToken = () => {
    const accessToken = localStorage.getItem('access_token');
    const userName = localStorage.getItem('userName') ?? '';
    accessToken ? setUserName(userName) : handleLogout();
  };

  const openSummaryCard = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [dialogMessage, setDialogMessage] = useState<IWindowGuardMultiple>({
    title: '',
    guideline: <></>,
    question: '',
    guidelineSelection: '',
    note: '',
    icon: ''
  });

  const [windowGuardForm, setWindowGuardForm] = useState(true);
  const [openWindowGuardForm, setOpenWindowGuardForm] = useState(false);

  const resetForm = () => {
    updateFormStatus({
      prepFormValue: null,
      errorPrepForm: false,
      multipleWindowGuardForm: null,
      errorMultipleGuardForm: false,
      singleWindowGuardForm: [],
      isValidForm: false
    });
  };

  const renderWindowForm = () => {
    resetForm();
    setOpenWindowGuardForm(true);

    includesCommunity(addendumPayload[0].name, 'Yonkers/Harrison')
      ? setDialogMessage(windowGuardForms.WindowGuardSingle)
      : setDialogMessage(windowGuardForms.WindowGuardMultiple);
  };

  const handleGenerateLease = () => {
    if (leaseDetailsQuery?.data?.isBlockedUnit) {
      setDialogDetails({
        ...dialogDetails,
        type: DialogType.Alert,
        customWidth: '400px',
        headerLabel: 'Additional Lease Form Required',
        body: (
          <>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                paddingBottom: '6px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              NOTE: THE LEASE WAS NOT GENERATED
            </div>
            <div
              style={{
                padding: '0px 26px',
                paddingBottom: '0px',
                fontSize: '16px'
              }}>
              This apartment home requires a lead notification form that is not accommodated with
              DocuSign. You will need to compile the lease package using FormsPlus to include this
              lead notification form.
            </div>
          </>
        ),
        isOpen: true,
        btnLabel1: 'Got It!',
        handleCloseConfirmDialog: handleCloseConfirmDialog
      });
      return;
    }

    if (windowGuardForm && leaseDetailsQuery?.data?.isHou) {
      return handleIsHouGenerateLease();
    }

    if (windowGuardForm) {
      return renderWindowForm();
    }

    if (leaseDetailsQuery?.data?.isHou) {
      return handleIsHouGenerateLease();
    }

    // Usall flow
    handleConfirmGenerateLeaseDialog();
  };

  const handleRenderWindowGuardForm = () => {
    const addendums = leaseDetailsQuery?.data?.plan?.addendums;
    const addendumHasElements = addendums && addendums.length > 0 ? true : false;

    const options =
      addendums &&
      addendums[0]?.questions[1]?.answers.map((value: any) => ({
        ...value,
        key: false
      }));

    addendumsDetails({
      addendumPayload: addendums,
      answersWindowGuard: options,
      videos: leaseDetailsQuery?.data?.plan?.videos
    });

    setWindowGuardForm(addendumHasElements);
  };

  const [unresolvedRequests, setUnresolvedRequests] = useState<HelpRequestItem[]>([]);
  const [resolvedHelpRequests, setResolvedHelpRequests] = useState<HelpRequestItem[]>([]);

  const [resolvedNTV, setResolvedNTV] = useState<NoticeToVacateItem[]>([]);
  const [unresolvedNTV, setUnresolvedNTV] = useState<NoticeToVacateItem[]>([]);

  const [displayLeaseInformationCard, setDisplayLeaseInformationCard] = useState(true);

  const updateHelpRequestLocally = (helpRequest: HelpRequestItem) => {
    setUnresolvedRequests(unresolvedRequests?.filter((helpItem) => helpItem.id !== helpRequest.id));
    let helpRequestWithProperties: HelpRequestItem = helpRequest;
    helpRequestWithProperties.resolvedByName = localStorage.getItem('userName');
    helpRequestWithProperties.windowGuardSelection = helpRequest.formData?.formName?.includes(
      'Window Guard'
    )
      ? getAnswers([helpRequestWithProperties], answersWindowGuard)
      : [];
    helpRequestWithProperties.notes = helpRequest.formData?.formName?.includes('Window Guard')
      ? 'The resident generated a lease form that indicated they may need a window guard installed or repaired.'
      : '';
    helpRequestWithProperties.helpRequestStatus.action = 'resolved';
    helpRequestWithProperties.resolvedDate = dayjs().toISOString();

    setResolvedHelpRequests([...resolvedHelpRequests, helpRequestWithProperties]);
  };

  const updateNoticeToVacateLocally = (noticeToVacate: NoticeToVacateItem) => {
    setUnresolvedNTV(unresolvedNTV?.filter((ntvItem) => ntvItem.id !== noticeToVacate.id));
    let noticeToVacateWithProps: NoticeToVacateItem = noticeToVacate;
    noticeToVacateWithProps.resolvedByName = localStorage.getItem('userName');
    noticeToVacateWithProps.resolvedDate = dayjs().format('DD/MM/YYYY, hh:mm a [EDT]');
    noticeToVacateWithProps.resolved = true;
    setResolvedNTV([...resolvedNTV, noticeToVacateWithProps]);
  };

  const displayLeaseDetailsViewLocally = () => {
    setDisplayLeaseInformationCard(false);
  };

  const resolvedRequests = orderResolvedRequestsByDate(
    resolvedHelpRequests,
    leaseDetailsQuery?.data?.leaseActions,
    resolvedNTV
  );

  const handleGoBack = () => {
    navigate('/dashboard', { replace: true });
  };

  const handleCloseConfirmDialog = () => {
    setDialogDetails({
      ...dialogDetails,
      isOpen: false
    });
  };

  const handleChildError = (errorDetails: any) => {
    setDialogDetails({
      ...dialogDetails,
      ...errorDetails,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleChildSuccess: any = (
    successDetails: any,
    successAction: () => void,
    btnLabel2Callback: any
  ) => {
    successAction();
    setDialogDetails({
      ...dialogDetails,
      ...successDetails,
      btnLabel2Callback: btnLabel2Callback,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleConfirmGenerateLeaseDialog = () => {
    resetForm();
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirm Lease Generation?',
      body: (
        <div>
          Are you sure you want to <b>generate this lease</b> for <br />
          <span style={{ color: '#349CC5', fontWeight: 'bold' }}>
            {offerSelectedQuery?.data?.leaseTerm ?? leaseDetailsQuery.data?.bestOffer}?
          </span>{' '}
          When you <b>Generate a Lease</b> the residents will receive an email with a link to review
          and sign their lease in <b>DocuSign</b>.
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Generate Lease in DocuSign',
      btnLabel2Callback: async () => {
        validateGenerateLease();
      },
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleIsHouGenerateLease = () => {
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirm Lease Generation - HOU',
      customWidth: '485px',
      body: (
        <div>
          Are you sure you want to <b>generate this lease</b> for{' '}
          <span style={{ color: '#349CC5', fontWeight: 'bold' }}>
            {offerSelectedQuery?.data?.leaseTerm ?? leaseDetailsQuery.data?.bestOffer}?
          </span>{' '}
          When you <b>generate a Lease</b> the residents will receive an email with a link to review
          and sign their lease in <b>DocuSign</b>.
          <div style={{ padding: '20px 0px' }}>
            <span>This account requires additional approvals from a Housing Authority.</span>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>
              {' '}
              Confirm that you have taken the following actions:
            </span>
            <ol style={{ padding: '0px 20px', margin: '2px 0px' }}>
              <li>Received approval of the renewal amount from the Housing Authority.</li>
              <li>Updated the base rent and HOU fee in MRI.</li>
              <li>Selected the lease term approved by the Housing Authority.</li>
            </ol>
          </div>
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Generate lease in DocuSign',
      btnLabel2Callback: async () => {
        windowGuardForm ? renderWindowForm() : validateGenerateLease();
      },
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const validateGenerateLease = () => {
    if (missingOccupantEmail(leaseDetailsQuery?.data?.occupantDetails || [])) {
      setTimeout(() => {
        displayErrorMessageMissingEmail();
      }, 500);
    } else if (
      missingPhoneNumber(
        leaseDetailsQuery?.data?.occupantDetails || [],
        leaseDetailsQuery?.data?.resident?.leaseId ?? ''
      )
    ) {
      setTimeout(() => {
        dispayErrorMissingPhoneNumber();
      }, 500);
    } else {
      let term = offerSelectedQuery?.data?.leaseTerm ?? leaseDetailsQuery.data?.bestOffer;
      let data = null;

      if (windowGuardForm) {
        let selectedResponses = [];
        includesCommunity(addendumPayload[0]?.name, 'Yonkers/Harrison')
          ? (selectedResponses = [...(singleWindowGuardForm || []), ...prepFormValue])
          : (selectedResponses = [...(multipleWindowGuardForm || []), ...prepFormValue]);
        const addendums = [
          {
            formName: leaseDetailsQuery?.data?.plan?.addendums[0]?.name,
            responses: selectedResponses?.map((item) => ({
              answerId: item.id,
              answerValue: ![2, 10].includes(item.id) ? 'yes' : 'no'
            }))
          }
        ];
        data = { addendums };
      }
      if (term) {
        generateLease(id, term, data);
      }
    }
  };

  const dispayErrorMissingPhoneNumber = () => {
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.NoIcon,
      customWidth: '600px',
      isLeaseMod: true,
      cancelOpt: false,
      isOpen: true,
      headerLabel: (
        <p style={{ fontWeight: 'bold', fontSize: '24px', margin: '0px' }}>Missing Phone Number</p>
      ),
      body: (
        <div>
          <>
            <div
              style={{
                paddingTop: '8px',
                paddingBottom: '8px',
                fontSize: '16px',
                fontWeight: 'bold'
              }}>
              NOTE: THE LEASE WAS NOT GENERATED{' '}
            </div>
            <div style={{ paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>
              You cannot Generate the Lease in DocuSign because we are missing the{' '}
              <b>phone number</b> for the primary contact of the account. This information is
              required to populate in the Lease Summary.
              <div style={{ height: '10px' }}> </div>
              Please contact the resident to provide their phone number. Enter the phone number in
              MRI so that you can generate the lease in DocuSign.
            </div>
          </>
        </div>
      ),
      btnLabel1: '',
      btnLabel2: 'Got it',
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const displayErrorMessageMissingEmail = () => {
    const occupants = filterResposibleAndGuarantors(leaseDetailsQuery?.data?.occupantDetails || []);
    let term = offerSelectedQuery?.data?.leaseTerm ?? leaseDetailsQuery.data?.bestOffer;

    setDialogDetails({
      ...dialogDetails,
      type: DialogType.NoIcon,
      customWidth: '600px',
      isLeaseMod: true,
      cancelOpt: true,
      isOpen: true,
      headerLabel: (
        <p style={{ fontWeight: 'bold', fontSize: '24px', margin: '0px' }}>Email Not Found!</p>
      ),
      body: (
        <div>
          <p style={{ marginTop: '0px' }}>
            One or more of the resident or guarantor email addresses are missing ({' '}
            {listFullName(occupants).map((name, idx, arr) => (
              <React.Fragment key={idx}>
                {name}
                {idx !== arr.length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
            ).
            <p>
              {' '}
              <span style={{ fontWeight: 'bold' }}>Option 1 </span>
              (Preferred)
            </p>
            <ol style={{ fontWeight: 'bold' }}>
              <li>
                Cancel
                <span style={{ fontWeight: 'normal' }}> this process.</span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  Obtain and enter missing email addresses in MRI.
                </span>
              </li>
              <li>
                Restart{' '}
                <span style={{ fontWeight: 'normal' }}>
                  the lease generation process to send the DocuSign link to all residents for
                  signature.
                </span>
              </li>
            </ol>
            <p>OR</p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Option 2</span> Manual Signature
            </p>
            <ol style={{ fontWeight: 'bold' }}>
              <li>
                Proceed Anyway <span style={{ fontWeight: 'normal' }}>to generate the lease.</span>
              </li>
              <li>
                <span style={{ fontWeight: 'normal' }}>
                  The DocuSign link will be emailed to the community. An associate at the community
                  must{' '}
                </span>
                print
                <span style={{ fontWeight: 'normal' }}> the lease for </span>manual signature.
              </li>
            </ol>
          </p>
        </div>
      ),
      btnLabel1: 'Cancel',
      btnLabel2: 'Proceed anyway',
      btnLabel2Callback: async () => {
        let data = null;

        if (
          missingPhoneNumber(
            leaseDetailsQuery?.data?.occupantDetails || [],
            leaseDetailsQuery?.data?.resident?.leaseId ?? ''
          )
        ) {
          return setTimeout(() => {
            dispayErrorMissingPhoneNumber();
          }, 500);
        }

        if (windowGuardForm) {
          let selectedResponses = [];
          includesCommunity(addendumPayload[0]?.name, 'Yonkers/Harrison')
            ? (selectedResponses = [...(singleWindowGuardForm || []), ...prepFormValue])
            : (selectedResponses = [...(multipleWindowGuardForm || []), ...prepFormValue]);
          const addendums = [
            {
              formName: leaseDetailsQuery?.data?.plan?.addendums[0]?.name,
              responses: selectedResponses?.map((item) => ({
                answerId: item.id,
                answerValue: ![2, 10].includes(item.id) ? 'yes' : 'no'
              }))
            }
          ];
          data = { addendums };
        }
        if (term) {
          generateLease(id, term, data);
        }
      },
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const displayConfirmationMessageGenerateLease = () => {
    displayConfirmation({
      query: generateLeaseQuery,
      successCode: 204,
      successMessage: 'Initiated Successfully!',
      successContent: (
        <div>
          This lease has been successfully <b>initiated.</b> Account status will update to{' '}
          <b>Lease Generated</b> when the DocuSign link has been delivered.
        </div>
      ),
      errorMessagePhoneNumber: 'Missing Phone Number',
      errorPhoneNumberContent: (
        <>
          <div
            style={{
              paddingTop: '8px',
              paddingBottom: '8px',
              fontSize: '16px',
              fontWeight: 'bold'
            }}>
            NOTE: THE LEASE WAS NOT GENERATED{' '}
          </div>
          <div style={{ paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>
            You cannot Generate the Lease in DocuSign because we are missing the <b>phone number</b>{' '}
            for the primary contact of the account. This information is required to populate in the
            Lease Summary.
            <div style={{ height: '10px' }}> </div>
            Please contact the resident to provide their phone number. Enter the phone number in MRI
            so that you can generate the lease in DocuSign.
          </div>
        </>
      ),
      errorMessage: 'Failed to Generate Lease!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {
        window.location.reload();
      },
      handleChildSuccess,
      handleChildError
    });

    generateLease(null, null, null);
  };

  useEffect(() => {
    const initialUnresolvedRequests: HelpRequestItem[] | any = leaseDetailsQuery?.data?.helpRequests
      ? filterHelpRequestActions(
          leaseDetailsQuery?.data?.helpRequests,
          'helpRequestStatus',
          'unresolved'
        )
      : [];
    const initialResolvedHelpRequests: HelpRequestItem[] | any = leaseDetailsQuery?.data
      ?.helpRequests
      ? filterHelpRequestActions(
          leaseDetailsQuery?.data?.helpRequests,
          'helpRequestStatus',
          'resolved'
        )
      : [];
    setUnresolvedRequests(initialUnresolvedRequests);
    setResolvedHelpRequests(initialResolvedHelpRequests);
  }, [leaseDetailsQuery?.data?.helpRequests]);

  useEffect(() => {
    const InitialresolvedNTV = filterNTVRequested(leaseDetailsQuery?.data?.noticeToVacate, true);
    const InitialunresolvedNTV = filterNTVRequested(leaseDetailsQuery?.data?.noticeToVacate, false);

    setResolvedNTV(InitialresolvedNTV);
    setUnresolvedNTV(InitialunresolvedNTV);
  }, [leaseDetailsQuery?.data?.noticeToVacate]);

  useEffect(() => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }
    localStorage.removeItem('formsView');
    getToken();
  }, []);

  useEffect(() => {
    displayConfirmationMessageGenerateLease();
  }, [generateLeaseQuery.data]);

  useEffect(() => {
    handleRenderWindowGuardForm();
  }, [leaseDetailsQuery?.data?.resident]);

  useEffect(() => {
    if (isValidForm) {
      leaseDetailsQuery?.data?.isHou ? validateGenerateLease() : handleConfirmGenerateLeaseDialog();
    }
  }, [isValidForm]);

  return (
    <Fragment>
      <LeaseHeader
        title="AvalonBay Communities"
        appName="Lease Manager Portal"
        handleLogout={handleLogout}
        userName={userName}>
        {leaseDetailsQuery.isLoading && (
          <div style={styles.Content}>
            <AccordionSkeleton />
            <div style={{ ...styles.DetailsCardsContainer, flexDirection: 'row' }}>
              <div>
                <LeaseCardSkeleton width={450} height={550} />
              </div>
              <div>
                <LeaseCardSkeleton width={450} height={550} />
              </div>
            </div>
            <LeaseCardSkeleton width={1146} height={300} />
          </div>
        )}
        {!leaseDetailsQuery.isLoading &&
          !leaseDetailsQuery.data?.code &&
          !leaseDetailsQuery.isError && (
            <>
              {leaseDetailsQuery.data && (
                <LeaseDetailsSubHeader leaseDetails={leaseDetailsQuery.data} />
              )}
              <div style={styles.Content}>
                {leaseDetailsQuery.data?.resident?.status !== 'renewal-completed' &&
                  leaseDetailsQuery.data?.requireWetSignature && <ManualLeaseGeneration />}
                {leaseDetailsQuery.data?.resident?.status !== 'renewal-not-ready' &&
                  leaseDetailsQuery.data?.requestType !== 'notice-to-vacate-no-offer' && (
                    <div style={styles.GuardRails}>
                      {leaseDetailsQuery.data && (
                        <a href={getGuardrailLink(leaseDetailsQuery.data)} target="_blank">
                          <Button variant="contained" sx={{ textTransform: 'capitalize' }}>
                            Go To Guardrails
                          </Button>
                        </a>
                      )}
                    </div>
                  )}

                {((unresolvedRequests !== undefined && unresolvedRequests.length > 0) ||
                  unresolvedNTV?.length > 0) && (
                  <HelpRequest
                    helpRequestUnresolve={unresolvedRequests}
                    voidLease={voidLease}
                    voidLeaseQuery={voidLeaseQuery}
                    resendEmailQuery={resendEmailQuery}
                    resendEmail={resendEmail}
                    resolveHelpRequest={resolveHelpRequest}
                    resolveProcessMoveOut={resolveProcessMoveOut}
                    resolveHelpRequestQuery={resolveHelpRequestQuery}
                    resolveProcessMoveOutQuery={resolveProcessMoveOutQuery}
                    resolveNoticeToVacateQuery={resolveNoticeToVacateQuery}
                    leaseDetailsQuery={leaseDetailsQuery}
                    unresolvedNTV={unresolvedNTV}
                    resolveNoticeToVacate={resolveNoticeToVacate}
                    updateHelpRequestLocally={updateHelpRequestLocally}
                    updateNoticeToVacateLocally={updateNoticeToVacateLocally}
                  />
                )}
                <div style={{ ...styles.DetailsCardsContainer, flexDirection: 'row' }}>
                  {leaseDetailsQuery.data !== undefined && leaseDetailsQuery.data?.lease && (
                    <div
                      style={{
                        display: leaseDetailsQuery.data?.lease?.leaseExpirationDate
                          ? 'block'
                          : 'none'
                      }}>
                      <LeaseInformationCard
                        offersQuery={offersQuery}
                        data={leaseDetailsQuery.data?.lease}
                        bestOffer={leaseDetailsQuery.data?.bestOffer}
                        residentStatus={leaseDetailsQuery.data?.resident?.status}
                        hideLeaseEndDate={leaseDetailsQuery.data?.isHideLeaseEndDate}
                        isNYC421a={leaseDetailsQuery.data.isNYC421a}
                        requireWetSignature={leaseDetailsQuery.data.requireWetSignature ?? false}
                      />
                    </div>
                  )}
                  {leaseDetailsQuery.data !== undefined && leaseDetailsQuery.data?.plan && (
                    <div
                      style={{
                        display: `${
                          leaseDetailsQuery.data?.resident?.status === 'renewal-not-ready' ||
                          leaseDetailsQuery.data?.plan?.forceComplete ||
                          !validatePlanProperties(leaseDetailsQuery.data?.plan) ||
                          !displayLeaseInformationCard
                            ? 'none'
                            : 'block'
                        }`
                      }}>
                      <LeaseInformationCard
                        data={
                          offerSelectedQuery?.data !== undefined
                            ? isEmptyObject(offerSelectedQuery?.data)
                              ? leaseDetailsQuery.data?.plan
                              : offerSelectedQuery?.data
                            : leaseDetailsQuery.data?.plan
                        }
                        offersQuery={offersQuery}
                        bestOffer={leaseDetailsQuery.data.bestOffer}
                        residentStatus={leaseDetailsQuery.data.resident?.status}
                        openSummaryCard={openSummaryCard}
                        handleGenerateLease={handleGenerateLease}
                        generateLeaseQuery={generateLeaseQuery}
                        isRenewalPlan={true}
                        isNYC421a={leaseDetailsQuery.data.isNYC421a}
                        requireWetSignature={leaseDetailsQuery.data.requireWetSignature ?? false}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <LeaseStatusCard
                    leaseDetails={leaseDetailsQuery.data}
                    forcedLeaseQuery={forcedLeaseQuery}
                    forcedLease={forcedLease}
                    voidLease={voidLease}
                    voidLeaseQuery={voidLeaseQuery}
                    leaseDetailsQuery={leaseDetailsQuery}
                    displayLeaseDetailsViewLocally={displayLeaseDetailsViewLocally}
                  />
                </div>
                {leaseDetailsQuery?.data?.occupantDetails && (
                  <OccupantDetails details={leaseDetailsQuery?.data.occupantDetails} />
                )}
                {resolvedRequests !== undefined && resolvedRequests?.length > 0 && (
                  <ResolveHelpRequest
                    resolvedRequests={resolvedRequests}
                    leaseDetails={leaseDetailsQuery?.data}
                  />
                )}
                <SummaryCard
                  open={open}
                  onClose={handleClose}
                  offers={offersQuery?.data ?? ([] as Offer[])}
                  offerSelectedQuery={offerSelectedQuery}
                  offerSelected={offerSelected}
                  isNYC421a={leaseDetailsQuery?.data?.isNYC421a}
                  isBlockedMTM={leaseDetailsQuery?.data?.isBlockedMTM}
                />
                <Dialog {...dialogDetails} />
                <div>
                  <WindowGuardForm
                    setOpenDialog={setOpenWindowGuardForm}
                    openDialog={openWindowGuardForm}
                    title={dialogMessage.title}
                    info={dialogMessage.info}
                    montgomeryInfo={dialogMessage.montgomeryInfo}
                    guideline={dialogMessage.guideline}
                    question={dialogMessage.question}
                    prepForm={dialogMessage.prepForm}
                    guidelineSelection={dialogMessage.guidelineSelection}
                    form={dialogMessage.form}
                    note={dialogMessage.note}
                    icon={dialogMessage.icon}
                    confirmMessage="Confirm Selection"
                  />
                </div>
                <Button
                  variant="outlined"
                  onClick={handleGoBack}
                  style={{ ...styles.capitalize, ...styles.Margin }}>
                  Back
                </Button>
              </div>
            </>
          )}

        {(leaseDetailsQuery.data?.code === 'ERR_NETWORK' ||
          leaseDetailsQuery.isError ||
          leaseDetailsQuery.data?.code === 'ERR_BAD_REQUEST') && (
          <ErrrorScreen
            message={
              'Sorry, something went wrong on our end. We are working on fixing the problem. Please try again.'
            }
            btnLabel={'Go to Dashboard'}
            redirectTo={'/dashboard'}
          />
        )}
      </LeaseHeader>
    </Fragment>
  );
};

export default LeaseDetailsView;
