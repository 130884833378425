/* eslint-disable @typescript-eslint/no-explicit-any */
interface FlexContainerProps {
  fontFamily?: string;
  fontWeight?: string | undefined;
  fontSize?: string | undefined;
  color?: string | undefined;
  whiteSpace?: string | any;
  textTransform?: string | any;
  display?: 'flex';
  alignItems?: string;
}

const PrimaryButton = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '36px',
  backgroundColor: '#1D2F5C',
  cursor: 'pointer'
};

const OutlineButton = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // width: '80px',
  height: '36px',
  border: '1px solid #1D2F5C',
  background: '#FFF',
  cursor: 'pointer',
  color: '#1D2F5C'
};

const DisabledButton = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '36px',
  backgroundColor: '#31343A66',
  border: 'none',
  boxShadow: 'none',
  cursor: 'none'
};

const ulStyle = {
  padding: '0px 15px',
  margin: '10px',
  listStyleType: 'disc'
};

// AccordionAssociate

const accordionContainer = { width: '97.5%', maxWidth: '1146px' };

const accordionDetailsCard = {
  border: '1px',
  borderStyle: 'solid',
  borderRadius: '4px',
  borderColor: '#DCDDDF',
  paddingBottom: '20px'
};

const accordionFlexDirection = { display: 'flex', flexFlow: 'wrap', maxWidth: '100%' };

const accordionButtonContainer = {
  display: 'flex',
  flex: 1,
  justifyContent: 'end',
  paddingTop: '6%'
};

const resolveButtonStyle = { fontSize: '16px', color: '#FFFFFF', fontFamily: 'ProximaNova' };

// AccordionSkeleton
const skeletonContainer = { width: '97.5%', maxWidth: '1146px' };

// HelpRequest
const textContainerHelpRequest = {
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1.6%',
  paddingTop: '25px'
};

// HelpRequestContent
const contentHelpRequest: FlexContainerProps = {
  fontWeight: '600',
  fontSize: '14px',
  color: '#31343A',
  whiteSpace: 'initial'
};

// HelpRequestHeader
const headerStatus: FlexContainerProps = {
  color: '#31343A',
  fontFamily: 'ProximaNova',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize'
};

const headerContainer = { display: 'flex', justifyContent: 'space-between', flex: 1 };

// NoticeToVacateMRI
const fontStyleMRI = { fontWeight: '600', fontSize: '14px', color: '#31343A' };

const boldStyle = { fontWeight: '400', paddingLeft: '1%' };

const containerInput = {
  display: 'flex',
  justifyItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column'
};

const centerLabel = {
  display: 'flex',
  justifyItems: 'center',
  justifyContent: 'center'
};

const buttonAlignment = { display: 'flex', flex: 1, justifyContent: 'end', paddingTop: '6%' };

// WindowGuardResolutionAndSelection
const fontStyleResponse = { fontWeight: '400', fontSize: '16px', color: '#31343A' };

// WindowGuardResponse
const windowGuardResponseContainer = { display: 'flex', flexFlow: 'wrap' };
const windowGuardListStyle = { listStyleType: 'none', paddingLeft: 0 };

export const helpRequestStyles = {
  PrimaryButton,
  OutlineButton,
  DisabledButton,
  ulStyle,

  // AccordionAssociate
  accordionContainer,
  accordionDetailsCard,
  accordionFlexDirection,
  accordionButtonContainer,
  resolveButtonStyle,

  //AccordionSkeleton
  skeletonContainer,

  // HelpRequest
  textContainerHelpRequest,

  // HelpRequestContent
  contentHelpRequest,

  // HelpRequestHeader
  headerContainer,
  headerStatus,

  // NoticeToVacateMRI
  fontStyleMRI,
  boldStyle,
  containerInput,
  centerLabel,
  buttonAlignment,

  // WindowGuardResolutionAndSelection
  fontStyleResponse,

  // WindowGuardResponse
  windowGuardResponseContainer,
  windowGuardListStyle
};
