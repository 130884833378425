import React, { useContext } from 'react';
import { AssociateContext } from '../../../../context/AssociateContext';

const WindowGuardMontgomeryInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { videos } = useContext<any>(AssociateContext);

  return (
    <>
      <b>AvalonBay is not required </b>to install window guards: on windows that do not open, if the
      apartment is
      <br /> located on the ground-level floor of the building, on any window that has an air
      conditioning unit
      <br /> permanently bolted to the window and not surrounded by an open space exceeding 4
      inches, or on windows leading to a fire escape ladder or an exterior balcony. <br />
      <div style={{ paddingTop: '2%' }}>
        <b>AvalonBay is required </b>to demonstrate how to safely operate the window guards in the
        apartment. If the <br />
        resident’s status indicates that they have or will receive window guards, then when you
        click “Confirm <br />
        Selection” on this form, the resident will be sent an email with the link to the window
        guard demonstration
        <br /> videos in{' '}
        <a
          href={videos?.videoEnglish}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1D2F5C' }}>
          English
        </a>{' '}
        and{' '}
        <a
          href={videos?.videoSpanish}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1D2F5C' }}>
          {' '}
          Spanish.
        </a>{' '}
        <b>They can also request an in-person demonstration. </b>
      </div>
    </>
  );
};

export default WindowGuardMontgomeryInfo;
