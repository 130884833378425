/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LeaseFormsRepository {
  getForms: (
    leaseId: string | null,
    leaseFlow: string | null,
    leaseTerm: string | null
  ) => Promise<any>; // need interface from response
  postGenerateForms: (leaseId: string | null, data: any) => Promise<any>;
}

export const LEASE_PAYLOAD_MOCK = {
  resident: {
    email: 'peteosinski.test@projectx.avalonbay.com',
    firstName: 'Peter',
    lastName: 'Tester',
    term: null,
    status: 'renewal-ready',
    noticeDate: null,
    lastModified: null,
    docusignNumberPending: 0,
    docusignTotalSignatures: 0,
    leaseId: 'NY050-011-1107-2',
    nameGroup: '5V17340054'
  },
  unit: {
    buildingId: '011',
    unitId: '1107',
    propertyId: 'AVB-NY050',
    bedroom: 2,
    bathroom: 2,
    squareFeet: 1420
  },
  community: {
    name: 'Avalon Harbor Isle'
  },
  isHou: false,
  isNYC421a: false,
  isHideLeaseEndDate: false,
  isBlockedUnit: false,
  isLegal: false,
  lease: {
    leaseTerm: '14 months',
    leaseExpirationDate: '2024-03-29',
    monthlyCharges: [
      {
        chargeCode: 'RNT',
        chargeDescription: 'Base Rent',
        chargeAmount: 4170,
        optional: 'N'
      },
      {
        chargeCode: 'CAA',
        chargeDescription: 'Common Area/Amenities',
        chargeAmount: 65,
        optional: 'N'
      },
      {
        chargeCode: 'P02',
        chargeDescription: 'Parking charge/rent',
        chargeAmount: 22.84,
        optional: 'Y'
      },
      {
        chargeCode: 'PTX',
        chargeDescription: 'Parking Sales Tax',
        chargeAmount: 2.16,
        optional: 'Y'
      }
    ],
    rentableItems: [
      {
        itemDescription: 'Surface Parking',
        assignedItem: '176'
      }
    ],
    noticeToVacate: 60,
    totalCharges: 4260,
    baseRent: 4170,
    decideBy: '2024-03-01'
  },
  plan: {
    variancePercentage: null,
    forceComplete: false,
    isProrateCaa: false,
    additionalCaaFee: 0,
    communityCode: 'NY050',
    addendums: []
  },
  leaseActions: [],
  noticeToVacate: null,
  helpRequests: [
    {
      id: 1821,
      notes: 'Testing',
      formData: null,
      action: 'unresolved',
      updated_at: '2023-02-21T15:26:08.256Z',
      submitted_by_id: 12631,
      submitted_by: 'Peter Tester',
      resident_created_date: '2023-07-24T13:47:22.741Z',
      leaseId: 'NY050-011-1107-2',
      occupantId: 12631,
      helpRequestStatusId: 2,
      resolvedDate: null,
      resolvedByName: null,
      submittedBy: 'Peter Tester',
      submittedOn: '2023-07-24T14:33:42.669Z',
      createdDate: '07/24/2023, 10:33 am EDT',
      helpRequestStatus: {
        action: 'unresolved'
      },
      helpRequestTypes: [
        {
          id: 3,
          key: 'occupancy-change',
          subkey: 'renewals',
          displayName: 'Occupancy Change'
        }
      ]
    }
  ],
  requestType: 'renewal',
  occupantDetails: [
    {
      firstName: 'Peter',
      lastName: 'Tester',
      type: 'Responsible Resident',
      email: 'peteosinski.test@projectx.avalonbay.com',
      phoneNumber: null,
      status: 'N/A'
    }
  ]
};

export function addTextBeforeParenthesis(text: string, textToAdd: string) {
  const openingParenthesisIndex = text.indexOf('(');

  if (text.includes('(s)')) return text;
  if (openingParenthesisIndex !== -1) {
    const newText =
      text.slice(0, openingParenthesisIndex + 1) +
      textToAdd +
      ' ' +
      text.slice(openingParenthesisIndex + 1);

    return newText;
  } else {
    return text;
  }
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
