/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Button from '@mui/material/Button';
import { helpRequestStyles } from './styles';
import { CircularProgress } from '@mui/material';
import { HeaderKey } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import LeaseModificationContent from './LeaseModificationContent';
import { OccupantDetail } from '../../../../modules/details/leaseDetails/domain/LeaseDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { emailNullResolutionText, resolutionText } from './ResolutionsLeaseMod';
import { missingOccupantEmail } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsSubheader';

interface LeaseModificationRequestProps {
  isResolved: boolean;
  residentResponse: string[];
  handleResendEmail: () => any;
  handleVoidDocuSign: () => any;
  handleResolveHelpRequest: () => any;
  voidLeaseQuery: any;
  resolveHelpRequestQuery: any;
  resendEmailQuery: any;
  description: string;
  selectedButtonIndex: number;
  tableBody: Record<HeaderKey, string>;
  idx: number;
  docusignRefId: string | null;
  occupantDetails: OccupantDetail[];
  leaseType: string;
  requireWetSignature: boolean;
}

const statusColors: Record<string, string> = {
  'N/A': '#31343A',
  NO: '#F5222D',
  YES: '#52C41A',
  null: '#098BD2'
};

const LeaseModificationRequest: React.FC<LeaseModificationRequestProps> = ({
  isResolved,
  voidLeaseQuery,
  resolveHelpRequestQuery,
  resendEmailQuery,
  handleVoidDocuSign,
  handleResolveHelpRequest,
  handleResendEmail,
  docusignRefId,
  selectedButtonIndex,
  tableBody,
  idx,
  occupantDetails,
  leaseType,
  requireWetSignature
}) => {
  const signatureStatus = (status: string | null) =>
    status ? (
      <span style={{ color: statusColors[status?.toUpperCase() ?? 'N/A'] }}> {status}</span>
    ) : (
      <span style={{ color: statusColors['null'] }}> Manual Signature</span>
    );

  /**
   * If any of the occupants are missing email addresses,
   * then all occupants must show "Manual Signature"
   */
  const isManualSignatures = occupantDetails?.some((od) => !od.email);

  return (
    <>
      {!isResolved && <div></div>}
      <div style={helpRequestStyles.windowGuardResponseContainer}>
        <LeaseModificationContent tableBody={tableBody} />
      </div>
      {leaseType !== 'CORPORATE' ? (
        <TableContainer sx={{ marginBottom: '24px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ fontWeight: '700', padding: '8px' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: '700', padding: '8px' }}>
                  Signature Completed
                </TableCell>
                <TableCell sx={{ fontWeight: '700', padding: '8px' }}>Email</TableCell>
                <TableCell sx={{ fontWeight: '700', padding: '8px' }}>Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {occupantDetails?.map((row, idx) => (
                <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" sx={{ borderBottom: 0, padding: '8px' }}>
                    {row.firstName}&nbsp;{row.lastName}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 0, padding: '8px' }}>
                    {signatureStatus(isManualSignatures ? null : row.status)}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 0, padding: '8px' }}>
                    {row.email ?? '(No Email in MRI)'}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 0, padding: '8px' }}>{row.phoneNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      <div>
        {resendEmailQuery?.isFetching ? (
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            style={helpRequestStyles.DisabledButton}
            disabled={true}>
            <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={
              docusignRefId
                ? {
                    ...helpRequestStyles.OutlineButton,
                    textTransform: 'capitalize',
                    backgroundColor: 'transparent'
                  }
                : { ...helpRequestStyles.DisabledButton, width: '150px' }
            }
            disabled={
              docusignRefId === null ||
              tableBody.documentStatus !== 'document-generated' ||
              requireWetSignature ||
              missingOccupantEmail(occupantDetails ?? [])
            }
            onClick={handleResendEmail}>
            <p style={{ fontSize: '16px', fontFamily: 'ProximaNova' }}>Resend Email</p>
          </Button>
        )}
      </div>
      <div>
        <h5 style={{ marginBottom: 0, color: '#525761' }}>Resolutions</h5>
        {requireWetSignature || missingOccupantEmail(occupantDetails ?? [])
          ? emailNullResolutionText()
          : resolutionText()}
      </div>
      <div>
        {!isResolved && (
          <>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'end', paddingTop: '6%' }}>
              {voidLeaseQuery?.isFetching && docusignRefId ? (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  style={helpRequestStyles.DisabledButton}
                  disabled={true}>
                  <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={
                    docusignRefId
                      ? {
                          ...helpRequestStyles.OutlineButton,
                          textTransform: 'capitalize',
                          backgroundColor: 'transparent'
                        }
                      : { ...helpRequestStyles.DisabledButton, width: '180px' }
                  }
                  onClick={handleVoidDocuSign}
                  disabled={
                    docusignRefId === null ||
                    tableBody.documentStatus !== 'document-generated' ||
                    requireWetSignature ||
                    missingOccupantEmail(occupantDetails ?? [])
                  }>
                  <p style={{ fontSize: '16px', fontFamily: 'ProximaNova' }}>Void in docuSign</p>
                </Button>
              )}
              {resolveHelpRequestQuery?.isFetching && selectedButtonIndex === idx ? (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize', marginLeft: '16px' }}
                  style={helpRequestStyles.DisabledButton}
                  disabled={true}>
                  <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize', marginLeft: '16px' }}
                  style={helpRequestStyles.PrimaryButton}
                  onClick={handleResolveHelpRequest}>
                  <p style={{ fontSize: '16px', color: '#FFFFFF', fontFamily: 'ProximaNova' }}>
                    Resolve
                  </p>
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LeaseModificationRequest;
