/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useReducer } from 'react';
import AssociateReducer from './AssociateReducer';
import { AssociateContext } from './AssociateContext';
import { Addendum, Answer } from '../modules/details/leaseDetails/domain/LeaseDetails';

export interface AssociateInitialState {
  prepFormValue: any | null;
  multipleWindowGuardForm: any | null;
  singleWindowGuardForm: any | null;
  errorPrepForm: boolean;
  errorMultipleGuardForm: boolean;
  isValidForm: boolean;
  addendumPayload: Addendum[];
  answersWindowGuard: Answer[];
}

export interface individualFormsProps {
  metadataForms: any;
}

export interface currentStepProps {
  currentSelection: string;
  lastStep: number;
  displaySelection: boolean;
}

export const ASSOCIATE_INITIAL_STATE: AssociateInitialState = {
  prepFormValue: null,
  multipleWindowGuardForm: null,
  singleWindowGuardForm: null,
  errorPrepForm: false,
  errorMultipleGuardForm: false,
  isValidForm: false,
  addendumPayload: [],
  answersWindowGuard: []
};

export const INDIVIDUAL_FOMS: individualFormsProps = {
  metadataForms: []
};

export const CURRENT_STEP: currentStepProps = {
  currentSelection: '',
  lastStep: 0,
  displaySelection: false
};

const AssociateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AssociateReducer, ASSOCIATE_INITIAL_STATE);

  const updateFormStatus = ({
    prepFormValue,
    multipleWindowGuardForm,
    singleWindowGuardForm,
    errorMultipleGuardForm,
    errorPrepForm,
    isValidForm
  }: AssociateInitialState) => {
    const windowGuardFormPayload = {
      prepFormValue,
      errorPrepForm,
      multipleWindowGuardForm,
      singleWindowGuardForm,
      errorMultipleGuardForm,
      isValidForm
    };

    dispatch({ type: 'updateFormStatus', payload: windowGuardFormPayload });
  };

  const addendumsDetails = ({ addendumPayload, answersWindowGuard, videos }: any) => {
    dispatch({
      type: 'addendumsDetails',
      payload: { addendumPayload, answersWindowGuard, videos }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const leaseDetails = ({ leasePayload, formsByCategory, forms }: any) => {
    dispatch({
      type: 'leaseDetails',
      payload: { leasePayload, formsByCategory, forms }
    });
  };

  const individualLeaseForm = ({ metadataForms }: individualFormsProps) => {
    dispatch({
      type: 'individualLeaseForm',
      payload: { metadataForms }
    });
  };

  const currentStep = ({ lastStep, displaySelection, currentSelection }: currentStepProps) => {
    dispatch({
      type: 'currentStep',
      payload: { currentSelection, lastStep, displaySelection }
    });
  };

  const formLeaseModificationPayload = ({ form }: any) => {
    const formData = {
      refId: '',
      formData: form
    };

    dispatch({
      type: 'formLeaseModificationPayload',
      payload: formData
    });
  };

  return (
    <AssociateContext.Provider
      value={{
        ...state,
        updateFormStatus,
        addendumsDetails,
        leaseDetails,
        individualLeaseForm,
        currentStep,
        formLeaseModificationPayload
      }}>
      {children}
    </AssociateContext.Provider>
  );
};

export default AssociateProvider;
