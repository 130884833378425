/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { createAPILeaseDetailsRepository } from '../../../../../modules/details/leaseDetails/infrastructure/APILeaseDetailsRepository';
import { postGenerateLease } from '../../../../../modules/details/leaseDetails/application/postGenerateLease';

import { Addendum } from '../../../../../modules/details/leaseDetails/domain/LeaseDetailsGuardForm';

const repository = createAPILeaseDetailsRepository();

export const useGenerateLease = () => {
  const [leaseId, setLeaseId] = useState<string | null>(null);
  const [term, setTerm] = useState<string | null>(null);
  const [addendums, setAddendums] = useState<Addendum[] | null>(null);

  const generateLeaseQuery = useQuery({
    queryKey: ['generateLease', leaseId],
    queryFn: () => postGenerateLease(repository, leaseId, term, addendums),
    enabled: !!leaseId
  });

  const generateLease = (
    leaseId: string | null,
    term: string | null,
    addendums: Addendum[] | any
  ) => {
    setLeaseId(leaseId);
    setTerm(term);
    setAddendums(addendums);
  };

  return {
    generateLeaseQuery,
    //Methods
    generateLease
  };
};
