import React from 'react';

import ntvIcon from '../../assets/imgs/requestTypes/icon_NTVRequested.png';
import occupantChanges from '../../assets/imgs/requestTypes/icon_OccupancyChange.png';
import transferIcon from '../../assets/imgs/requestTypes/icon_TransferApt.png';
import renewalBilling from '../../assets/imgs/requestTypes/icon_RenwlBlng.png';
import rateTerm from '../../assets/imgs/requestTypes/icon_RateTerm.png';
import renterInsurance from '../../assets/imgs/requestTypes/icon_RentersIns.png';
import docusign from '../../assets/imgs/requestTypes/pen_icon.png';
import addOns from '../../assets/imgs/requestTypes/icon_AddOns.png';
import windowGuard from '../../assets/imgs/requestTypes/icon_windowGuard.png';
import documents from '../../assets/imgs/requestTypes/icon_documents.png';

const LabelStatus = ({ label, subLabel }) => {
  const getIcon = (label) => {
    const ICON = {
      'Move Out Inquiry': {
        icon: <img src={ntvIcon} width="12px" alt="move_out" color=" #31343A" />
      },
      'Transfer Apmt': {
        icon: <img src={transferIcon} width="12px" alt="transfer" />
      },
      'Occupancy Change': {
        icon: <img src={occupantChanges} width="12px" alt="occupancy" />
      },
      'NTV Requested': {
        icon: <img src={ntvIcon} width="12px" alt="ntv_request" />,
        height: '32px',
        backgroundColor: '#FFF1F0',
        border: 'solid 1px #FFA39E'
      },
      'Process Move Out': {
        icon: <img src={ntvIcon} width="12px" alt="ntv_request" />,
        backgroundColor: '#FFF1F0',
        border: 'solid 1px #FFA39E'
      },
      'Renewal Billing': {
        icon: <img src={renewalBilling} width="12px" alt="renewal_billing" />
      },
      'Rate/Term': {
        icon: <img src={rateTerm} width="12px" alt="rate_term" />
      },
      'Renters Insurance': {
        icon: <img src={renterInsurance} width="12px" alt="renter_insurance" />
      },
      DocuSign: {
        icon: <img src={docusign} width="12px" alt="docusign" />
      },
      'Add-Ons': {
        icon: <img src={addOns} width="12px" alt="docusign" />
      },
      'Window Guard': {
        icon: <img src={windowGuard} width="12px" alt="Window-Guard" />
      },
      'Lease Modification': {
        icon: <img src={documents} width="12px" alt="lease-modification" />,
        backgroundColor: '#E6F7FF',
        border: 'solid 1px #9BD9FF'
      }
    };
    const DEFAULT_ICON = ICON[label] || {
      icon: <img src={ntvIcon} width="12px" alt="occupancy" />
    };

    const icon = ICON[label] || DEFAULT_ICON;
    return icon;
  };

  return (
    <div
      style={{
        backgroundColor: `${getIcon(label)['backgroundColor'] || '#FFF7E6'} `,
        border: `${getIcon(label)['border'] || 'solid 1px #FFD591'} `,
        borderRadius: '4px',
        display: 'flex',
        alignContent: 'center',
        width: 'fit-content',
        padding: '2px 0',
        height: `${getIcon(label)['height'] || '19px'} `,
        alignItems: 'center',
        margin: '0px'
      }}>
      <div style={{ paddingLeft: '8px', paddingTop: '2px' }}>{getIcon(label).icon} </div>
      <div>
        <div
          style={{
            fontSize: '12px',
            paddingLeft: '5px',
            paddingRight: '5px',
            display: 'block',
            whiteSpace: 'nowrap',
            lineHeight: '0.9'
          }}>
          {label}
          <div style={{ fontWeight: '600' }}>{subLabel}</div>
        </div>
      </div>
    </div>
  );
};

export default LabelStatus;
