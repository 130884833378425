/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

// material
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

// @ts-ignore
import { TableStyles } from './styles';
import { ArrowIconsProps } from '../../../../modules/dashboard/residents/domain/ResidentTable';

const ArrowIcons: FC<ArrowIconsProps> = ({ header, handleSortRequest }) => {
  const visibleArrowIcons = ['Term Selected', 'Action Needed', 'Document Status'];
  const customWidth = (name: string) => {
    const CUSTOM_WIDHT: { [key: string]: string } = {
      'Expiring Lease End Date': '55%'
    };
    const DEFAULT_WIDTH = name ? CUSTOM_WIDHT[name] : '';

    const custom_width = CUSTOM_WIDHT[name] || DEFAULT_WIDTH;
    return custom_width;
  };

  return (
    <div
      style={{
        ...TableStyles.ArrowIconsContainer,
        pointerEvents: `${visibleArrowIcons.includes(header.name) ? 'none' : 'auto'}`
      }}>
      <div
        style={{
          ...TableStyles.HeaderText,
          width: `${customWidth(header.name)} `
        }}>
        <div>{header.name !== 'Document Status' ? header.name : ''}</div>
        {header.subName}
      </div>
      {!visibleArrowIcons.includes(header.name) && (
        <>
          <div style={TableStyles.ArrowsContainer}>
            <div
              onClick={() => handleSortRequest(header.sorterElement)}
              style={TableStyles.ArrowIconsAlignment}>
              <div style={TableStyles.PositionArrowIcon}>
                <ArrowDropDownIcon />
              </div>
              <ArrowDropUpOutlinedIcon />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ArrowIcons;
