/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LeaseDetails {
  isCorporate: boolean;
  responsiblePersons?: Resident[];
  resident: Resident;
  unit: Unit;
  community: Community;
  lease: Lease;
  plan?: Plan;
  isNYC421a: boolean;
  isBlockedUnit: boolean;
  leaseActions: LeaseAction[];
  noticeToVacate: null;
  bestOffer: string;
  isHou: boolean;
  isLegal: boolean;
  requireWetSignature: boolean;
  isHideLeaseEndDate: boolean;
  isBlockedMTM: boolean;
  helpRequests: any[];
  requestType: string;
  occupantDetails: OccupantDetail[];
  code?: string;
}

export interface Community {
  name: string;
}

export interface Lease {
  leaseTerm: string;
  leaseExpirationDate: string;
  leaseStateDate: string;
  monthlyCharges: MonthlyCharge[];
  rentableItems: any[];
  noticeToVacate: number;
  totalCharges: number;
  baseRent: number;
  decideBy?: string;
  isProrateCaa: boolean;
  leaseType: string;
  additionalCaaFee: number;
}

export interface MonthlyCharge {
  chargeCode: string;
  chargeDescription: string;
  chargeAmount: number;
  optional: string;
  onetimeFee?: null;
}

export interface OccupantDetail {
  firstName: string;
  lastName: string;
  type: string;
  email: string;
  status: string;
  phoneNumber: string | null;
}

export interface Plan {
  leaseTerm: string;
  leaseStartDate: string;
  monthlyCharges: MonthlyCharge[];
  concession: null;
  totalCharges: number;
  baseRent: number;
  decideBy: string;
  communityCode: string;
  leaseExpirationDate: string;
  variancePercentage: number;
  forceComplete: boolean;
  addendums: Addendum[];
  videos?: Videos[];
}

export interface Resident {
  email: string;
  firstName: string;
  lastName: string;
  leaseId: string;
  term: null;
  status: string;
  noticeDate: null;
  lastModified?: string | null;
  docusignNumberPending: null;
  docusignTotalSignatures: null;
  docusignRefId: string | null;
  nameGroup: string;
}

export interface Unit {
  buildingId: string;
  unitId: string;
  propertyId: string;
  bedroom: number;
  bathroom: number;
  squareFeet: number;
}

export interface LeaseAction {
  associateName: string;
  actionDate: string;
  action: Action;
}

export interface Action {
  action: string;
}

export interface Addendum {
  name: string;
  description: string;
  disclaimer: string;
  questions: Question[];
}

export interface Videos {
  videoSpanish: string;
  videoEnglish: string;
}

export interface Question {
  id: number;
  questionText: string;
  questionType: string;
  sortOrder: number;
  isRequired: boolean;
  answers: Answer[];
}

export interface Answer {
  id: number;
  answerText: string;
}

export const isTermsDurationVisible = (status: string) => {
  const validStatus = ['lease-initiated', 'lease-generated', 'renewal-completed'];
  return validStatus.includes(status);
};

export const includesCommunity = (name: string, community: string) => {
  return name.includes(community);
};
