/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Resident {
  page: number;
  total: number;
  results?: Result[];
}

export interface Result {
  communityCode: string;
  unitId: string;
  firstName: string;
  lastName: string;
  leaseEndDate: string;
  leaseId: string;
  term?: string;
  status: Status;
  hou: boolean;
  legal: boolean;
  label: string;
  ntvDueDate: string;
  lastModified: string;
  leaseType: string;
  requestType: string;
  requestHoursOpen: null;
  helpRequestTypes?: string[];
  documentStatus: DocumentStatus[];
}

export enum Status {
  NtvCompleted = 'ntv-completed',
  RenewalReady = 'renewal-ready',
  RenewalCompleted = 'renewal-completed'
}

export interface Filters {
  search?: string;
  page?: number;
  communityCodes?: string[];
  limit?: number;
  helpRequest?: string;
  requestTypes?: string;
  statuses?: string;
  leaseEndMonths?: string;
  ntvDueDateMonths?: string;
  orderBy?: string;
  orderDirection?: string;
}

export interface ResidentCustomPagingProps {
  tableData: Resident;
  limit?: number;
  itemsByPage?: any;
}

export interface ResidentPaginationProps {
  tableData: Resident | undefined;
  handleChangePage: (e: any, p: number) => void;
  page?: number;
  limit?: number;
  itemsByPage?: any;
}

export interface DocumentStatus {
  timestamp: string;
  helpRequestId: number;
  documentStatus: string;
}
