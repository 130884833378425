import React, { useContext, useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { AssociateContext } from '../../../../context/AssociateContext';

interface RadioOption {
  id: string;
  answerText: string;
}

const PrepForm: React.FC = () => {
  const {
    updateFormStatus,
    errorPrepForm,
    addendumPayload,
    singleWindowGuardForm,
    errorMultipleGuardForm
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useContext<any>(AssociateContext);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setHelperText('');
    setError(false);
    if (event.target.value) {
      updateFormStatus({
        prepFormValue: addendumPayload[0]?.questions[0]?.answers.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ id }: any) => event.target.value == id
        ),
        singleWindowGuardForm,
        errorMultipleGuardForm,
        errorPrepForm: false
      });

      setError(false);
    }
  };

  const showError = () => {
    if (errorPrepForm) {
      setError(true);
      setHelperText('Please select one');
    }
  };

  useEffect(() => {
    showError();
  }, [errorPrepForm]);

  return (
    <form>
      <FormControl sx={{ m: 0 }} error={error} variant="standard">
        <RadioGroup
          aria-labelledby="window-guard-radios"
          name="window-guard"
          value={value}
          onChange={handleRadioChange}>
          {addendumPayload[0]?.questions[0]?.answers.map((option: RadioOption) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio sx={{ color: `${errorPrepForm ? '#F5222D' : ' '}` }} />}
              label={option.answerText}
            />
          ))}
        </RadioGroup>
        <FormHelperText sx={{ color: '#F5222D' }}>{helperText}</FormHelperText>
      </FormControl>
    </form>
  );
};

export default PrepForm;
