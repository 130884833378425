import { createTheme } from '@mui/material/styles';
import ProximaNova from './fonts/ProximaNova-Regular.woff2';

const proximaNova = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('ProximaNova'),
      local('ProximaNova-Regular'),
      url(${ProximaNova}) format('woff2')
    `
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#1D2F5C'
    },
    headerBackground: {
      main: '#C64D5B14'
    },
    active: {
      main: '#4190F7'
    }
  },
  typography: {
    fontFamily: ['ProximaNova', 'UtopiaStd'].join(',')
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [proximaNova]
      }
    }
  }
});

export default theme;
