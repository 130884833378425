import { useQuery } from '@tanstack/react-query';
import { createAPIFormsRepository } from '../../../../../modules/details/leaseForms/infrastructure/APIFormsRepository';
import { getForms } from '../../../../../modules/details/leaseForms/application/getForms';

const repository = createAPIFormsRepository();

export const useFormDetails = (
  leaseId: string,
  leaseFlow: string | null,
  leaseTerm: string | null
) => {
  const formsDetailsQuery = useQuery({
    queryKey: ['formsDetails', leaseId],
    queryFn: () => getForms(repository, leaseId, leaseFlow, leaseTerm),
    refetchOnWindowFocus: false
  });

  return {
    formsDetailsQuery,
    // Getter
    leaseId
  };
};
