/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlanTerm } from '../domain/Offers';
import { OffersRepository } from '../domain/OffersRepository';

export async function postOffer(
  offersRepository: OffersRepository,
  leaseId: string,
  term: string | null
): Promise<PlanTerm> {
  return await offersRepository.postTerm(leaseId, term);
}
