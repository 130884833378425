/* eslint-disable */
import associateAPI from '../../../../api/associateAPI';
import { CommunityList, formatCommunities } from '../domain/Community';
import { CommunityRepository } from '../domain/CommunityRepository';
// @ts-ignore
import { updateKeyValueLocalStorage } from '../../../../utils/updateKeyValueLocalStorage';

const METHOD_DASHBOARD = 'renewals-associate/dashboard';

export function createAPICommunitiesRepository(): CommunityRepository {
  return {
    getAll
  };
}

const getAll = async () => {
  try {
    const { data } = await associateAPI.get(METHOD_DASHBOARD);
    updateKeyValueLocalStorage('communities', formatCommunities(data));
    return data as CommunityList[];
  } catch (error) {
    return [] as CommunityList[];
  }
};
