import { useQuery } from '@tanstack/react-query';
import { getAllCommunities } from '../../../../../modules/dashboard/communities/application/get-all/getAllCommunities';
import { createAPICommunitiesRepository } from '../../../../../modules/dashboard/communities/infrastructure/APICommunityRepository';

const repository = createAPICommunitiesRepository();

export const useCommunities = () => {
  const communitiesQuery = useQuery({
    queryKey: ['communities'],
    queryFn: () => getAllCommunities(repository),
    staleTime: 1000 * 60 * 60
  });

  return {
    communitiesQuery
  };
};
