export const formatCurrency = (amount: number) => {
  return (
    amount &&
    amount.toLocaleString('en-US', {
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })
  );
};
