/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FormObject } from '../../../../../../modules/details/leaseForms/domain/LeaseModification';
import { AssociateContext } from '../../../../../../context/AssociateContext';

interface windowGuardRadioProps {
  answers: any;
  form: FormObject;
}

interface FormData {
  formId: number;
  formGroup: string;
  formData: { fieldName: string; answerValue: string }[];
}

interface NewFormData {
  formId: number;
  formGroup: string;
  formData: { fieldName: string; answerValue: string }[];
}

interface OriginalData {
  refId: string;
  formData: FormData[];
}

const WindowGuardRadio: FC<windowGuardRadioProps> = ({ answers, form }) => {
  const [value, setValue] = useState<string>('');
  const { formData, formLeaseModificationPayload } = useContext<any>(AssociateContext);

  const formatForms = (answer: string, form: FormObject) => {
    const fieldName = form?.formSchema?.fields?.find((field: any) =>
      field.fieldValues.includes(answer)
    ).fieldName;

    const formSelection = [
      {
        fieldName,
        answerValue: answer
      }
    ];

    const selection = {
      formId: form.formId,
      formGroup: form.formGroupName,
      formData: formSelection
    };
    let newData: OriginalData | [] = [];

    if (!formData) {
      formLeaseModificationPayload({
        form: selection.formData
      });
    } else {
      newData = filterAndAddIfNotExists(formData, selection);
    }
    return selection;
  };

  function filterAndAddIfNotExists(
    data: OriginalData | any,
    newFormData: NewFormData | any
  ): OriginalData {
    const existingForm = data?.find((item: any) => item.formId === newFormData.formId);
    if (existingForm) {
      newFormData.formData.map((newItem: any) => {
        const existingItem = existingForm.formData.find(
          (item: any) => item.fieldName === newItem.fieldName
        );

        if (existingItem) {
          existingItem.answerValue = newItem.answerValue;
        } else {
          existingForm.push(newItem);
        }
      });
    } else {
      data.push(newFormData);
    }

    return data;
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    formatForms(event.target.value, form);
  };
  return (
    <div style={{ paddingLeft: '1%' }}>
      <form>
        <FormControl sx={{ m: 0 }} variant="standard">
          <RadioGroup
            aria-labelledby="window-guard-radios"
            name="window-guard"
            value={value}
            onChange={handleRadioChange}>
            {answers?.fieldValues?.map((option: string, idx: number) => (
              <FormControlLabel key={idx} value={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        </FormControl>
      </form>
    </div>
  );
};

export default WindowGuardRadio;
