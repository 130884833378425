/* eslint-disable */

import React from 'react';
import { leaseDetailsCardStyles } from './styles';
// @ts-ignore
import filterCharges from '../../../../utils/filterCharges';
// @ts-ignore
import { formatCurrency } from '../../../../utils/formatCurrency';
import { Lease } from '../../../../modules/details/leaseDetails/domain/LeaseDetails';
import AdditionalCommonArea from './AdditionalCommonArea';

interface OneTimeFeeCardProps {
  data: Lease;
}

const OneTimeFeeCard = ({ data }: OneTimeFeeCardProps) => {
  return (
    <div style={{ ...leaseDetailsCardStyles.CenterRows, paddingTop: '5%' }}>
      <div style={{ ...leaseDetailsCardStyles.Notes, background: '#C64D5B14', textAlign: 'left' }}>
        <h3 style={{ padding: 0, margin: 0, fontSize: '16px' }}>One Time Fees *</h3>

        {filterCharges('one-time-fee', data?.monthlyCharges).map((charge: any, idx: number) => (
          <p
            key={idx}
            style={{
              ...leaseDetailsCardStyles.Row,
              margin: '8px 0px',
              marginTop: '8px',
              fontFamily: 'ProximaNova'
            }}>
            <span style={leaseDetailsCardStyles.LabelField}>{charge.chargeDescription}</span>
            <span style={leaseDetailsCardStyles.ValueField}>
              ${formatCurrency(charge.onetimeFee)}
            </span>
          </p>
        ))}

        <div style={{ display: 'flex' }}>
          <div style={{ paddingTop: '1.8%' }}>
            <div
              style={{
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                backgroundColor: '#31343A',
                marginRight: '4px'
              }}></div>
          </div>
          <span
            style={{
              ...leaseDetailsCardStyles.LabelField,
              fontWeight: 200,
              fontSize: '14px',
              fontFamily: 'ProximaNovaLight'
            }}>
            One Time Fees are charged on the 1st of the month following the lease start date.
          </span>
        </div>
        <div>
          {data?.additionalCaaFee > 0 && (
            <AdditionalCommonArea additionalCaaFee={data.additionalCaaFee} />
          )}
        </div>
      </div>
    </div>
  );
};

export default OneTimeFeeCard;
