import React, { FC } from 'react';
import Alert from '@mui/material/Alert';

interface alertMessageProps {
  isMontgomery: boolean;
}
const AlertMessage: FC<alertMessageProps> = ({ isMontgomery }) => {
  const message = () => {
    return (
      <div style={{ color: '#5F2B01', fontSize: '16px' }}>
        <div style={{ fontWeight: 'bold', paddingBottom: '2%' }}>
          <span>BEFORE YOU PROCEED:</span>
          <div>
            Validate that every responsible resident and guarantor has an email address in MRI.
          </div>
        </div>
        <div>
          If they do not have an email address:
          <div>Option 1 (Preferred)</div>
          <ul style={{ marginTop: '0px' }}>
            <li>
              <span style={{ fontWeight: 'bold' }}>Exit</span> this page and enter missing email
              addresses in MRI.
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}>Restart</span> the form generation process in
              MRI.
            </li>
          </ul>
          Option 2 Manual Signature
          <ul style={{ marginTop: '0px' }}>
            <li>
              <span style={{ fontWeight: 'bold' }}>Continue</span> to generate the lease package or
              lease forms.
            </li>
            <li>
              The DocuSign link will be emailed to the community. The lease must be signed{' '}
              <span style={{ fontWeight: 'bold' }}>manually </span> by all parties.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const montgomeryMessage = () => {
    return (
      <div style={{ color: '#5F2B01', fontSize: '16px' }}>
        <div style={{ fontWeight: 'bold' }}>
          <span>BEFORE YOU PROCEED:</span>
          <div>Validate that the resident’s information is complete in MRI. </div>
          <ul style={{ fontWeight: 'bold', marginTop: '0px' }}>
            <li>All responsible residents and guarantors must have an email address. </li>
            <li>At least one resident must have a phone number.</li>
            <li>
              At least one resident must have an emergency contact with a name and phone number.{' '}
            </li>
          </ul>
        </div>
        <div>
          If phone number or emergency contact is missing in MRI:
          <ol style={{ marginTop: '0px' }}>
            <li style={{ fontWeight: 'bold' }}>
              <span style={{ fontWeight: 'bold' }}>Exit</span>{' '}
              <span style={{ fontWeight: 'normal' }}>
                this page and enter enter missing information in MRI.
              </span>
            </li>
            <li style={{ fontWeight: 'bold' }}>
              <span style={{ fontWeight: 'bold' }}>Restart</span>{' '}
              <span style={{ fontWeight: 'normal' }}> the form generation process in MRI.</span>
            </li>
          </ol>
          If a resident’s email address is missing, you can either:
          <ul style={{ marginTop: 0 }}>
            <li>
              Enter the missing information in MRI and restart this process so the residents can
              sign via DocuSign <span style={{ fontWeight: 'bold' }}>OR</span>
            </li>
            <li>
              <span style={{ fontWeight: 'bold' }}> Continue</span> to generate the lease package
              with the missing email(s). The DocuSign link will be emailed to the community. The
              lease must be signed <span style={{ fontWeight: 'bold' }}> manually</span> by all
              parties.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div style={{ paddingLeft: '2%' }}>
      <Alert variant="outlined" severity="warning" sx={{ backgroundColor: '#FDF0E6' }}>
        {isMontgomery ? montgomeryMessage() : message()}
      </Alert>
    </div>
  );
};

export default AlertMessage;
