import { Addendum } from '../domain/LeaseDetailsGuardForm';
import { LeaseDetailsRepository } from '../domain/LeaseDetailsRepository';

export async function postGenerateLease(
  leaseDetailsRepository: LeaseDetailsRepository,
  leaseId: string | null,
  term: string | null,
  addendums?: Addendum[] | null
): Promise<number> {
  return await leaseDetailsRepository.postGenerateLease(leaseId, term, addendums);
}
