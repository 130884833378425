import { isUndefined } from 'lodash';

export default function parseToDecimalOrInt(amount, places = 2) {
  if (isUndefined(amount)) {
    return;
  }

  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  if (amount % 1 !== 0) {
    return amount.toFixed(places);
  }
  return amount;
}
