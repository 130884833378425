import axios, { AxiosRequestConfig } from 'axios';

const BASE_URL = process.env.REACT_APP_ASSOCIATE_URL;
const API_KEY = process.env.REACT_APP_ASSOCIATE_API_KEY;

function getAccessToken(): string | null {
  const token = localStorage.getItem('access_token');

  if (!token) {
    return null;
  }

  return token;
}

const leaseAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': API_KEY
  }
});

leaseAPI.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config: AxiosRequestConfig): any => {
    const accessToken = getAccessToken();

    if (accessToken && config.headers) {
      config.headers['x-id-token'] = accessToken;
      config.headers['x-access-token'] = accessToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default leaseAPI;
