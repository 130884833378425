import { NavigateFunction } from 'react-router-dom';
import { FormsProps, LeaseFlows } from './LeaseForms';
import { LeasePayload } from './LeaseModification';

export const getTerm = (leaseTerm: string) => {
  return leaseTerm === 'MTM' ? leaseTerm : Number(leaseTerm);
};

export interface FormDataItem {
  fieldName: string;
  answerValue: string;
}

export interface FormItem {
  formId: number;
  formGroup: string;
  formData: FormDataItem[];
}

export const isValid = (formData: []) => {
  const nonEmptyForms = formData?.filter((item: FormItem) => item?.formData?.length > 0);
  return nonEmptyForms?.every((item: FormItem) =>
    item.formData.every(
      (form) =>
        form.answerValue !== '' &&
        form.answerValue?.toLowerCase() !== 'invalid date' &&
        form.answerValue
    )
  );
};

export const validateFullLease = (
  form: FormsProps,
  currentSelection: string,
  leaseFlow: string,
  leasePayload: LeasePayload
) => {
  const { leaseTypes, leaseFlows } = form;
  const {
    lease: { leaseType }
  } = leasePayload;

  const mapToLowerCase = (lease: LeaseFlows[]) => {
    return lease?.map(({ name }) => name.toLowerCase());
  };

  if (currentSelection === 'Individual Lease') return true;

  const leasesNames = mapToLowerCase(leaseFlows);
  const leasesTypesNames = mapToLowerCase(leaseTypes);

  const includeLeaseFlow = leasesNames?.includes(leaseFlow);
  const includesLeaseType = leasesTypesNames?.includes(leaseType.toLowerCase());

  if (includeLeaseFlow || includesLeaseType) {
    return true;
  } else {
    return false;
  }
};

export const restoreState = (
  currentStep: (arg: {
    displaySelection: boolean;
    currentSelection: string;
    lastStep: number;
  }) => void,
  formLeaseModificationPayload: (arg: { form: never[] }) => void,
  navigate: NavigateFunction
) => {
  currentStep({ displaySelection: false, currentSelection: '', lastStep: 0 });
  formLeaseModificationPayload({
    form: []
  });
  navigate('/dashboard', { replace: true });
  localStorage.removeItem('formsView');
};
