import { Plan } from './LeaseDetails';

export const validatePlanProperties = (plan: Plan) => {
  const requiredProperties = ['totalCharges', 'leaseExpirationDate', 'leaseStartDate'];

  const allPropertiesPresent = requiredProperties.every((property) =>
    Object.prototype.hasOwnProperty.call(plan, property)
  );
  return allPropertiesPresent;
};

export interface NYC421CardProps {
  isRenewalPlan: boolean;
}
