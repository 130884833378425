//react
import { useEffect, useMemo, useState } from 'react';

//router
import { useLocation, useNavigate } from 'react-router-dom';

// material
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import FormGroup from '@mui/material/FormGroup';
import { Button } from '@mui/material';

import { initiateAuthFlow, LOGIN_URL, timeToRefreshSession } from './auth';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const MainHeader = (mainHeaderProps) => {
  const {
    companyName = 'AvalonBay Communities',
    projectName = 'Lease Manager Portal',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    login = () => {
      return;
    }
  } = mainHeaderProps;

  const [auth, setAuth] = useState(false);

  const navigate = useNavigate();

  const query = useQuery();
  const code = query.get('code');

  async function getTokensFromAuthorizationCode(code) {
    const idT = await initiateAuthFlow(code);
    login();
    const setToTrue = () => {
      setAuth(true);
    };
    setToTrue();
    if (idT) {
      const historyEntries = localStorage.getItem('formsView');
      setTimeout(() => {
        if (historyEntries) {
          navigate(`${historyEntries}`);
        } else {
          navigate('/dashboard');
        }
      }, 300);
    }
  }

  useEffect(() => {
    if (code) {
      getTokensFromAuthorizationCode(code);
    } else if (!timeToRefreshSession()) {
      login();
    }
  }, []);

  return (
    <Box>
      <AppBar position="static">
        <FormGroup>
          <Toolbar
            sx={{
              backgroundColor: 'white',
              color: 'custom.inputAdornmentColor',
              minHeight: '64px !important'
            }}>
            <p
              style={{
                flexGrow: 1,
                fontSize: '24px',
                color: '#31343A',
                display: 'flex',
                fontWeight: 300,
                justifyContent: 'flex-start'
              }}>
              {companyName}
            </p>
            <p
              style={{
                flexGrow: 1,
                fontSize: '24px',
                color: '#31343A',
                display: 'flex',
                fontWeight: 600,
                justifyContent: 'flex-start'
              }}>
              {projectName}
            </p>
            {!auth && (
              <div>
                {
                  <a href={LOGIN_URL}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ marginTop: 1, textTransform: 'capitalize' }}>
                      Log In
                    </Button>
                  </a>
                }
              </div>
            )}
          </Toolbar>
        </FormGroup>
      </AppBar>
    </Box>
  );
};

export default MainHeader;
