/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { OccupantDetail } from '../../../../modules/details/leaseDetails/domain/LeaseDetails';

interface ResponsibleResidentsProps {
  responsibleResidents: OccupantDetail[];
}

const ResponsibleResidents: FC<ResponsibleResidentsProps> = ({ responsibleResidents }) => {
  const parsedFullName = (resident: OccupantDetail) => {
    const nonNullValuesArr = [resident.firstName, resident.lastName].filter((item) => !!item);
    const fullName = nonNullValuesArr.join(' ');

    return fullName;
  };

  return (
    <div
      style={{
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
        borderWidth: '2px',
        borderRadius: '4px'
      }}>
      <p
        style={{
          color: '#31343A',
          fontSize: '18px',
          fontWeight: 500,
          textTransform: 'uppercase',
          paddingLeft: '2%'
        }}>
        Responsible residents & Guarantors
      </p>
      <div style={{ padding: '0 2% 2% 2%' }}>
        {responsibleResidents?.map((resident: any, idx: number) => (
          <div
            key={idx}
            style={{
              display: 'flex',
              color: resident?.email ? '#31343A' : 'red',
              fontWeight: resident?.email ? '500' : 'bold'
            }}>
            <div>{parsedFullName(resident)}</div>
            <div style={{ paddingLeft: '2%' }}>
              <div
                style={{
                  color: resident?.email ? '#31343A' : 'red',
                  fontWeight: resident?.email ? '500' : 'bold'
                }}>
                {resident?.email ?? '(No email in MRI)'}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResponsibleResidents;
