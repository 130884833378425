/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import { addTextBeforeParenthesis } from '../../../../../../modules/details/leaseForms/domain/LeaseFormsRepository';
import { validationLayer } from '../../../../../../modules/details/leaseForms/domain/LeaseForms';

interface textFieldProps {
  label: string;
  value: string;
  onChange: (e: string) => void;
  validateFullFormInput: boolean;
  validationPattern: string;
}

const TextFieldUtil: FC<textFieldProps> = ({
  label,
  value,
  onChange,
  validateFullFormInput,
  validationPattern
}) => {
  const [error, setError] = useState<boolean>(true);

  const [updateValue, setUpdateValue] = useState('');

  const validateForm = (e: string) => {
    if (!validateFullFormInput) {
      setError(false);
      return;
    }
    if (e?.length || value?.length) {
      validationLayer(e, validationPattern, setUpdateValue, setError);
    } else {
      setError(true);
      setUpdateValue('');
    }
  };

  useEffect(() => {
    validateForm(value);
  }, []);

  return (
    <form>
      <FormControl sx={{ m: 0 }} error={error} variant="standard">
        <div
          style={{
            padding: '1% 0%'
          }}>{`${addTextBeforeParenthesis(label, 'enter as:')}`}</div>
        <OutlinedInput
          size="small"
          placeholder={`${label}`}
          value={updateValue}
          onChange={(e) => {
            onChange(e.target.value);
            validateForm(e.target.value);
          }}
          endAdornment={
            validationPattern?.includes('%') && <InputAdornment position="end">%</InputAdornment>
          }
          startAdornment={
            validationPattern?.includes('$') && <InputAdornment position="start">$</InputAdornment>
          }
          sx={{ width: '295px' }}
        />
        {error && <FormHelperText sx={{ color: '#F5222D' }}>This field is required</FormHelperText>}
      </FormControl>
    </form>
  );
};

export default TextFieldUtil;
