/* eslint-disable no-undef */
import { redirect } from 'react-router-dom';
import { Buffer } from 'buffer';

export const localConstants = {
  ID_TOKEN_KEY: 'id_token',
  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  EXPIRES_IN_KEY: 'expires_in',
  TOKEN_TYPE_KEY: 'token_type',
  EMAIL_KEY: 'email',
  TRANSFER_KEY: 'transfer',
  LOGOUT_KEY: 'logout',
  EXPIRY_TS: 'expiryTS',
  ID_GIVEN_NAME: 'givenName',
  ID_FAMILY_NAME: 'familyName',
  ID_USER_NAME: 'userName'
};

const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_COGNITO_CLIENT_SECRET;
const COGNITO_ROOT_URL = process.env.REACT_APP_COGNITO_ROOT;
const CALLBACK_URL = process.env.REACT_APP_COGNITO_CALLBACK;

export const LOGIN_URL = `${COGNITO_ROOT_URL}/login?client_id=${CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${CALLBACK_URL}`;
export const refreshAssociateToken = async () => {
  const REFRESH_TOKEN = localStorage.getItem(localConstants.REFRESH_TOKEN_KEY);

  const body = {
    grant_type: 'refresh_token',
    refresh_token: REFRESH_TOKEN
  };

  const requestOptions = generateRequestOptions(body);
  const res = await fetch(`${COGNITO_ROOT_URL}/oauth2/token`, requestOptions);

  // Save the token, new expiry time etc. to local storage
  // Return the new ID token
  const idToken = await storeCredentials(res);
  return idToken;
};

export const decodeToken = (token) => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const decodedToken = JSON.parse(jsonPayload);
  return decodedToken;
};

export const storeCredentials = async (responseObj) => {
  const credentials = await responseObj.json();
  const {
    id_token: idToken,
    error,
    refresh_token: refreshToken,
    token_type: tokenType,
    expires_in: expiresIn,
    access_token: accessToken
  } = credentials;
  if (!error) {
    const _refreshToken = localStorage.getItem(localConstants.REFRESH_TOKEN_KEY) ?? refreshToken;

    const decodedToken = decodeToken(idToken);
    localStorage.setItem('id_token', `Bearer ${idToken}`);
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', _refreshToken);
    localStorage.setItem('token_type', tokenType);
    localStorage.setItem('expires_in', expiresIn);
    localStorage.setItem('email', decodedToken?.name);
    const currentTimeStamp = new Date().getTime();
    const expiryTimeStamp = new Date();
    expiryTimeStamp.setTime(currentTimeStamp + expiresIn * 1000);
    localStorage.setItem('expiryTS', expiryTimeStamp.getTime().toString());
    localStorage.setItem('expiryTime', expiryTimeStamp.toISOString());
    const givenName = handleName(decodedToken?.given_name);
    const familyName = handleName(decodedToken?.family_name);
    localStorage.setItem('givenName', givenName ?? '');
    localStorage.setItem('familyName', familyName ?? '');
    localStorage.setItem('userName', `${givenName} ${familyName}`);

    return idToken;
  }
  return '';
};

export const initiateAuthFlow = async (code) => {
  const url = `${COGNITO_ROOT_URL}/oauth2/token`;

  const body = {
    grant_type: 'authorization_code',
    code
  };

  const requestOptions = generateRequestOptions(body);

  const resp = await fetch(url, requestOptions);

  const idToken = await storeCredentials(resp);

  return idToken;
};

export const getCredentials = async () => {
  const ID_TOKEN = localStorage.getItem(localConstants.ID_TOKEN_KEY);
  const expiryTS = Number.parseInt(localStorage.getItem(localConstants.EXPIRY_TS), 10);

  const now = new Date().getTime();
  if (now < expiryTS) {
    return ID_TOKEN;
  }

  const refreshedIDToken = await refreshAssociateToken()
    .then(async (response) => {
      const respObj = JSON.parse(response);
      if ('error' in respObj || Object.keys(respObj).length === 0) {
        document.location.href = LOGIN_URL;
      }
    })
    .catch(async () => {
      // handle errors
      // Start the auth flow
      // Await and return the id token
      document.location.href = LOGIN_URL;
      return '';
    });
  return refreshedIDToken ?? '';
};

export const handleLogin = () => {
  redirect(LOGIN_URL);
};

export const fetchUserName = () => {
  return localStorage.getItem(localConstants.ID_USER_NAME);
};

export const fetchEmail = () => {
  return localStorage.getItem(localConstants.EMAIL_KEY);
};

export const fetchExpiryTime = () => localStorage.getItem(localConstants.EXPIRY_TS);

export const timeToRefreshSession = () => {
  const currentTime = new Date().getTime();
  const expiryTime = Number(fetchExpiryTime());

  return currentTime > expiryTime;
};

const handleName = (name) => name;

const generateRequestOptions = (uniqueBits) => {
  const bodyBuilder = {
    client_id: CLIENT_ID,
    redirect_uri: CALLBACK_URL
  };

  Object.entries(uniqueBits).forEach(([k, v]) => (bodyBuilder[k] = v));
  const encode = (str) => Buffer.from(str, 'binary').toString('base64');

  const base64Encoded = encode(`${CLIENT_ID}:${CLIENT_SECRET}`);
  const body = Object.entries(bodyBuilder)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  const returnValue = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${base64Encoded}`
    },
    body
  };

  return returnValue;
};
