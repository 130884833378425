/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import { helpRequestStyles } from './styles';
import { CircularProgress } from '@mui/material';
import {
  HeaderKey,
  getStyleForKey,
  matchHeaderTable,
  isHeaderKey
} from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import HelpRequestContent from './HelpRequestContent';
import WindowGuardResolutionAndSelection from './WindowGuardResolutionAndSelection';

interface WindowGuardResponseProps {
  isResolved: boolean;
  residentResponse: string[];
  resolveHelpRequestQuery: any;
  description: string;
  handleResolveHelpRequest: () => any;
  selectedButtonIndex: number;
  tableBody: Record<HeaderKey, string>;
  idx: number;
}

const WindowGuardResponse: React.FC<WindowGuardResponseProps> = ({
  isResolved,
  residentResponse,
  resolveHelpRequestQuery,
  handleResolveHelpRequest,
  selectedButtonIndex,
  tableBody,
  idx
}) => {
  return (
    <>
      {!isResolved ? (
        <>
          <div style={helpRequestStyles.windowGuardResponseContainer}>
            <HelpRequestContent tableBody={tableBody} />
          </div>
          <WindowGuardResolutionAndSelection
            residentResponse={residentResponse}
            isResolved={isResolved}
          />
        </>
      ) : (
        <div style={helpRequestStyles.windowGuardResponseContainer}>
          {Object.keys(tableBody).map((key, idx) => {
            if (!isHeaderKey(key)) return null;

            const styleByKey = getStyleForKey(key as HeaderKey);
            return (
              <Fragment key={idx}>
                <div style={styleByKey}>
                  <ul style={helpRequestStyles.windowGuardListStyle}>
                    <li
                      style={{
                        ...helpRequestStyles.fontStyleMRI,
                        whiteSpace: 'initial'
                      }}>
                      {tableBody[key] && matchHeaderTable(key as HeaderKey)}
                    </li>

                    <li style={{ fontSize: '16px', color: '#31343A' }}>
                      {key === 'description' ? (
                        <>
                          <div style={{ paddingBottom: '20px' }}>{tableBody[key]}</div>
                          <WindowGuardResolutionAndSelection residentResponse={residentResponse} />
                        </>
                      ) : (
                        tableBody[key]
                      )}
                    </li>
                  </ul>
                </div>
              </Fragment>
            );
          })}
        </div>
      )}
      <div>
        {!isResolved && (
          <>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'end', paddingTop: '6%' }}>
              {resolveHelpRequestQuery?.isFetching && selectedButtonIndex === idx ? (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  style={helpRequestStyles.DisabledButton}
                  disabled={true}>
                  <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                  style={helpRequestStyles.PrimaryButton}
                  onClick={handleResolveHelpRequest}>
                  <p style={{ fontSize: '16px', color: '#FFFFFF', fontFamily: 'ProximaNova' }}>
                    Resolve
                  </p>
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WindowGuardResponse;
