import { MonthlyCharge, OccupantDetail } from './LeaseDetails';

enum OCCUPANT_TYPES {
  RESPONSIBLE = 'Responsible Resident',
  GUARANTOR = 'Guarantor'
}

export const isHOU = (monthlyCharges: MonthlyCharge[]) => {
  return monthlyCharges?.some(({ chargeCode }: MonthlyCharge) => chargeCode === 'HOU');
};

export const filterResposibleAndGuarantors = (occupantDetails: OccupantDetail[]) => {
  return occupantDetails.filter(
    ({ type }) => type === OCCUPANT_TYPES.RESPONSIBLE || type === OCCUPANT_TYPES.GUARANTOR
  );
};

export const missingOccupantEmail = (occupantDetails: OccupantDetail[]) => {
  const occupants = filterResposibleAndGuarantors(occupantDetails);
  return occupants.some((occupant) => occupant?.email === null || occupant?.email == undefined);
};

export const numberMissingEmails = (occupantDetails: OccupantDetail[]) => {
  return occupantDetails.filter(({ email }) => email == null || email == undefined).length;
};

export const missingPhoneNumber = (occupantDetails: OccupantDetail[], communityCode: string) => {
  const montgomeryCommunities = ['MD006', 'MD007', 'MD015', 'MD016', 'MD017', 'MD018', 'MD031'];

  const isPhoneMissing =
    occupantDetails.some(
      ({ type, phoneNumber }) =>
        type === OCCUPANT_TYPES.RESPONSIBLE && (phoneNumber === null || phoneNumber === undefined)
    ) && montgomeryCommunities.some((community) => communityCode.includes(community));
  return isPhoneMissing;
};

export const listFullName = (occupantDetails: OccupantDetail[]) => {
  return occupantDetails
    .filter(({ email }) => email == null || email == undefined)
    .map(({ firstName, lastName }) => `${firstName + ' ' + lastName}`);
};

export const quantityMissingEmails = (occupantDetails: OccupantDetail[]): string => {
  const number = numberMissingEmails(occupantDetails);
  const numbersInWords: string[] = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten'
  ];

  if (number >= 0 && number <= 10) {
    return numbersInWords[number];
  } else {
    return 'more than 10';
  }
};
