interface FlexContainerProps {
  display?: 'flex';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | undefined;
  paddingLeft?: string;
  position?: 'fixed' | undefined;
  bottom?: string;
  width?: string;
}

const RowText = {
  paddingLeft: '2%',
  fontSize: '16px',
  color: '#31343A'
};

const UnitColumn = {
  fontSize: '14px',
  color: '#31343A',
  fontWeight: 'bold'
};

const BottomPositionPagination: FlexContainerProps = {
  position: 'fixed',
  bottom: '0px',
  width: '100%'
};

const PaginationContainer = {
  height: '50px',
  backgroundColor: 'white',
  boxShadow: ' 0px 0px 10px #41454D4D',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
};

const ChipStatusContainer = {
  borderRadius: '4px',
  height: '19px',
  font: 'normal normal 300 12px/18px ProximaNova',
  border: '1px',
  borderStyle: 'solid',
  alignItems: 'self',
  display: 'flex',
  justifyContent: 'left'
};

const ExpirationDate = {
  color: '#F5222D',
  fontWeight: 'bold',
  fontSize: '15px',
  verticalAlign: 'top'
};

const LeaseDate = {
  color: '#31343A',
  fontSize: '16px',
  verticalAlign: 'top'
};

const RequestHours = {
  paddingLeft: '2%',
  color: '#F5222D',
  fontWeight: 'bold',
  fontSize: '16px'
};

const RegHour = {
  color: '#31343A',
  fontSize: '16px'
};

// ResidentTable
const CenterCell = {
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex',
  alignItems: 'center'
};

const NameCell = {
  justifyContent: 'start',
  paddingLeft: '10%',
  verticalAlign: 'top',
  display: 'flex',
  flex: 1
};

const ContainerCell = {
  width: '10px',
  verticalAlign: 'top',
  padding: '10px 5px'
};

const TableDataContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '25vh'
};

const WarningMessageText = { fontSize: '24px', color: '#31343A', fontWeight: '600' };

export const residentStyles = {
  RowText,
  RegHour,
  UnitColumn,
  BottomPositionPagination,
  PaginationContainer,
  ChipStatusContainer,
  ExpirationDate,
  LeaseDate,
  RequestHours,

  // ResidentTable
  CenterCell,
  NameCell,
  ContainerCell,
  TableDataContainer,
  WarningMessageText
};
