/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useRef, FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { styled } from '@mui/material/styles';

// @ts-ignore
import { residentStyles } from './styles';

import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// @ts-ignore
import { Container } from '@mui/material';
// @ts-ignore
import { useEffect } from 'react';
// @ts-ignore
import ChipStatus from '../../../shared/chipStatus/ChipStatus';
import {
  ResidentTableProps,
  addIconDocumentStatus,
  calculateTableHeight,
  completedStatus,
  displayAddIcon,
  getWidth,
  headerData,
  isLeaseExpired,
  newTableHeight
} from '../../../modules/dashboard/residents/domain/ResidentTable';
import ArrowIcons from '../components/table/ArrowIcons';
import StatusLabel from '../components/table/StatusLabel';
import TableSkeletonRow from '../components/table/TableSkeletonRow';
import ErrrorScreen from '../../../shared/errorScreen/ErrrorScreen';
import { Result, Status } from '../../../modules/dashboard/residents/domain/Resident';
import DocumentLabels from '../components/table/DocumentLabels';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F6F6F7'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const ResidentTable: FC<ResidentTableProps> = ({
  tableData,
  isLoading,
  isError,
  handleOrderByAndOrderDirection
}) => {
  const columnCount = 11;
  const skeletonRowCount = 7;
  const rowSkeletonHeight = 40;

  const [orderDirection, setOrderDirection] = useState('asc');
  const [toggleRowList, setToggleRowList] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleSortRequest = (orderBy: string) => {
    if (orderBy === 'term' || orderBy === 'requestType') return;
    handleOrderByAndOrderDirection(orderBy, orderDirection);
    setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleResidentClick = (item: any, event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.button === 2) {
      window.open(`/details/${item.leaseId}`, '_blank');
    } else {
      navigate(`/details/${item.leaseId}`);
    }
  };

  const displayTerm = (status: string) => {
    const invalidStatus = ['Renewal Ready', 'Forced MTM', 'NTV Completed'];
    return !invalidStatus.includes(status);
  };

  const toggleRow = (e: any, id: number) => {
    e.stopPropagation();
    if (isRowExpanded(id)) {
      setToggleRowList(toggleRowList.filter((item) => item !== id));
    } else {
      setToggleRowList([...toggleRowList, id]);
    }
  };

  const isRowExpanded = (id: number) => {
    return toggleRowList.filter((item) => item === id).length > 0;
  };

  const [tableHeight, setTableHeight] = useState('55vh');

  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const heightTable = calculateTableHeight(window.innerHeight);
    setTableHeight(heightTable);
  }, [window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      const heightTable = tableRef?.current?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (heightTable) {
        const newHeight = newTableHeight(windowHeight, heightTable);
        setTableHeight(newHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container maxWidth={false}>
      <TableContainer component={Paper} ref={tableRef} sx={{ flex: 1, maxHeight: tableHeight }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ width: '10px' }}></StyledTableCell>
              {headerData.map((header: any, idx: number) => (
                <StyledTableCell
                  key={idx}
                  sx={{
                    paddingLeft: `${getWidth(header.name)?.paddingLeft}`,
                    width: `${getWidth(header.name)?.width}`,
                    borderLeft: `${idx != 0 ? '1px solid #e3e8ec' : ''}`
                  }}>
                  <ArrowIcons header={header} handleSortRequest={handleSortRequest} />
                </StyledTableCell>
              ))}
              <StyledTableCell sx={{ width: '10px', fontSize: '12px' }}>
                Document Status
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading
              ? [...Array(skeletonRowCount)].map((_, idx) => (
                  <TableSkeletonRow
                    key={idx}
                    columnCount={columnCount}
                    rowSkeletonHeight={rowSkeletonHeight}
                    hideFirstColumn={true}
                  />
                ))
              : tableData?.map((row: Result, idx: number) => (
                  <StyledTableRow
                    key={idx}
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => handleResidentClick(row, e)}
                    onContextMenu={(e) => handleResidentClick(row, e)}>
                    <TableCell onClick={(e) => toggleRow(e, idx)}>
                      <div style={{ ...residentStyles.CenterCell }}>
                        {displayAddIcon(row) &&
                          (!isRowExpanded(idx) ? <AddIcon /> : <MinimizeIcon />)}
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{ gap: '5px', flexDirection: 'column' }}>
                      <StatusLabel item={row} idx={idx} isRowExpanded={isRowExpanded} />
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          ...residentStyles.RowText,
                          ...residentStyles.NameCell
                        }}>
                        <div
                          style={
                            row?.requestHoursOpen !== null && row?.requestHoursOpen >= 48
                              ? residentStyles.RequestHours
                              : residentStyles.RowText
                          }>
                          {row?.requestHoursOpen}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div style={residentStyles.CenterCell}>{row?.communityCode}</div>
                    </TableCell>
                    <TableCell align="left" style={{ ...residentStyles.UnitColumn }}>
                      {row?.unitId}
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          ...residentStyles.RowText,
                          ...residentStyles.NameCell
                        }}>
                        <div>
                          {row?.firstName} {row?.lastName}
                        </div>
                        <div
                          style={{
                            ...residentStyles.CenterCell,
                            paddingLeft: '5%'
                          }}>
                          {row.hou ? ChipStatus('HOU') : undefined}
                        </div>
                        <div
                          style={{
                            ...residentStyles.CenterCell,
                            paddingLeft: '5%'
                          }}>
                          {row.legal ? ChipStatus('LEGAL') : undefined}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          ...residentStyles.RowText,
                          ...residentStyles.NameCell,
                          flexWrap: 'wrap',
                          paddingLeft: '0%'
                        }}>
                        <div
                          style={
                            row?.leaseEndDate &&
                            isLeaseExpired(row.leaseEndDate) &&
                            !completedStatus(row?.status)
                              ? residentStyles.ExpirationDate
                              : residentStyles.LeaseDate
                          }>
                          {row?.leaseEndDate && (
                            <div>
                              {dayjs(row?.leaseEndDate.split('T')[0]).format('MMM D, YYYY')}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            ...residentStyles.CenterCell,
                            paddingLeft: '5%',
                            width: '50%'
                          }}>
                          {row?.leaseEndDate &&
                          isLeaseExpired(row.leaseEndDate) &&
                          row?.status !== Status.RenewalCompleted
                            ? ChipStatus('forced-mtm')
                            : undefined}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {row?.ntvDueDate && (
                        <div
                          style={{
                            ...residentStyles.LeaseDate,
                            paddingLeft: '0%'
                          }}>
                          {dayjs(row?.ntvDueDate.split('T')[0]).format('MMM D, YYYY')}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ paddingLeft: '3%' }}>
                      {row?.leaseType.toUpperCase() === 'MARKET'
                        ? row?.requestType === 'notice-to-vacate-no-offer'
                          ? ChipStatus(row?.status)
                          : ChipStatus(row?.label, row?.lastModified)
                        : null}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        ...residentStyles.RowText,
                        paddingLeft: '20px',
                        verticalAlign: 'top'
                      }}>
                      {row?.term &&
                        displayTerm(row?.label) &&
                        (row?.term !== 'MTM' ? row?.term + ' months' : 'MTM')}
                    </TableCell>
                    <TableCell onClick={(e) => toggleRow(e, idx)}>
                      <div style={{ ...residentStyles.CenterCell }}>
                        {addIconDocumentStatus(row?.documentStatus) &&
                          (!isRowExpanded(idx) ? <AddIcon /> : <MinimizeIcon />)}
                      </div>
                    </TableCell>
                    <TableCell sx={{ paddingLeft: '0' }}>
                      <DocumentLabels row={row} isRowExpanded={isRowExpanded} idx={idx} />
                    </TableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
        {!isLoading && !isError && tableData?.length === 0 && (
          <div style={residentStyles.TableDataContainer}>
            <p style={residentStyles.WarningMessageText}>
              {tableData?.length === 0 && 'No data found'}
            </p>
          </div>
        )}
        {isError && (
          <ErrrorScreen
            message={
              ' Sorry, something went wrong on our end. We are working on fixing the problem. Please try again.'
            }
            btnLabel={'Refresh'}
            fixHeight={'50vh'}
            refreshPage={refreshPage}
            redirectTo={'/dashboard'}
          />
        )}
      </TableContainer>
    </Container>
  );
};

export default ResidentTable;
