import React from 'react';
import Alert from '@mui/material/Alert';

const ManualLeaseGeneration = () => {
  const message = () => {
    return (
      <div style={{ fontSize: '16px' }}>
        <p style={{ fontWeight: 'bold', margin: '0px', fontSize: '16px' }}>
          Manual Lease Generation
        </p>
        <p style={{ marginTop: '0px' }}>
          This lease has been generated for manual signature. Data on this page will not accurately
          reflect the resident’s signature status.
        </p>
        <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>
          After all manual signatures have been completed:
        </p>
        <ol style={{ marginTop: '0px' }}>
          <li>Ensure the renewal is committed in MRI.</li>
          <li>Ensure the renewal lease is uploaded to Salesforce.</li>
          <li>
            <span style={{ fontWeight: 'bold' }}>Force Complete</span> the renewal to finish this
            process.
          </li>
        </ol>
        <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>
          If you need to make a change to the renewal lease:
        </p>
        <ol style={{ marginTop: '0px', marginBottom: '0px' }}>
          <li>Make the change in MRI.</li>
          <li>Re-generate the renewal from the Renewal Plan card.</li>
        </ol>
        <p style={{ marginTop: '0px' }}>
          <span style={{ fontWeight: 'bold' }}>NOTE:</span>You will not be able to void leases
          generated for manual signature. Ensure you select the most recently generated lease for
          manual signature.
        </p>
      </div>
    );
  };
  return (
    <div style={{ paddingBottom: '2%', maxWidth: '1146px' }}>
      <Alert variant="outlined" severity="warning" sx={{ backgroundColor: '#FDF0E6' }}>
        {message()}
      </Alert>
    </div>
  );
};

export default ManualLeaseGeneration;
