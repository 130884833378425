interface FlexContainerProps {
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: number;
  textAlign?: 'left' | 'right' | undefined;
}

// ConcessionCard
const CenterRows = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const Notes = {
  width: '371px',
  padding: '8px',
  marginTop: '16px',
  border: 'solid 1px #D1AFB1',
  borderRadius: '4px'
};

// AdditionalCommonArea
const Subtitle = {
  font: 'normal normal 300 16px/20px',
  color: '#31343A',
  fontSize: '16px'
};

const LabelField = {
  font: 'normal normal 300 16px/20px',
  color: '#31343A',
  maxWidth: '303px'
};
const LabelFieldDate = {
  ...LabelField,
  fontSize: '16px'
};
const ValueField = {
  font: 'normal normal 600 18px/22px',
  color: '#31343A',
  fontWeight: '600',
  fontSize: '16px'
};
const ValueFieldDate = {
  ...ValueField,
  fontSize: '18px'
};

const Row = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between'
};

const DescriptionText = {
  fontWeight: '200',
  fontSize: '14px',
  fontFamily: 'ProximaNovaLight'
};

const Separation = {
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: '#31343A',
  marginRight: '4px'
};

// NYC421 Card

const BoxStyleNYC = {
  width: '85%',
  border: '1px solid #D1AFB1',
  backgroundColor: '#FAF1F2',
  borderRadius: '4px',
  padding: '2%'
};

const TitleFontNYC = {
  fontFamily: 'ProximaNova',
  fontWeight: 600,
  fontSize: '16px',
  display: 'flex'
};

const BodyFontNYC: FlexContainerProps = {
  fontFamily: 'ProximaNova',
  fontWeight: 200,
  fontSize: '14px',
  textAlign: 'left'
};

const SupStyle = {
  fontWeight: 'bold',
  fontSize: '16px'
};

const FontBoldNYC = {
  fontWeight: 'bold'
};

const PaddingLeftNYC = {
  paddingLeft: '2%'
};

const BottomSeparationNYC = { paddingBottom: '2%' };

export const leaseDetailsCardStyles = {
  CenterRows,
  Notes,
  LabelField,
  Subtitle,
  LabelFieldDate,
  ValueField,
  ValueFieldDate,
  DescriptionText,
  Separation,
  Row,
  // NYC421 Card
  BoxStyleNYC,
  TitleFontNYC,
  BodyFontNYC,
  FontBoldNYC,
  PaddingLeftNYC,
  BottomSeparationNYC,
  SupStyle
};
