/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
// @ts-ignore
import full_package from '../../../../../assets/imgs/leaseModification/full.svg';
import GenerateFullLease from './GenerateFullLease';

import FullLeaseAdditionalInfo from './FullLeaseAdditionalInfo';

interface fullLeaseProps {
  expandFull: boolean;
  step: number;
  handleClose: () => void;
  handleStep: (value: number) => void;
}

const FullLeasePackage: FC<fullLeaseProps> = ({ expandFull, step, handleClose, handleStep }) => {
  return (
    <div>
      <div
        style={{
          flexDirection: 'column',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: expandFull ? 'flex' : 'none'
        }}>
        <div style={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'capitalize' }}>
          Generate full lease package
        </div>
        <img style={{ padding: '3%' }} src={full_package} width={'43%'} alt="Full lease package" />
      </div>
      {step === 1 && <GenerateFullLease handleClose={handleClose} handleStep={handleStep} />}
      {step === 2 && <FullLeaseAdditionalInfo handleStep={handleStep} />}
    </div>
  );
};

export default FullLeasePackage;
