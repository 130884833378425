/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { DateField } from '@mui/x-date-pickers/DateField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { addTextBeforeParenthesis } from '../../../../../../modules/details/leaseForms/domain/LeaseFormsRepository';

interface dateFieldProps {
  label: string;
  value: Dayjs | null;
  onChange: (e: string) => void;
  validateFullFormInput: boolean;
  validationPattern: string;
}

const DateSelectUtil: FC<dateFieldProps> = ({
  label,
  value = null,
  onChange,
  validateFullFormInput
}) => {
  const [error, setError] = useState<boolean>(true);

  const validateForm = (e: string | Dayjs | null) => {
    if (!validateFullFormInput) {
      setError(false);
      return;
    }
    if (dayjs(e).isValid()) {
      setError(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    validateForm(value);
  }, []);

  return (
    <form>
      <FormControl sx={{ m: 0 }} error={error} variant="standard">
        <div
          style={{
            padding: '1% 0%'
          }}>{`${addTextBeforeParenthesis(label, 'enter as:')}`}</div>

        <DateField
          variant="outlined"
          size="small"
          defaultValue={null}
          onChange={(newValue) => {
            if (newValue) {
              onChange(dayjs(newValue).format('MM/DD/YYYY'));
              validateForm(newValue);
            }
          }}
          sx={{
            width: '295px',
            border: error ? '1px solid red' : '',
            borderRadius: error ? '5px' : '',
            '&:hover': {
              bgcolor: 'inherit'
            }
          }}
        />

        {error && <FormHelperText sx={{ color: '#F5222D' }}>This field is required</FormHelperText>}
      </FormControl>
    </form>
  );
};

export default DateSelectUtil;
