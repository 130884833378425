// CheckboxesList

interface FlexContainerProps {
  display?: 'flex';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | undefined;
  maxWidth?: string;
  textTransform?: 'capitalize' | undefined;
  fontFamily?: string;
  paddingLeft?: string;
}

const CheckBoxListContainer: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
};

const TypoAndContainers = {
  fontFamily: 'ProximaNova',
  fontSize: '16px',
  width: '30%'
};

const TypoAndContainerForms = {
  fontFamily: 'ProximaNova',
  fontSize: '16px',
  width: '100%'
};

const FilterPadding = {
  padding: '2% 0'
};

// DialogContainerFilters

const CheckBox = {
  width: '193px',
  fontFamily: 'ProximaNova',
  fontSize: '12px'
};

const DialogContainer = {
  width: '20px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.5s'
};

const MenuContainer: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '250px'
};

const DialogPadding = {
  padding: '2px 0'
};

// FilterCheckbox

const FilterContainer = {
  flex: 1,
  padding: '0 8px',
  height: 'fitContent',
  maxHeight: '40px'
};

const TypoLabel = {
  fontFamily: 'ProximaNova',
  fontSize: '14px'
};

const TypoSubLabel = { fontSize: '12px' };

// FilterOptionsCheckboxes

const HeadTitleFilter = {
  fontFamily: 'ProximaNova',
  fontSize: '18px',
  fontWeight: 600,
  color: '#31343A',
  padding: '2% 0 2% 1% '
};

// FiltersContainer

const FilterButtonContainer = { display: 'flex', justifyContent: 'center', items: 'center' };

const FilterText: FlexContainerProps = {
  fontFamily: 'ProximaNova',
  paddingLeft: '10%',
  textTransform: 'capitalize'
};

const DialogCloseBtnContainer = { display: 'flex', justifyContent: 'flex-end' };

const DialogClearBtnContainer = { display: 'flex', justifyContent: 'flex-start' };

export const filterStyles = {
  // DialogContainerFilters
  CheckBox,
  DialogContainer,
  MenuContainer,
  DialogPadding,

  // FilterCheckbox
  FilterContainer,
  TypoLabel,
  TypoSubLabel,

  // FilterOptionsCheckboxes
  HeadTitleFilter,

  // CheckboxesList
  TypoAndContainers,
  CheckBoxListContainer,
  FilterPadding,
  TypoAndContainerForms,

  // FiltersContainer
  FilterButtonContainer,
  FilterText,
  DialogCloseBtnContainer,
  DialogClearBtnContainer
};
