const Container = {
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  textAlign: 'center',
  zIndex: 99
};

const Overlay = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  opacity: '40%',
  background: '#B2D4FC'
};

const Content = {
  boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
  background: '#fff',
  position: 'absolute',
  width: '350px',
  left: '50%',
  marginLeft: '-175px',
  marginTop: '30px'
};

const Header = {
  padding: '25px 0px 25px 0px',
  background: 'lightgray'
};

const Body = {
  padding: '15px',
  textAlign: 'left'
};

const Button = {
  height: '40px',
  textAlign: 'center',
  marginBottom: '15px',
  color: '#fff',
  background: '#5bc0de',
  borderColor: '#46b8da',
  width: '100%'
};

const userFullName = {
  backgroundColor: 'white',
  marginLeft: '0.5ch',
  color: '#5d6368',
  fontSize: ' 16px'
};

const signoutIcon = {
  backgroundColor: 'white',
  marginLeft: '0.8ch',
  color: '#5d6368',
  fontSize: '16px'
};

export const styles = {
  Container,
  Overlay,
  Content,
  Header,
  Body,
  Button,
  signoutIcon,
  userFullName
};
