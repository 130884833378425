/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';

import React, { FC, useEffect, useState, useContext } from 'react';
import { AssociateContext } from '../../../../../../context/AssociateContext';

interface WindowGuardCheckBoxesProps {
  answers: any;
  form: any;
}

interface FormData {
  formId: number;
  formGroup: string;
  formData: { fieldName: string; answerValue: string }[];
}

interface NewFormData {
  formId: number;
  formGroup: string;
  formData: { fieldName: string; answerValue: string }[];
}

interface OriginalData {
  formData: FormData[] | any;
}

interface FieldType {
  fieldType: string;
}

const WindowGuardCheckBoxes: FC<WindowGuardCheckBoxesProps> = ({ answers, form }) => {
  const [checkboxes, setCheckboxes] = useState<any[]>([]);

  const { formData, formLeaseModificationPayload } = useContext<any>(AssociateContext);

  const createOptions = (answers: any) => {
    const values = answers[0]?.fieldValues;

    const options = [...values]?.map((value: any) => ({
      answerText: value,
      key: false
    }));

    return options;
  };

  const formatForms = (answerArray: any, form: any) => {
    let fieldName = '';
    if (!answerArray.length) {
      fieldName = form?.formSchema?.fields?.find(
        (field: any) => field?.fieldType === 'checkbox'
      ).fieldName;
    } else {
      fieldName = form?.formSchema?.fields?.find((field: any) =>
        field?.fieldValues?.includes(answerArray[0].answerText)
      ).fieldName;
    }

    const forms = [
      {
        fieldName: fieldName,
        answerValue: answerArray.map((answer: any) => answer.answerText)
      }
    ];

    const selection = {
      formId: form.formId,
      formGroup: form.formGroupName,
      formData: forms
    };
    let newData: OriginalData | [] = [];
    if (!formData) {
      formLeaseModificationPayload({
        form: selection.formData
      });
    } else {
      newData = filterAndAddIfNotExists(formData, selection);
    }
    return selection;
  };

  function filterAndAddIfNotExists(
    data: OriginalData | any,
    newFormData: NewFormData | any
  ): OriginalData {
    const existingForm = data?.find((item: any) => item.formId === newFormData.formId);

    if (existingForm) {
      newFormData?.formData.map((newItem: any) => {
        const existingItem = existingForm?.formData.find(
          (item: any) => item.fieldName === newItem.fieldName
        );

        if (existingItem) {
          existingItem.answerValue = newItem.answerValue;
        } else {
          existingForm.push(newItem);
        }
      });
    } else {
      data.push(newFormData);
    }

    return data;
  }

  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].key = !updatedCheckboxes[index].key;
    const selectedCheckboxes = updatedCheckboxes.filter((checkbox) => checkbox.key);

    formatForms(selectedCheckboxes, form);
    setCheckboxes(updatedCheckboxes);
  };

  const includeCheckbox = () => {
    return form?.formSchema?.fields?.filter((type: FieldType) => type.fieldType === 'checkbox');
  };

  const selectOptionsToDisplay = (answers: any) => {
    const optionsToDisplay =
      answers.length && includeCheckbox().length ? createOptions(answers) : [];

    setCheckboxes(optionsToDisplay);
  };

  useEffect(() => {
    selectOptionsToDisplay(answers);
  }, []);

  return (
    <div style={{ paddingLeft: '1%' }}>
      {checkboxes?.map((option, index) => (
        <FormGroup key={index}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ paddingTop: 0 }}
                size="medium"
                checked={option.key}
                onChange={() => handleCheckboxChange(index)}
              />
            }
            label={option.answerText}
          />
        </FormGroup>
      ))}
    </div>
  );
};

export default WindowGuardCheckBoxes;
