import associateAPI from '../../../../api/associateAPI';
import leaseAPI from '../../../../api/leaseAPI';
import { HelpRequestRepository } from '../domain/HelpRequestRepository';

const METHOD_HELP_REQUEST = 'renewals-associate/help-request/';
const METHOD_ASSOCIATE_DETAILS = 'renewals-associate/lease/';
const METHOD_RESEND_EMAIL = '/leases/resend-envelope/';

export function createAPIHelpRequestRepository(): HelpRequestRepository {
  return {
    patchHelpRequest,
    putNoticeToVacate,
    putResendEmail
  };
}

const patchHelpRequest = async (helpRequestId: string | null) => {
  const data = { resolved: true };
  try {
    const { status } = await associateAPI.patch(METHOD_HELP_REQUEST + helpRequestId, data);
    return status as number;
  } catch (error) {
    return error as number;
  }
};

const putNoticeToVacate = async (
  leaseId: string | null,
  enteredInMri: boolean,
  ntvId: string | null,
  helpRequestRefId: string | null
) => {
  const data = { enteredInMri, ntvId, helpRequestRefId };
  try {
    const { status } = await associateAPI.put(
      METHOD_ASSOCIATE_DETAILS + leaseId + '/resolve-ntv',
      data
    );
    return status as number;
  } catch (error) {
    return error as number;
  }
};

const putResendEmail = async (refId: string | null) => {
  try {
    const { status } = await leaseAPI.put(METHOD_RESEND_EMAIL + refId);
    return status as number;
  } catch (error) {
    return error as number;
  }
};
