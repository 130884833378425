const ResidentSearchContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row'
};

const ClearBtnContainer = {
  paddingBottom: '1%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '1%'
};

const TextStyle = {
  backgroundColor: '#31343A',
  textTransform: 'capitalize',
  fontFamily: 'ProximaNova'
};

export const dashboardStyles = {
  ResidentSearchContainer,
  ClearBtnContainer,
  TextStyle
};
