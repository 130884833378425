/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FC, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { AssociateContext } from '../../../../../context/AssociateContext';
import { useSearchParams } from 'react-router-dom';
import { isInvalidLeaseType } from '../../../../../modules/details/leaseForms/domain/SelectLease';

interface generateFullProps {
  handleClose: () => void;
  handleStep: (value: number) => void;
}

const GenerateFullLease: FC<generateFullProps> = ({ handleClose, handleStep }) => {
  const { currentStep, lastStep, leasePayload } = useContext<any>(AssociateContext);
  const [selectedValue, setSelectedValue] = useState('');
  // router
  const [searchParams] = useSearchParams();
  const leaseFlow: string | undefined = searchParams.get('leaseFlow')?.toLowerCase();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    currentStep({ currentSelection: event.target.value, displaySelection: false, lastStep });
  };

  return (
    <div>
      {' '}
      <div style={{ padding: '2%' }}>
        <div style={{ fontSize: '24px', color: '#31343A', textTransform: 'capitalize' }}>
          Generate Full Lease Package
        </div>
        <div style={{ fontSize: '16px', color: '#72767F', paddingTop: '1%' }}>
          Forms will be automatically compiled into a DocuSign package.
        </div>
      </div>
      <div style={{ padding: '2%' }}>
        {leaseFlow === 'renewals' && isInvalidLeaseType(leasePayload) && (
          <p style={{ fontWeight: 600, fontSize: '16px' }}>
            This selection is only available for associate, affordable, and corporate lease types.
          </p>
        )}
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="packages"
            name="packages"
            style={{ display: 'flex', flexDirection: 'column' }}
            value={selectedValue}
            onChange={handleChange}>
            <FormControlLabel
              value="New Lease Package"
              style={{ display: leaseFlow === 'renewals' ? 'none' : 'block' }}
              control={<Radio />}
              label="New Lease Package"
            />
            <FormControlLabel
              value="Renewal Lease Package"
              control={<Radio />}
              style={{ display: leaseFlow === 'currentlease' ? 'none' : 'block' }}
              disabled={leaseFlow === 'renewals' && isInvalidLeaseType(leasePayload)}
              label="Renewal Lease Package"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div
        style={{
          padding: '2%',
          paddingTop: '15%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <div>
          <Button onClick={handleClose}>Back</Button>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => handleStep(1)}
            disabled={
              (leaseFlow === 'renewals' && isInvalidLeaseType(leasePayload)) || selectedValue === ''
            }>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GenerateFullLease;
