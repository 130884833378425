/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import NoticeToVacateMRI from './NoticeToVacateMRI';

const NoticeToVacateContent = ({
  tableBody,
  resolveNoticeToVacateQuery,
  handleResolve,
  selectedButtonIndex,
  idx
}: any) => {
  return (
    <div>
      <NoticeToVacateMRI
        tableBody={tableBody}
        resolveNoticeToVacateQuery={resolveNoticeToVacateQuery}
        handleResolve={handleResolve}
        selectedButtonIndex={selectedButtonIndex}
        idx={idx}
      />
    </div>
  );
};

export default NoticeToVacateContent;
