/* eslint-disable @typescript-eslint/no-explicit-any */
export type HeaderKeyLease =
  | 'startDate'
  | 'moveInDate'
  | 'leaseTerm'
  | 'rent'
  | 'securityDeposit'
  | 'govermentLicenceFee'
  | 'monthlyCharges'
  | 'totalMonthlyCharges';

type HeaderMap = {
  [key in HeaderKeyLease]?: string | any;
};

export const matchHeaderLease = (propertyHeader: HeaderKeyLease): string => {
  const HEADER: HeaderMap = {
    startDate: 'Start Date',
    moveInDate: 'Move In Date',
    leaseTerm: 'Lease Term',
    rent: 'Rent',
    securityDeposit: 'Security Deposit',
    govermentLicenceFee: 'Goverment Licence Fee',
    monthlyCharges: 'Monthly Charges',
    totalMonthlyCharges: 'Total Monthly Charges'
  };

  const header = propertyHeader ? HEADER[propertyHeader] : '';
  return header;
};

export const headerKeysLease: HeaderKeyLease[] = [
  'startDate',
  'moveInDate',
  'leaseTerm',
  'rent',
  'securityDeposit',
  'govermentLicenceFee',
  'monthlyCharges',
  'totalMonthlyCharges'
];

// Fees
export type HeaderKeyFee = 'feeA' | 'feeB' | 'feeC' | 'feeD' | 'feeE';

type HeaderMapFee = {
  [key in HeaderKeyFee]: string;
};

export const matchHeaderFee = (propertyHeader: HeaderKeyFee): string => {
  const HEADER: HeaderMapFee = {
    feeA: 'Fee A',
    feeB: 'Fee B',
    feeC: 'Fee C',
    feeD: 'Fee D',
    feeE: 'Fee E'
  };

  const header = HEADER[propertyHeader] || '';
  return header;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isHeaderKeyLease = (key: any): key is HeaderKeyLease => {
  return headerKeysLease.includes(key as HeaderKeyLease);
};
