/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import LeaseDetailsSubHeader from '../../leaseDetails/components/leaseDetails/LeaseDetailsSubHeader';
import { AssociateContext } from '../../../context/AssociateContext';
import LeaseHeader from '../../../shared/header/LeaseHeader';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { clearLocalStorage } from '../../../../src/utils/clearLocalStorage';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { timeToRefreshSession } from '../../../../src/components/signIn/auth';

// material UI

import { FormsViewstyles } from './styles';
import ResponsibleResidents from '../components/responsibleResidents/ResponsibleResidents';
import {
  getResponsableResidents,
  isMontgomeryCommunity
} from '../../../modules/details/leaseForms/domain/LeaseForms';
import LeaseSumary from '../components/leaseSummary/LeaseSumary';
import TypeOfGenerateLease from '../components/typeOfGenerateLease/TypeOfGenerateLease';
import SelectedLease from '../components/selectedLeaseModification/SelectedLease';
import AlertMessage from '../components/alertMessage/AlertMessage';
import { useFormDetails } from '../components/typeOfGenerateLease/hooks/useLeaseFormsDetails';
import SkeletetonForm from './SkeletetonForm';
import { formatCurrency } from '../../../utils/formatCurrency';
import dayjs from 'dayjs';

import ErrrorScreen from '../../../shared/errorScreen/ErrrorScreen';
import CancelProcess from '../components/typeOfGenerateLease/components/CancelProcess';

const FormsView = () => {
  const { leaseDetails, displaySelection, currentSelection, lastStep } =
    useContext<any>(AssociateContext);
  const [isMontgomery, setIsMontgomery] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { leaseId = '0' } = useParams();
  const leaseFlow: string | null = searchParams.get('leaseFlow');
  const leaseTerm: string | null = searchParams.get('leaseTerm');

  // queries
  const { formsDetailsQuery } = useFormDetails(leaseId, leaseFlow, leaseTerm);

  const handleLogout = () => {
    clearLocalStorage(['activeFilters', 'communities']);
    localStorage.removeItem('formsView');
    navigate('/');
  };
  const errorCodes = ['ERR_NETWORK', 'ERR_BAD_RESPONSE', 'ERR_BAD_REQUEST'];
  const getToken = () => {
    const accessToken = localStorage.getItem('access_token');
    !accessToken && handleLogout();
  };

  useEffect(() => {
    leaseDetails({ leasePayload: formsDetailsQuery.data });

    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }
    getToken();
  }, [formsDetailsQuery.data]);

  useEffect(() => {
    const leaseTerm = searchParams.get('leaseTerm');
    const leaseFlow = searchParams.get('leaseFlow');
    localStorage.setItem(
      'formsView',
      `lease-details/${leaseId}?leaseTerm=${leaseTerm}&leaseFlow=${leaseFlow}`
    );
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = '#F6F6F7';
  }, []);

  useEffect(() => {
    const res = leaseId ? isMontgomeryCommunity(leaseId) : false;
    setIsMontgomery(res);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === 'hidden' &&
        localStorage.getItem('isFormRendered') === 'true'
      ) {
        localStorage.removeItem('formsView');
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Fragment>
      {formsDetailsQuery.isLoading && (
        <>
          <SkeletetonForm />
        </>
      )}
      {!formsDetailsQuery.isLoading &&
        !formsDetailsQuery.isError &&
        !formsDetailsQuery.data?.code && (
          <LeaseHeader
            title="AvalonBay Communities"
            appName="Lease Manager Portal"
            handleLogout={handleLogout}
            generateLeaseForms={true}>
            <LeaseDetailsSubHeader
              leaseDetails={formsDetailsQuery.data}
              generateLeaseForms={true}
            />
            {!formsDetailsQuery?.isLoading && !formsDetailsQuery.isError && (
              <div style={{ ...FormsViewstyles.Content }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '30%', display: 'flex', flexFlow: 'column' }}>
                    {displaySelection && <SelectedLease />}
                    <ResponsibleResidents
                      responsibleResidents={getResponsableResidents(
                        formsDetailsQuery.data?.responsiblePersons
                      )}
                    />
                    <LeaseSumary
                      leaseDetails={formsDetailsQuery.data?.lease}
                      leaseContent={{
                        startDate: `${dayjs(formsDetailsQuery.data?.lease?.leaseStartDate).format('M/DD/YYYY')}`,
                        moveInDate: `${dayjs(formsDetailsQuery.data?.lease?.moveInDate).format('M/DD/YYYY')}`,
                        leaseTerm: formsDetailsQuery.data?.lease?.leaseTerm,
                        rent: `$${formatCurrency(formsDetailsQuery.data?.lease?.baseRent)} / month`,
                        totalMonthlyCharges: ` $${formatCurrency(formsDetailsQuery.data?.lease?.totalCharges)}`
                      }}
                      feesContent={{
                        fees: formsDetailsQuery.data?.lease?.fees ?? []
                      }}
                      monthlyCharges={{
                        monthlyCharges: formsDetailsQuery.data?.lease?.monthlyCharges
                      }}
                    />
                  </div>
                  <div style={{ width: '70%' }}>
                    {!currentSelection?.length && <AlertMessage isMontgomery={isMontgomery} />}
                    <TypeOfGenerateLease />
                    <div>{lastStep === 1 ? <CancelProcess /> : <></>}</div>
                  </div>
                </div>
              </div>
            )}
          </LeaseHeader>
        )}
      {(formsDetailsQuery.isError ||
        (formsDetailsQuery.data && errorCodes.includes(formsDetailsQuery.data.code))) && (
        <ErrrorScreen
          message={
            'Sorry, something went wrong on our end. We are working on fixing the problem. Please try again.'
          }
          btnLabel={'Go to Dashboard'}
          redirectTo={'/dashboard'}
        />
      )}
    </Fragment>
  );
};

export default FormsView;
