interface FlexContainerProps {
  display?: 'flex';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | undefined;
  paddingLeft?: string;
  position?: 'fixed' | undefined;
  bottom?: string;
  width?: string;
  minHeight?: string;
  alignItems?: string;
  background?: string;
  borderRadius?: string;
  border?: string;
  maxWidth?: string;
  justifyContent?: string;
}

const h2 = {
  fontSize: '20px',
  padding: '25px 0'
};

const row = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '40px',
  alignItems: 'center'
};
const col = {
  width: '18%',
  paddingLeft: '1.5%'
};

const colLarge = {
  width: '34%',
  paddingLeft: '1.5%'
};
const header = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '40px',
  alignItems: 'center',
  background: '#F6F6F7',
  border: 'solid 1px #979AA0',
  borderRadius: '3px',
  maxWidth: '1146px'
};
const content = {
  border: 'solid 1px #DCDDDF',
  borderRadius: '3px',
  maxWidth: '1146px'
};

const occupantDetailsContainer: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '40px',
  alignItems: 'center',
  background: '#F6F6F7',
  border: 'solid 1px #979AA0',
  borderRadius: '3px',
  maxWidth: '1146px'
};

const occupantContent: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'row',
  minHeight: '40px',
  alignItems: 'center',
  justifyContent: 'left'
};

export const occupantDetailsStyles = {
  h2,
  row,
  col,
  colLarge,
  header,
  content,
  occupantDetailsContainer,
  occupantContent
};
