import React, { FC } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

import { styles } from './styles';

interface ErrorScreenProps {
  message: string;
  btnLabel: string;
  fixHeight?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshPage?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redirectTo: any;
}

const ErrrorScreen: FC<ErrorScreenProps> = ({
  message,
  btnLabel,
  fixHeight = '80vh',
  redirectTo
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any) => {
    e.preventDefault();
    // window.location.reload();
    navigate(redirectTo, { replace: true });
  };
  return (
    <Container fixed>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: `${fixHeight}`
        }}>
        <h2>{message}</h2>
        <div>
          <Button variant="outlined" style={styles.Margin} onClick={handleClick}>
            {btnLabel}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ErrrorScreen;
