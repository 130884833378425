/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { styles } from './styles';

export const DialogType = {
  Alert: 1,
  Success: 2,
  Confirm: 3,
  NoIcon: 4
};

const HeaderIcon = (type: number) => {
  switch (type) {
    case DialogType.Alert:
      return <CancelIcon sx={styles.CloseIcon} />;
    case DialogType.Success:
      return <CheckCircleIcon sx={styles.SuccessIcon} />;
    case DialogType.Confirm:
      return <ErrorIcon sx={styles.ConfirmIcon} />;
    default:
      break;
  }
};

const Body = (type: any, body: any, isLeaseMod: boolean) => {
  return <div style={{ padding: isLeaseMod ? '15px 20px 20px 20px' : '' }}>{body}</div>;
};

const Dialog = (props: any) => {
  const {
    type,
    headerLabel,
    body,
    btnLabel1,
    btnLabel2,
    isOpen,
    customWidth,
    isLeaseMod = false,
    cancelOpt = false,
    btnLabel2Callback,
    handleCloseConfirmDialog
  } = props;

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          ...styles.Model,
          width: customWidth ? customWidth : '423px',
          paddingLeft: isLeaseMod ? '0px' : '24px',
          paddingRight: isLeaseMod ? '0px' : '24px'
        }}>
        <Grid container alignItems={'stretch'}>
          <Grid item lg={12} container sx={styles.Header}>
            <Grid item lg>
              <div
                style={{
                  ...styles.Heading,
                  fontSize: isLeaseMod ? '16px' : '20px',
                  fontWeight: isLeaseMod ? 500 : 'bold',
                  textTransform: isLeaseMod ? 'capitalize' : 'none',
                  paddingLeft: isLeaseMod ? '20px' : ''
                }}>
                {HeaderIcon(type)}
                {headerLabel}
              </div>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon sx={styles.closeButton} onClick={() => handleCloseConfirmDialog()} />
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            sx={{
              ...styles.Body,
              backgroundColor: isLeaseMod ? '#F6F6F7' : 'transparent',
              marginTop: isLeaseMod ? '0px' : '16px',
              marginBottom: isLeaseMod ? '0px' : '16px'
            }}>
            {Body(type, body, isLeaseMod)}
          </Grid>
          <Grid
            item
            container
            lg={12}
            sx={{ ...styles.Footer, justifyContent: isLeaseMod ? 'space-around' : 'center' }}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}>
            {btnLabel1.length ? (
              <Button
                href="#"
                variant={
                  isLeaseMod ? 'text' : type === DialogType.Confirm ? 'outlined' : 'contained'
                }
                size="large"
                sx={styles.GeneralButton}
                onClick={() => {
                  handleCloseConfirmDialog();
                  btnLabel1 === 'OK' && btnLabel2Callback && btnLabel2Callback();
                }}>
                {btnLabel1}
              </Button>
            ) : (
              <></>
            )}
            {(type === DialogType.Confirm || type === DialogType.NoIcon) && btnLabel2.length ? (
              <Button
                variant="contained"
                size="large"
                sx={{ ...styles.Button, backgroundColor: cancelOpt ? '#C62828' : '#1D2F5C' }}
                onClick={() => {
                  btnLabel2Callback && btnLabel2Callback();
                  handleCloseConfirmDialog();
                }}>
                {btnLabel2}
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Dialog;
