import React, { FC } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FilterCheckboxProps } from '../../../../modules/dashboard/residents/domain/ResidentsFilters';
import { filterStyles } from './styles';

const FilterCheckbox: FC<FilterCheckboxProps> = ({ label, checkedItems, handleChange }) => {
  return (
    <FormGroup>
      <span
        style={{
          ...filterStyles.FilterContainer,
          backgroundColor:
            label !== undefined && checkedItems.get(label.value) ? 'rgb(0,163,224 , 0.1)' : ''
        }}>
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              checked={checkedItems.get(label !== undefined && label.value) || false}
              onChange={handleChange}
              name={label !== undefined ? label.value : ''}
            />
          }
          label={
            <div style={filterStyles.TypoLabel}>
              <div>{label !== undefined && label.displayName} </div>
              {label !== undefined && label?.sublabel !== undefined && (
                <div style={filterStyles.TypoSubLabel}>{label.sublabel}</div>
              )}
            </div>
          }
        />
      </span>
    </FormGroup>
  );
};

export default FilterCheckbox;
