interface LeasePayloadProps {
  lease?: {
    leaseType?: string;
  };
}

const disallowedTypes = ['AFFORDABLE', 'ASSOCIATE', 'CORPORATE'];

export const isInvalidLeaseType = (leasePayload: LeasePayloadProps) => {
  const leaseType = leasePayload?.lease?.leaseType?.toUpperCase();
  return !!leaseType && !disallowedTypes.includes(leaseType);
};

const disallowedCommunities = [
  'NY011',
  'NY018',
  'NY026',
  'NY037',
  'NY039',
  'NY041',
  'NY525',
  'NY533',
  'NY534',
  'NY815',
  'NY821',
  'NY823',
  'NY829',
  'NY834'
];

export const isInvalidCommunity = (community: string) => {
  return disallowedCommunities.some((code) => community.toUpperCase().includes(code));
};
