/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { FC } from 'react';
// @ts-ignore
// import partial_package from '../../../../../assets/imgs/leaseModification/partial_package.png';
// @ts-ignore
import individual from '../../../../../assets/imgs/leaseModification/individual.svg';
import SelectLeaseModification from './SelectLeaseModification';
import AdditionalInformation from './AdditionalInformation';

interface individualLeaseProps {
  expdandIndividual: boolean;
  step: number;
  handleClose: () => void;
  handleStep: (value: number) => void;
}

const IndividualLeaseForm: FC<individualLeaseProps> = ({
  expdandIndividual,
  step,
  handleClose,
  handleStep
}) => {
  return (
    <div>
      <div
        style={{
          flexDirection: 'column',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: expdandIndividual ? 'flex' : 'none'
        }}>
        <div style={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'capitalize' }}>
          Generate individual lease forms
        </div>
        <img style={{ padding: '3%' }} src={individual} width={'50%'} alt="partial lease package" />
      </div>
      {step === 1 && <SelectLeaseModification handleClose={handleClose} handleStep={handleStep} />}
      {step === 2 && <AdditionalInformation handleStep={handleStep} />}
    </div>
  );
};

export default IndividualLeaseForm;
