/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LabelStatus from '../../../../shared/labelStatus/LabelStatus';

import Typography from '@mui/material/Typography';
import { displayTwoRowLabels } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import { helpRequestStyles } from './styles';

const HelpRequestHeader = ({ labels, subLabel, status, title, date }: any) => {
  return (
    <div style={helpRequestStyles.headerContainer}>
      <div style={displayTwoRowLabels({ labels })}>
        {labels?.length > 0 ? (
          labels?.map((label: any, idx: number) => (
            <div key={idx} style={{ paddingRight: '5px' }}>
              <LabelStatus label={label} subLabel={subLabel} />
            </div>
          ))
        ) : (
          <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
        )}
      </div>
      <div style={helpRequestStyles.headerStatus}>
        <div>{status === 'lease-generated' ? 'Completed' : status}</div>
        <div style={{ paddingLeft: '10px', paddingRight: '5px' }}>{date}</div>
      </div>
    </div>
  );
};

export default HelpRequestHeader;
