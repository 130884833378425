/* eslint-disable @typescript-eslint/no-explicit-any */
import associateAPI from '../../../../api/associateAPI';
import { LeaseDetails } from '../domain/LeaseDetails';
import { Addendum } from '../domain/LeaseDetailsGuardForm';
import { LeaseDetailsRepository } from '../domain/LeaseDetailsRepository';

const METHOD_ASSOCIATE_DETAILS = 'renewals-associate/lease/';

export function createAPILeaseDetailsRepository(): LeaseDetailsRepository {
  return {
    get,
    putVoidLease,
    postForcedLease,
    postGenerateLease
  };
}

const get = async (leaseId: string | null) => {
  try {
    const { data } = await associateAPI.get(METHOD_ASSOCIATE_DETAILS + leaseId);
    return data as LeaseDetails;
  } catch (error) {
    return error as LeaseDetails;
  }
};

const putVoidLease = async (leaseId: string | null, docuRefId: string | null) => {
  const data = {
    refId: docuRefId
  };
  try {
    const { status } = await associateAPI.put(METHOD_ASSOCIATE_DETAILS + leaseId + '/void', data);
    return status as number;
  } catch (error) {
    console.error(error);
    return error as number;
  }
};

const postForcedLease = async (leaseId: string | null) => {
  try {
    const { status } = await associateAPI.post(
      METHOD_ASSOCIATE_DETAILS + leaseId + '/force-complete'
    );

    return status as number;
  } catch (error) {
    return error as number;
  }
};

const postGenerateLease = async (
  leaseId: string | null,
  term: string | null,
  addendums?: Addendum[] | null
) => {
  try {
    const { status } = await associateAPI.post(
      METHOD_ASSOCIATE_DETAILS + leaseId + '/generate?term=' + term,
      addendums
    );
    return status as number;
  } catch (error) {
    return error as number;
  }
};
