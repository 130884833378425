import React from 'react';
import PrepForm from '../../../../sections/leaseDetails/components/leaseDetails/PrepForm';
import MultipleGuardForm from '../../../../sections/leaseDetails/components/leaseDetails/MultipleGuardForm';

import WindowGuardDescription from '../../../../sections/leaseDetails/components/leaseDetails/WindowGuardDescription';
import SingleGuardForm from '../../../../sections/leaseDetails/components/leaseDetails/SingleGuardForm';
import WindowGuardMontgomeryInfo from '../../../../sections/leaseDetails/components/leaseDetails/WindowGuardMontgomeryInfo';

export interface IWindowGuardMultiple {
  title: string;
  info?: JSX.Element;
  montgomeryInfo?: JSX.Element;
  guideline: JSX.Element;
  question: string;
  prepForm?: JSX.Element;
  guidelineSelection: string;
  form?: JSX.Element;
  note: string | JSX.Element;
  icon: string;
}

export interface Addendums {
  communityCode: string;
  addendums: Addendum[];
}

export interface Addendum {
  formName: string;
  responses: Response[];
}

export interface Response {
  answerId: number;
  answerValue: string;
}

const INFO_MESSAGE: JSX.Element = (
  <>
    AvalonBay is required by law to install window guards on any window in the apartment that can be{' '}
    <br />
    opened and is located above a ground floor if a child 10 years of age or younger lives in the
    apartment, or if <br />
    the resident asks to have window guards put in at any time. (The resident does not need to give
    a reason.)
  </>
);

const WindowGuardMultiple: IWindowGuardMultiple = {
  title: 'Response Required to Generate Lease Form',
  info: INFO_MESSAGE,
  montgomeryInfo: <WindowGuardMontgomeryInfo />,
  guideline: (
    <>
      Please ask the resident the following questions so that the DocuSign lease can be <br />
      prepared with their selections for signature. Select their answer here:
    </>
  ),
  question: 'Are window guards currently installed in your apartment?',
  prepForm: <PrepForm />,
  guidelineSelection: 'Please select all that apply:',
  form: <MultipleGuardForm />,
  note: <WindowGuardDescription description={false} />,
  icon: 'confirm'
};
const WindowGuardSingle: IWindowGuardMultiple = {
  title: 'Response Required to Generate Lease Form',
  info: INFO_MESSAGE,
  guideline: (
    <>
      Please ask the resident the following questions so that the DocuSign lease can be <br />
      prepared with their selections for signature. Select their answer here:
    </>
  ),
  question: 'Are window guards currently installed in your apartment?',
  prepForm: <PrepForm />,
  guidelineSelection: 'Select one:',
  form: <SingleGuardForm />,
  note: <WindowGuardDescription description={false} />,
  icon: 'confirm'
};

export const windowGuardForms = {
  WindowGuardMultiple,
  WindowGuardSingle
};
