/* eslint-disable @typescript-eslint/no-explicit-any */

interface FlexContainerProps {
  fontFamily?: string;
  fontWeight?: string | undefined;
  fontSize?: string | undefined;
  color?: string | undefined;
  whiteSpace?: string | any;
  textTransform?: string | any;
  display?: 'flex';
  flexDirection?: string | any;
  textAlign?: string | any;
  justifyContent?: string | any;
  alignItems?: string;
  padding?: string;
  gap?: string;
  margin?: number;
  font?: string | any;
  background?: string | any;
  border?: string | any;
  borderRadius?: string | any;
  height?: string | any;
  width?: string | any;
  pointerEvents?: string | any;
  marginTop?: string | any;
  cursor?: string;
  lineHeight?: string;
}

const Header = {
  boxShadow: '0px -5px 13px',
  padding: '16px 40px 30px 40px',
  color: '#31343A'
};

const BackButton = {
  color: '#707070',
  textDecoration: 'none',
  display: 'flex',
  marginLeft: '-0.5rem'
};

const H2 = {
  color: '#252E5C',
  margin: 0,
  height: '29px'
};
const Card_H2 = {
  color: '#31343A',
  margin: 0,
  height: '29px',
  fontSize: '30px',
  font: 'normal normal normal 30px Utopia Std'
};
const RightAign = {
  alignItems: 'flex-end',
  justifyContent: 'flex-start'
};

const FontSmall: FlexContainerProps = {
  fontSize: '14px',
  color: '#31343A'
};

const SmallBox: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 'bold',
  font: 'normal normal bold 16px/20px ProximaNova'
};

const Points: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'row',
  padding: '0',
  gap: '1.5rem',
  margin: 0,
  color: '#31343A',
  font: 'normal normal 300 14px/17px ProximaNova'
};

const Content = {
  margin: '100px 40px 30px 40px',
  font: 'normal normal normal 16px/20px ProximaNova'
};

const CardLayout = {
  maxWidth: '1146px',
  padding: '16px'
};

const Margin = { marginTop: '14px', gap: '14px' };

const StatusBox = {
  background: ' #FA8C1640 0% 0% no-repeat padding-box',
  border: '1px solid #FA8C16',
  borderRadius: '4px',
  padding: '2px 8px'
};

const PrimaryButton: FlexContainerProps = {
  font: 'normal normal 600 14px/17px ProximaNova',
  background: '#31343A',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  lineHeight: '1.75'
};

const GeneralButton: FlexContainerProps = {
  ...PrimaryButton,
  borderRadius: '4px',
  height: '44px',
  width: '300px',
  marginTop: '30px',
  cursor: 'pointer'
};

const DisabledButton: FlexContainerProps = {
  background: '#BABCC0',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  border: 0,
  pointerEvents: 'none'
};

const DisableGeneralButton: FlexContainerProps = {
  ...GeneralButton,
  background: '#BABCC0',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  border: 0,
  pointerEvents: 'none'
};

const NonClickableButton = {
  background: '#1D2F5C',
  cursor: 'none',
  color: '#FFFFFF',
  font: 'normal normal 600 19px/23px ProximaNova',
  letterSpacing: '0.001px'
};

const WarningText = {
  color: '#F5222D',
  fontWeight: 'bold'
};
const SuccessText = {
  color: '#52C41A',
  fontWeight: 'bold'
};
const NoBullet = {
  listStyle: 'none'
};
const PropertyId = {
  font: 'normal normal normal 16px/20px ProximaNova',
  color: '#31343A'
};
const capitalize = {
  textTransform: 'capitalize',
  font: 'normal normal 600 16px/20px ProximaNova',
  color: '#31343A'
};
const Signatures = {
  ...FontSmall,
  marginRight: '1.25rem'
};
const DetailsCardsContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0',
  paddingBottom: '44px',
  maxWidth: '1146px'
};
const DetailsCard = {
  width: '450px',
  height: 'auto',
  border: 'solid 1px #979AA0',
  borderTop: 'solid 8px #979AA0',
  borderRadius: '4px 4px 0px 0px'
};
const LeaseCardTopBg = {
  background: '#C64D5B33 0% 0% no-repeat padding-box',
  border: '1px solid #70707034',
  height: '32px'
};
const RenewCardTopBg = {
  background: '#12BABA 0% 0% no-repeat padding-box',
  border: '1px solid #707070',
  height: '32px',
  opacity: '0.2'
};
const CardContent: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'column',
  padding: '15px 0 50px 0',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  color: '#31343A'
};
const Info = {
  font: 'normal normal normal 14px/17px',
  color: '#31343A',
  width: '252px',
  fontSize: '14px',
  opacity: 0.8,
  marginTop: '15px'
};
const LabelField = {
  font: 'normal normal 300 16px/20px',
  color: '#31343A',
  maxWidth: '303px'
};
const LabelFieldDate = {
  ...LabelField,
  fontSize: '16px'
};
const ValueField = {
  font: 'normal normal 600 18px/22px',
  color: '#31343A',
  fontWeight: '600',
  fontSize: '16px'
};
const ValueFieldDate = {
  ...ValueField,
  fontSize: '18px'
};
const Divider = {
  height: '1px',
  background: '#979AA0',
  width: '100%',
  marginTop: '20px'
};
const TableView = {
  width: '373px',
  fontSize: '14px'
};
const Row = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between'
};
const Notes = {
  width: '371px',
  padding: '8px',
  marginTop: '16px',
  border: 'solid 1px #D1AFB1',
  borderRadius: '4px',
  textAlign: 'left'
};

const CenterRows = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const textInfoTooltip = {
  fontFamily: 'ProximaNova',
  fontSize: '12px',
  lineHeight: '1.3'
};

const guideLineText = { fontSize: '16px', fontWeight: 'bold', paddingTop: '12px' };

export const leaseDetailsStyles = {
  Header,
  BackButton,
  H2,
  RightAign,
  FontSmall,
  SmallBox,
  Points,
  Content,
  Margin,
  StatusBox,
  PrimaryButton,
  DisabledButton,
  DisableGeneralButton,
  CardLayout,
  WarningText,
  SuccessText,
  NoBullet,
  NonClickableButton,
  PropertyId,
  capitalize,
  Signatures,
  DetailsCardsContainer,
  DetailsCard,
  LeaseCardTopBg,
  RenewCardTopBg,
  CardContent,
  Info,
  LabelField,
  ValueField,
  Divider,
  TableView,
  Row,
  GeneralButton,
  Card_H2,
  LabelFieldDate,
  ValueFieldDate,
  Notes,
  CenterRows,
  textInfoTooltip,
  guideLineText
};
