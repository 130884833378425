// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateKeyValueLocalStorage = (prop: string, value: any): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    try {
      const activeFiltersStorage = JSON.parse(localStorage.getItem('activeFilters') || '{}');
      activeFiltersStorage[prop] = value;

      localStorage.setItem('activeFilters', JSON.stringify(activeFiltersStorage));
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
