/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import CloseBtn from '../../sections/leaseDetails/components/summaryCard/CloseBtn';
import { AssociateContext } from '../../context/AssociateContext';
import ErrorIcon from '@mui/icons-material/Error';
import WindowGuardSelectionSection from '../../sections/leaseDetails/components/leaseDetails/WindowGuardSelectionSection';
import { includesCommunity } from '../../modules/details/leaseDetails/domain/LeaseDetails';

const WindowGuardForm = ({
  openDialog,
  setOpenDialog,
  title,
  info,
  montgomeryInfo,
  question,
  guideline,
  prepForm,
  guidelineSelection,
  form,
  note
}: any) => {
  const {
    addendumPayload,
    prepFormValue,
    updateFormStatus,
    multipleWindowGuardForm,
    errorPrepForm,
    errorMultipleGuardForm,
    singleWindowGuardForm
  } = React.useContext<any>(AssociateContext);

  const [isNYRequired, setIsNYRequired] = React.useState(false);
  const [isMontgomeryCountyCommunities, setIsMontgomeryCountyCommunities] = React.useState(false);

  React.useEffect(() => {
    addendumPayload?.length && identifyCommunities();
  }, [addendumPayload]);

  const identifyCommunities = () => {
    setIsNYRequired(includesCommunity(addendumPayload[0]?.name, 'Yonkers/Harrison'));
    setIsMontgomeryCountyCommunities(includesCommunity(addendumPayload[0]?.name, 'Montgomery'));
  };

  const handleErrorsForm = () => {
    let hasError = false;

    const formState = {
      singleWindowGuardForm,
      prepFormValue,
      errorPrepForm,
      errorMultipleGuardForm
    };

    if (!prepFormValue) {
      updateFormStatus({ ...formState, errorPrepForm: true });
      if (isNYRequired) {
        updateFormStatus({
          ...formState,
          errorPrepForm: true,
          errorMultipleGuardForm: true
        });
      }
      hasError = true;
    }

    if (prepFormValue) {
      updateFormStatus({ ...formState, errorPrepForm: false });
      if (isNYRequired) {
        updateFormStatus({
          ...formState,
          errorPrepForm: false,
          errorMultipleGuardForm: true
        });
      }
      hasError = true;
    }

    if (singleWindowGuardForm.length) {
      updateFormStatus({
        ...formState,
        errorPrepForm: true,
        errorMultipleGuardForm: false
      });
    }

    hasError = !(isNYRequired ? singleWindowGuardForm.length && prepFormValue : prepFormValue);

    return hasError;
  };

  const handleConfirmSelection = () => {
    if (handleErrorsForm()) {
      return;
    }

    if (prepFormValue) {
      setOpenDialog(false);
      return updateFormStatus({
        prepFormValue,
        multipleWindowGuardForm,
        singleWindowGuardForm,
        errorPrepForm: false,
        errorMultipleGuardForm: false,
        isValidForm: true
      });
    }
  };

  const handleClose = () => {
    updateFormStatus({
      prepFormValue: null,
      errorPrepForm: false,
      multipleWindowGuardForm,
      errorMultipleGuardForm: false,
      singleWindowGuardForm: [],
      isValidForm: false
    });
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '810px'
            }
          }
        }}>
        <DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '10px',
              borderBottom: 'solid 1px #DDD'
            }}>
            <div
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center'
              }}>
              <ErrorIcon sx={{ color: 'rgb(250, 140, 22)', mr: 1 }} />

              {title}
            </div>
            <div style={{ paddingRight: '3%' }}>
              <CloseBtn handleClose={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ color: '#31343A', fontSize: '16px' }}>
            <div>{info}</div>
            {isMontgomeryCountyCommunities && (
              <div style={{ paddingTop: '2%' }}>{montgomeryInfo}</div>
            )}
          </div>
          <div
            style={{
              color: '#31343A',
              fontSize: '18px',
              fontWeight: 'bold',
              paddingTop: '2rem'
            }}>
            {guideline}
          </div>
          {question && (
            <div style={{ fontWeight: 'bold', fontSize: '16px', paddingTop: '20px' }}>
              {question}
            </div>
          )}
          {prepForm && <div style={{ paddingTop: '4px' }}>{prepForm}</div>}
          <WindowGuardSelectionSection form={form} guidelineSelection={guidelineSelection} />
          <div style={{ fontSize: '12px', paddingTop: '5px' }}>{note}</div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              paddingBottom: '20px'
            }}>
            <Button
              variant="contained"
              sx={{ textTransform: 'capitalize', fontSize: '16px' }}
              onClick={handleConfirmSelection}>
              Confirm Selection
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WindowGuardForm;
