import { Skeleton } from '@mui/material';
import React, { Fragment } from 'react';
import { FormsViewstyles } from './styles';

const SkeletetonForm = () => {
  return (
    <Fragment>
      <Skeleton variant="rectangular" width={'100%'} height={'350px'} />
      <div style={{ ...FormsViewstyles.Content }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '30%', display: 'flex', flexFlow: 'column' }}>
            <Skeleton variant="rectangular" width={'85%'} height={'212px'} />
            <div style={{ paddingTop: '5%' }}>
              <Skeleton variant="rectangular" width={'85%'} height={'630px'} />
            </div>
          </div>
          <div style={{ width: '70%' }}>
            <Skeleton variant="rectangular" width={'100%'} height={'212px'} />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '8%'
              }}>
              <Skeleton variant="rectangular" width={'40%'} height={'212px'} />
              <div style={{ width: '4%' }}></div>
              <Skeleton variant="rectangular" width={'40%'} height={'212px'} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SkeletetonForm;
