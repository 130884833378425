/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { capitalizeFirstLetter } from '../../../../../../modules/details/leaseForms/domain/LeaseFormsRepository';

interface Option {
  value: string;
  option: string;
}
interface dropDownProps {
  label: string;
  options: Option[];
  isBedBugForm: boolean;
  selectedOption: any;
  onChange: (e: any) => any;
  validateFullFormInput: boolean;
}

const DropdownUtil: FC<dropDownProps> = ({
  label,
  isBedBugForm,
  options,
  selectedOption,
  onChange,
  validateFullFormInput
}) => {
  const [error, setError] = useState<boolean>(true);

  const validateForm = (e: SelectChangeEvent<{ value: string }>) => {
    if (!validateFullFormInput) {
      setError(false);
      return;
    }
    if (!e) return;
    const selectedValue = typeof e !== 'string' ? e.target.value : e;
    if (selectedValue) {
      setError(false);
    }
  };

  useEffect(() => {
    validateForm(selectedOption);
  }, []);

  return (
    <form>
      <FormControl sx={{ m: 0 }} error={error} variant="standard">
        <div style={{ paddingRight: '2%', paddingBottom: '0%' }}>
          <div style={{ padding: '1% 0%', textTransform: isBedBugForm ? 'none' : 'capitalize' }}>
            {isBedBugForm ? capitalizeFirstLetter(label) : label}
          </div>
          <Select
            size="small"
            variant="outlined"
            value={selectedOption}
            required={true}
            onChange={(e: SelectChangeEvent<{ value: string }>) => {
              onChange(e.target.value);
              validateForm(e);
            }}
            sx={{ width: '285px', height: '40px' }}>
            {options?.map((option: any, index: number) => (
              <MenuItem key={index} value={option.value}>
                {option.option}
              </MenuItem>
            ))}
          </Select>
        </div>
        {error && (
          <FormHelperText sx={{ color: '#F5222D' }}>Please select an option</FormHelperText>
        )}
      </FormControl>
    </form>
  );
};

export default DropdownUtil;
