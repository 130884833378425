const containerStyles = {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, minmax(100px, 1fr))',
  gridGap: '25px',
  padding: '25px',
  background: 'white',
  borderRadius: '5px',
  cursor: 'pointer',
  boxShadow: '0px 10px 10px -5px rgba(0, 0, 0, 0.05)',
  willChange: 'width, height'
};
const disabledButton = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '36px',
  backgroundColor: '#31343A66',
  border: 'none',
  boxShadow: 'none',
  cursor: 'none'
};
export const typeOfGenerateLeaseStyles = {
  containerStyles,
  disabledButton
};
