import isEmpty from 'lodash/isEmpty';

const filterCharges = (filterType, arr, isNYC421a = false, isRenewalPlan = false) => {
  if (isEmpty(arr)) {
    return [];
  }

  let newArr = [...arr];

  if (filterType === 'no-optional') {
    if (isNYC421a && isRenewalPlan) {
      newArr.push({
        chargeDescription: '421-a Fee 4.4',
        optional: null,
        chargeCode: '42A',
        onetimeFee: null,
        chargeAmount: null
      });
    }
    return newArr
      .filter(({ chargeAmount, onetimeFee }) => chargeAmount !== 0 && !onetimeFee)
      .filter((charge) => ['N', null].includes(charge.optional) && charge.chargeCode !== 'RNT');
  }

  if (filterType === 'one-time-fee') {
    return newArr
      .filter(({ onetimeFee }) => onetimeFee !== 0)
      .filter(({ onetimeFee }) => !isEmpty(onetimeFee) || typeof onetimeFee === 'number');
  }

  return newArr.filter(
    (charge) => !['N', null].includes(charge.optional) && charge.chargeCode !== 'RNT'
  );
};

export default filterCharges;
