const RowText = {
  paddingLeft: '2%',
  fontSize: '16px',
  color: '#31343A'
};

const UnitColumn = {
  paddingLeft: '2%',
  fontSize: '14px',
  color: '#31343A',
  fontWeight: 'bold'
};

const ArrowIconsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '25px',
  cursor: 'pointer',
  paddingLeft: '15px'
};

const ArrowIconsAlignment = {
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'right'
};

const PositionArrowIcon = {
  zIndex: '10',
  marginTop: '-13px'
};

const BottomPositionPagination = {
  position: 'fixed',
  bottom: '0',
  width: '100%'
};

const PaginationContainer = {
  height: '50px',
  backgroundColor: '#FFFFF',
  boxShadow: ' 0px 0px 10px #41454D4D',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
};

const ChipStatusContainer = {
  borderRadius: '4px',
  height: '19px',
  font: 'normal normal 300 12px/18px ProximaNova',
  border: '1px',
  borderStyle: 'solid',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'left'
};

const ExpirationDate = {
  paddingLeft: '5%',
  color: '#F5222D',
  fontWeight: 'bold',
  fontSize: '15px',
  verticalAlign: 'top'
};

const LeaseDate = {
  paddingLeft: '5%',
  color: '#31343A',
  fontSize: '16px',
  verticalAlign: 'top'
};

const RequestHours = {
  paddingLeft: '2%',
  color: '#F5222D',
  fontWeight: 'bold',
  fontSize: '16px'
};

const RegHour = {
  color: '#31343A',
  fontSize: '16px'
};

export const styles = {
  RowText,
  RegHour,
  UnitColumn,
  ArrowIconsContainer,
  ArrowIconsAlignment,
  PositionArrowIcon,
  BottomPositionPagination,
  PaginationContainer,
  ChipStatusContainer,
  ExpirationDate,
  LeaseDate,
  RequestHours
};
