import { DocumentStatus, Result } from './Resident';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const addLabelStatus = (status: string) => {
  const LABEL_STATUS: { [key: string]: string } = {
    'renewal-ready': 'Renewal Ready',
    'renewal-not-ready': 'Renewal Not Ready',
    'ntv-completed': 'NTV Completed',
    'renewal-completed': 'Renewal Completed',
    'lease-initiated': 'Lease Initiated',
    'lease-generated': 'Lease Generated',
    'forced-mtm': 'Forced MTM'
  };

  const validateStatus = (status: string) => Object.keys(LABEL_STATUS).includes(status);
  const DEFAULT_STATUS = status && validateStatus(status) ? LABEL_STATUS[status] : null;

  const getLabel = LABEL_STATUS[status] || DEFAULT_STATUS;
  return getLabel;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addLabel = (arr: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr?.map((item: any) => {
    return {
      ...item,
      label: addLabelStatus(item.status)
    };
  });

export interface WidthByStatus {
  'Action Needed': { width: string; paddingLeft: string };
  'Request Hours Open': { width: string; paddingLeft: string };
  'Community Code': { width: string; paddingLeft: string };
  Unit: { width: string; paddingLeft: string };
  'Primary Resident': { width: string; paddingLeft: string };
  'Expiring Lease End Date': { width: string; paddingLeft: string };
  NTV: { width: string; paddingLeft: string };
  'Renewal/NTV Status': { width: string; paddingLeft: string };
  'Term Selected': { width: string; paddingLeft: string };
  'Document Status': { width: string; paddingLeft: string };
}

export const getWidth = (header: keyof WidthByStatus) => {
  const WIDTH_BY_STATUS: WidthByStatus = {
    'Action Needed': { width: '12%', paddingLeft: '0' },
    'Request Hours Open': { width: '5%', paddingLeft: '0' },
    'Community Code': { width: '3%', paddingLeft: '0' },
    Unit: { width: '8%', paddingLeft: '0' },
    'Primary Resident': { width: '15%', paddingLeft: '2%' },
    'Expiring Lease End Date': { width: '20%', paddingLeft: '1%' },
    NTV: { width: '11%', paddingLeft: '0%' },
    'Renewal/NTV Status': { width: '18%', paddingLeft: '3%' },
    'Term Selected': { width: '20%', paddingLeft: '20px' },
    'Document Status': { width: '18%', paddingLeft: '0' }
  };

  const DEFAULT_WIDTH = header ? WIDTH_BY_STATUS[header] : { width: '20%' };
  const widthByStatus = WIDTH_BY_STATUS[header] || DEFAULT_WIDTH;
  return widthByStatus;
};

export const headerData = [
  {
    name: 'Action Needed',
    sorterElement: 'requestType'
  },
  {
    name: 'Request Hours Open',
    sorterElement: 'requestHoursOpen'
  },
  {
    name: 'Community Code',
    sorterElement: 'communityCode'
  },
  { name: 'Unit', sorterElement: 'unitId' },
  { name: 'Primary Resident', sorterElement: 'lastName' },
  { name: 'Expiring Lease End Date', sorterElement: 'leaseEndDate' },
  { name: 'NTV', subName: 'Due Date', sorterElement: 'ntvDueDate' },
  { name: 'Renewal/NTV Status', sorterElement: 'status' },
  { name: 'Term Selected', sorterElement: 'term' },
  { name: 'Document Status' }
];

export const newTableHeight = (windowHeight: number, heightTable: number) =>
  `calc(100vh - ${windowHeight - heightTable}px)`;

export const calculateTableHeight = (windowHeight: number) => {
  const heightMap = [
    { maxHeight: 850, value: '50vh' },
    { maxHeight: 1100, value: '60vh' }
  ];

  const matchedHeight = heightMap.find((item) => windowHeight <= item.maxHeight);

  if (matchedHeight) {
    return matchedHeight.value;
  }

  return '68vh';
};

export interface StatusLabelProps {
  item: Result;
  idx: number;
  isRowExpanded: (idx: number) => boolean;
}

export interface HeaderProps {
  name: string;
  subName: string;
  sorterElement: string;
}
export interface ArrowIconsProps {
  header: HeaderProps;
  handleSortRequest: (sorterElement: string) => void;
}

export interface ResidentTableProps {
  tableData: Result[];
  isLoading: boolean;
  isError: boolean;
  handleOrderByAndOrderDirection: (orderBy: string, orderDirection: string) => void;
}

export const displayAddIcon = (row: Result) => {
  const noticeToVacateLabel = ['notice-to-vacate-offer', 'notice-to-vacate-no-offer'];
  const helpRequestTypes = row.helpRequestTypes ?? [];
  const isNoticeToVacateWithMultipleHelpRequest =
    noticeToVacateLabel.includes(row.requestType) &&
    helpRequestTypes.length > 0 &&
    !helpRequestTypes.includes('Process Move Out');

  return helpRequestTypes.length > 1 || isNoticeToVacateWithMultipleHelpRequest;
};

export const addIconDocumentStatus = (documents: DocumentStatus[]) => {
  if (documents?.length <= 1 || !documents) {
    return false;
  }
  return true;
};

export const isLeaseExpired = (leaseEndDate: string) => {
  const today = dayjs.utc().startOf('day');
  const leaseEndDateUtc = dayjs.utc(leaseEndDate).startOf('day');
  return leaseEndDateUtc.isBefore(today);
};

export const completedStatus = (status: string) => {
  const COMPLETED_STATUS = ['ntv-completed', 'renewal-completed'];

  return COMPLETED_STATUS.includes(status);
};
