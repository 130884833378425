import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { createAPILeaseDetailsRepository } from '../../../../../modules/details/leaseDetails/infrastructure/APILeaseDetailsRepository';
import { putVoidLease } from '../../../../../modules/details/leaseDetails/application/postVoidLease';

const repository = createAPILeaseDetailsRepository();

export const useVoidLease = () => {
  const [leaseId, setLeaseId] = useState<string | null>(null);
  const [docuRefId, setDocuRefId] = useState<string | null>(null);
  const voidLeaseQuery = useQuery({
    queryKey: ['voidLease', docuRefId],
    queryFn: () => putVoidLease(repository, leaseId, docuRefId),
    enabled: !!leaseId
  });

  const voidLease = (leaseId: string, docuRefId: string) => {
    setDocuRefId(docuRefId);
    setLeaseId(leaseId);
  };

  return {
    voidLeaseQuery,
    //Methods
    voidLease
  };
};
