import React from 'react';

const Content = {
  margin: '50px 40px 30px 40px',
  font: 'normal normal normal 16px/20px ProximaNova'
};

const DetailsCardsContainer = {
  display: 'flex',
  // flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0',
  paddingBottom: '44px',
  maxWidth: '1146px'
};

const capitalize: React.CSSProperties = {
  textTransform: 'capitalize',
  font: 'ProximaNova',
  color: '#31343A'
};

const Margin: React.CSSProperties = { marginTop: '14px', gap: '14px' };

const GuardRails: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  maxWidth: '1146px',
  marginBottom: '15px'
};

export const styles = {
  GuardRails,
  Content,
  DetailsCardsContainer,
  Margin,
  capitalize
};
