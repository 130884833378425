/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';

const DialogType = {
  Alert: 1,
  Success: 2,
  Confirm: 3
};

const handleError = (header: string, message: any) => {
  return {
    type: DialogType.Alert,
    isOpen: true,
    headerLabel: header,
    body: message,
    btnLabel1: 'Got It!',
    handleCloseConfirmDialog: () => {}
  };
};

const handleSuccess = (header?: string, message?: any) => {
  return {
    type: DialogType.Success,
    customWidth: '455px',
    isOpen: true,
    headerLabel: header,
    body: message,
    btnLabel1: 'OK',
    btnLabel2Callback: () => {},
    handleCloseConfirmDialog: () => {}
  };
};

export const errorHandlers: Record<string, (header: string, message: any) => void> = {
  ERR_NETWORK: (header, message) => {
    return handleError(header, message);
  },
  400: (header, message) => {
    return handleError(header, message);
  },
  500: (header, message) => {
    return handleError(header, message);
  },
  default: (header, message) => {
    return handleError(header, message);
  },
  100: (header, message) => {
    return handleError(header, message);
  }
};

export const successHandlers: Record<string, (header: string, message: any) => void> = {
  204: (header, message) => {
    return handleSuccess(header, message);
  },
  202: (header, message) => {
    return handleSuccess(header, message);
  }
};

export const handleResponse = (responseStatus: number, responseCode: number) => {
  const isSuccess = responseStatus === 204;
  const isError = responseCode >= 400;

  return isSuccess ? successHandlers[responseStatus] : isError && errorHandlers[responseCode];
};

type ConfirmationConfig = {
  query?: any;
  successCode?: number;
  successMessage?: string;
  successContent?: React.ReactNode;
  errorMessage: string;
  errorContent: React.ReactNode;
  errorMessagePhoneNumber?: string | any;
  errorPhoneNumberContent?: React.ReactNode;
  successAction?: () => void;
  refetchCallback?: () => void;
  handleChildSuccess?: any;
  handleChildError?: any;
};

export const displayConfirmation = (config: ConfirmationConfig) => {
  const responseStatus = config.query?.data;
  const responseCode = config.query?.data?.response?.status;
  const isSuccess = config.query?.data === config.successCode;
  const isError = responseCode >= 400;

  if (!responseStatus) {
    return;
  }

  if (config.query?.data?.code === 'ERR_NETWORK') {
    const errorDetails = handleError(config.errorMessage, config.errorContent);
    return config.handleChildError(errorDetails);
  }

  if (responseCode == 400) {
    const data = config.query?.data?.response?.data;
    if (data.errorCode === 100) {
      const errorDetails = handleError(
        config.errorMessagePhoneNumber,
        config.errorPhoneNumberContent
      );
      return config.handleChildError(errorDetails);
    }
  }

  const handler = isSuccess
    ? successHandlers[responseStatus]
    : isError && errorHandlers[responseCode];

  if (!handler) {
    return;
  }

  if (isSuccess) {
    const successDetails = handler(config.successMessage ?? '', config.successContent);
    config.handleChildSuccess(successDetails, config.successAction, config.refetchCallback);
  } else {
    const errorDetails = handler(config.errorMessage, config.errorContent);
    config.handleChildError(errorDetails);
  }
};
