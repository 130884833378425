import React from 'react';
import { leaseDetailsStyles } from './styles';

export const TextInfoToolTip = () => {
  return (
    <>
      <p style={leaseDetailsStyles.textInfoTooltip}>
        Forced Complete cannot be done in the{' '}
        <span style={{ fontWeight: 600 }}> Lease Initiated </span> or{' '}
        <span style={{ fontWeight: 600 }}>Lease Generated</span> state. First void the lease in
        DocuSign before marking it complete.
      </p>
    </>
  );
};
