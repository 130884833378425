/* eslint-disable @typescript-eslint/no-explicit-any */
import associateAPI from '../../../../api/associateAPI';
import { Offers, PlanTerm } from '../domain/Offers';
import { OffersRepository } from '../domain/OffersRepository';

const METHOD_ASSOCIATE_DETAILS = 'renewals-associate/lease/';

export function createAPIOffersRepository(): OffersRepository {
  return {
    getAll,
    postTerm
  };
}

const getAll = async (leaseId: string) => {
  try {
    const { data } = await associateAPI.get(METHOD_ASSOCIATE_DETAILS + leaseId + '/offers');
    return data as Offers;
  } catch (error) {
    return error as Offers;
  }
};

const postTerm = async (leaseId: string, term: string | null) => {
  try {
    const { data } = await associateAPI.post(
      METHOD_ASSOCIATE_DETAILS + leaseId + '/plan?term=' + term
    );
    return data as PlanTerm;
  } catch (error) {
    return error as PlanTerm;
  }
};
