/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress } from '@mui/material';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import FormModule from './FormModule';
import Divider from '@mui/material/Divider';
import { AssociateContext } from '../../../../../context/AssociateContext';

import { FormObject } from '../../../../../modules/details/leaseForms/domain/LeaseModification';

import Dialog, { DialogType } from '../../../../../shared/dialog/Dialog';
import { DialogDetailsType } from '../../../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { displayConfirmation } from '../../../../../modules/details/leaseDetails/domain/LeaseDetailsDialogs';

import { typeOfGenerateLeaseStyles } from '../styles';
import { useGenerateForms } from '../hooks/useGenerateForms';
import {
  FormItem,
  getTerm,
  isValid,
  restoreState
} from '../../../../../modules/details/leaseForms/domain/LeaseAdditionalInfo';

interface additionalInformationProps {
  handleStep: (value: number) => void;
}

const AdditionalInformation: FC<additionalInformationProps> = ({ handleStep }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {
    metadataForms,
    currentStep,
    lastStep,
    formLeaseModificationPayload,
    formData,
    leasePayload,
    currentSelection
  } = useContext<any>(AssociateContext);
  // router
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();
  const leaseFlow: string | null = searchParams.get('leaseFlow');
  const { leaseId = '0' } = params;

  // queries
  const { generateFormsQuery, generateForms } = useGenerateForms();

  const [formsRendering, setFormsRendering] = useState<any>([]);
  const [isValidForm, setIsValidForm] = useState(false);

  const [emptyForms, setEmptyForms] = useState<boolean>(false);

  const [dialogDetails, setDilaogDetails] = useState<DialogDetailsType>({
    type: DialogType.NoIcon,
    headerLabel: '',
    body: '',
    btnLabel1: 'Cancel',
    btnLabel2: 'Confirm',
    isOpen: false,
    btnLabel2Callback: () => Promise.resolve()
  });

  const handleCloseConfirmDialog = () => {
    setDilaogDetails({
      ...dialogDetails,
      isOpen: false
    });
  };

  const handleChildError = (errorDetails: any) => {
    setDilaogDetails({
      ...dialogDetails,
      ...errorDetails,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleChildSuccess: any = (
    successDetails: any,
    successAction: () => void,
    btnLabel2Callback: any
  ) => {
    successAction();
    setDilaogDetails({
      ...dialogDetails,
      ...successDetails,
      btnLabel2Callback: btnLabel2Callback,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const filterByName = (prevArr: FormObject[], arr: FormObject[]) => {
    const formNamesArr = prevArr.map((el) => el.formName);

    const filterElements = arr.filter((el) => !formNamesArr.includes(el.formName));

    return filterElements;
  };

  const getFormsToRender = () => {
    let copyForms = [...leasePayload.forms];

    if (formsRendering.length > 0) {
      const newArr = filterByName(formsRendering, copyForms);
      copyForms = [...formsRendering, ...newArr];
    }

    const arrModifiedValue = createForms(copyForms);

    const filterByFormName = (objectArray: any, formNames: string[]) => {
      return objectArray.filter((obj: any) => formNames.includes(obj.formName.replace(/\s/g, '')));
    };

    const filteredObjects = filterByFormName(
      arrModifiedValue,
      Object.values(metadataForms).flatMap((str: any) => str.split(','))
    );

    setEmptyForms(isEmptyForms(filteredObjects));

    const payload = formatPayload(filteredObjects);

    formLeaseModificationPayload({
      form: payload
    });

    setFormsRendering(filteredObjects);
  };

  const isEmptyForms = (forms: FormObject[]) => {
    const res = forms?.every(
      (form: FormObject) =>
        Array.isArray(form.formSchema.fields) && form.formSchema.fields.length === 0
    );
    return res;
  };

  const handleResolveDialog = () => {
    const data = {
      refId: leasePayload.refId,
      formData: formData ?? [],
      typeForm: currentSelection,
      leaseTerm: getTerm(leasePayload.lease.leaseTerm),
      action: leaseFlow
    };

    setDilaogDetails({
      ...dialogDetails,
      type: DialogType.NoIcon,
      customWidth: '455px',
      isLeaseMod: true,
      headerLabel: 'Are You Sure You Want To Generate These Forms?',
      body: (
        <div>
          The forms you selected will be compiled into a DocuSign package and sent to responsible
          residents and guarantors.{' '}
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Yes, Generate and send',
      btnLabel2Callback: async () => {
        await generateForms(leaseId, data);
      },
      handleCloseConfirmDialog: () =>
        setDilaogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
  };

  const displayConfirmationGenerateForms = async () => {
    displayConfirmation({
      query: generateFormsQuery,
      successCode: 204,
      successMessage: 'The forms were submitted successfully',
      successContent: <div>This form has been successfully created.</div>,
      errorMessage: 'Failed to Submit the Form!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {
        restoreState(currentStep, formLeaseModificationPayload, navigate);
      },
      handleChildSuccess,
      handleChildError
    });
    generateForms(null, null);
  };

  useEffect(() => {
    if (currentSelection !== 'Individual Lease') return;
    metadataForms && getFormsToRender();
    currentStep({ displaySelection: true, currentSelection, lastStep });
  }, [metadataForms]);

  useEffect(() => {
    displayConfirmationGenerateForms();
  }, [generateFormsQuery.data]);

  const createFormData = (item: any) => {
    return item.formSchema.fields.length > 0
      ? item.formSchema.fields.map((field: any) => ({
          fieldName: field.fieldName,
          answerValue: field.value
        }))
      : [];
  };

  const formatPayload = (form: any) => {
    let res: any = [];
    res = form.map((item: any) => {
      const formDataItem = {
        formId: item.formId,
        formGroup: item.formGroupName,
        formData: createFormData(item)
      };

      return formDataItem;
    });
    return res;
  };

  const handleChange = (newValue: any, fieldName: string, formName: string) => {
    const newValues = [...formsRendering];

    const indexElement = newValues.findIndex((element) => element.formName === formName);
    if (indexElement !== -1) {
      newValues[indexElement].formSchema.fields.forEach((field: any) => {
        if (field['fieldName'] === fieldName) {
          field.value = newValue;
        }
      });

      const payload = formatPayload(newValues);

      formLeaseModificationPayload({
        form: payload
      });

      setFormsRendering(newValues);
    }
  };

  const createForms = (arr: any) => {
    arr.forEach((obj: any, idx: number) => {
      if (obj['formSchema']['fields']?.length) {
        obj['formSchema']['fields']?.forEach((field: any) => {
          field.value = field.value || '';
        });
      }
      obj.selected = false;
    });

    return arr;
  };

  useEffect(() => {
    if (!formsRendering?.length) {
      setIsValidForm(false);
      return;
    }
    if (formData) {
      const isValidForm = isValid(formData);
      setIsValidForm(isValidForm);
    }
  }, [formData]);

  return (
    <div>
      <div style={{ padding: '2% 3%' }}>
        <div style={{ fontSize: '24px', color: '#31343A', textTransform: 'capitalize' }}>
          Complete additional information
        </div>
        <div style={{ fontSize: '16px', color: '#72767F', paddingTop: '1%' }}>
          All fields are required before you can generate the document package. Reference the Lease
          Summary to ensure that all changes have been made in MRI prior to generating these forms.
        </div>

        {!emptyForms ? (
          formsRendering?.map((form: any, idx: number) => (
            <Fragment key={idx}>
              {form.formSchema?.fields?.length || form.formName.includes('Window') ? (
                <div key={idx}>
                  <FormModule
                    key={idx}
                    title={form.formName}
                    form={form}
                    handleChange={handleChange}
                  />
                  <Divider variant="fullWidth" />
                </div>
              ) : (
                <></>
              )}
            </Fragment>
          ))
        ) : (
          <div>
            {isValidForm && (
              <p style={{ fontWeight: 400, fontSize: '18px' }}>
                No additional information required. Proceed to document generation.
              </p>
            )}
          </div>
        )}
        <div
          style={{
            padding: '2%',
            paddingTop: '15%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <div>
            <Button onClick={() => handleStep(-1)}>Back</Button>
          </div>
          <div>
            {generateFormsQuery?.isFetching ? (
              <Button
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                style={typeOfGenerateLeaseStyles.disabledButton}
                disabled={true}>
                <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
              </Button>
            ) : (
              <Button variant="contained" disabled={!isValidForm} onClick={handleResolveDialog}>
                Generate And Send
              </Button>
            )}
          </div>
        </div>
      </div>
      <Dialog {...dialogDetails} />
    </div>
  );
};

export default AdditionalInformation;
