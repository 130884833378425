/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import associateFormsAPI from '../../../../api/associateFormsAPI';

const METHOD_ASSOCIATE_FORMS = '/leases/details';
const METHOD_ASSOCIATE_GENERATE_FORMS = '/leases/generate';

export function createAPIFormsRepository(): any {
  return {
    getForms,
    postGenerateForms
  };
}

const getForms = async (leaseId: string, leaseFlow: string, leaseTerm: string) => {
  try {
    const { data } = await associateFormsAPI.get(
      METHOD_ASSOCIATE_FORMS + '/' + leaseId + '?action=' + `${leaseFlow}&renewalTerm=${leaseTerm}`
    );

    return data as any;
  } catch (error) {
    console.error(error);
    return error as any;
  }
};

const postGenerateForms = async (leaseId: string, data: any) => {
  try {
    const { status } = await associateFormsAPI.post(METHOD_ASSOCIATE_GENERATE_FORMS, data);
    return status as number;
  } catch (error) {
    return error as any;
  }
};
