/* eslint-disable @typescript-eslint/ban-ts-comment */
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/system';
import React, { FC } from 'react';

import { residentStyles } from './styles';

import ResidentCustomPaging from './ResidentCustomPaging';
import { ResidentPaginationProps } from '../../../modules/dashboard/residents/domain/Resident';

const ResidentPagination: FC<ResidentPaginationProps> = ({
  tableData,
  handleChangePage,
  page,
  limit,
  itemsByPage
}) => {
  const count =
    tableData !== undefined && limit !== undefined ? Math.ceil(tableData?.total / limit) : 10;

  const MyPagination = styled(Pagination)({
    '& .MuiButtonBase-root-MuiPaginationItem-root': {
      borderColor: '#4190F7',
      backgroundColor: '#FFFFFF',
      fontWeight: 'bold'
    }
  });

  return (
    <div style={residentStyles.BottomPositionPagination}>
      {tableData !== undefined && tableData?.total > 0 && (
        <div style={residentStyles.PaginationContainer}>
          <MyPagination
            count={count}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
          />
          <div>
            <ResidentCustomPaging tableData={tableData} limit={limit} itemsByPage={itemsByPage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResidentPagination;
