/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Collapse } from '@mui/material';
import React, { FC } from 'react';
// @ts-ignore
import ChipStatus from '../../../../shared/chipStatus/ChipStatus';
import { Result } from '../../../../modules/dashboard/residents/domain/Resident';
import dayjs from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativetime);

interface DocumentLabelsProps {
  row: Result;
  isRowExpanded: (id: number) => boolean;
  idx: number;
}

const DocumentLabels: FC<DocumentLabelsProps> = ({ row, isRowExpanded, idx }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'start',
        alignItems: 'start',
        flexDirection: 'column'
      }}>
      <div>
        {row?.documentStatus?.length > 0 &&
          ChipStatus(
            row?.documentStatus?.[0].documentStatus,
            dayjs.utc(row?.documentStatus?.[0].timestamp).fromNow(true)
          )}
      </div>
      <Collapse in={isRowExpanded(idx)}>
        <div style={{ marginTop: '2%' }}>
          {row?.documentStatus?.length > 1 ? (
            <div>
              {row?.documentStatus?.slice(1).map((val) => (
                <div style={{ marginTop: '2%' }} key={val.helpRequestId}>
                  {ChipStatus(val.documentStatus, dayjs.utc(val.timestamp).fromNow(true))}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default DocumentLabels;
