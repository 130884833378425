/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  HeaderKey,
  getStyleForKey,
  matchHeaderTable,
  isHeaderKey,
  HelpRequestContentProps,
  isAddressComplete
} from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import { helpRequestStyles } from './styles';

const HelpRequestContent = ({ tableBody }: HelpRequestContentProps) => {
  const formatForwardingAddress = (action: any) => {
    if (isAddressComplete(action)) {
      if (action['addressLine2']) {
        return (
          <>
            {action['street'] + ', '}
            {action['addressLine2'] + ', '}
            {`${action['city']}, ${action['state']} ${action['zip']} - ${action['country']}`}
          </>
        );
      } else {
        return (
          <>
            {action['street'] + ', '}
            {`${action['city']}, ${action['state']} ${action['zip']} - ${action['country']}`}
          </>
        );
      }
    } else {
      return 'Unknown';
    }
  };
  return (
    <>
      {Object.keys(tableBody).map((key, idx) => {
        if (!isHeaderKey(key)) return null;
        const styleByKey = getStyleForKey(key as HeaderKey);
        return (
          <React.Fragment key={idx}>
            <div style={styleByKey}>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li style={helpRequestStyles.contentHelpRequest}>
                  {tableBody[key] && matchHeaderTable(key as HeaderKey)}
                </li>
                <li style={{ fontSize: '16px', color: '#31343A' }}>
                  {key === 'forwardingAddress'
                    ? formatForwardingAddress(tableBody[key])
                    : tableBody[key]}
                </li>
              </ul>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default HelpRequestContent;
