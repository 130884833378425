import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { createAPIOffersRepository } from '../../../../../modules/details/offers/infrastructure/APIOffers';
import { getAllOffers } from '../../../../../modules/details/offers/application/getAllOffers';
import { postOffer } from '../../../../../modules/details/offers/application/postOffer';

const respository = createAPIOffersRepository();

export const useOffers = (leaseId: string) => {
  const [leaseIdSelected, setLeaseIdSelected] = useState<string | null>(null);
  const [term, setTerm] = useState<string | null>(null);

  const offersQuery = useQuery({
    queryKey: ['offers', leaseId],
    queryFn: () => getAllOffers(respository, leaseId),
    staleTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false
  });

  const offerSelectedQuery = useQuery({
    queryKey: ['offer', leaseId, term],
    queryFn: () => postOffer(respository, leaseId, term),
    refetchOnWindowFocus: false,
    enabled: !!leaseIdSelected
  });

  const offerSelected = (leaseId: string, term: string) => {
    setLeaseIdSelected(leaseId);
    setTerm(term);
  };

  return {
    offersQuery,
    offerSelectedQuery,
    // Methods
    offerSelected
  };
};
