/* eslint-disable */
import React from 'react';
// @ts-ignore
import star from '../../../../assets/imgs/detailsIcons/yellowstar_icon.png';
import { leaseDetailsCardStyles } from './styles';
// @ts-ignore
import { formatCurrency } from '../../../../utils/formatCurrency';

const ConcessionCard = ({ data }: any) => {
  return (
    <div style={{ width: '85%', border: '1px solid #D1AFB1', borderRadius: '4px', padding: '2%' }}>
      <div style={{ display: 'flex', paddingBottom: '2%' }}>
        <div
          style={{
            fontFamily: 'ProximaNova',
            fontWeight: 600,
            fontSize: '16px',
            display: 'inline-block'
          }}>
          Renewal Incentive
        </div>
        <div style={{ ...leaseDetailsCardStyles.CenterRows, paddingLeft: '3%' }}>
          <span
            style={{
              ...leaseDetailsCardStyles.CenterRows,
              background: '#FFFBB8',
              width: 'fit-content'
            }}>
            <div style={{ padding: '0 0 0 3px' }}>
              <img src={star} alt="star-icon" width={10} />
            </div>
            <div style={{ padding: '0 5px', fontSize: '12px', fontWeight: 600 }}>Special</div>
          </span>
        </div>
        <div
          style={{
            fontFamily: 'ProximaNova',
            fontWeight: 600,
            fontSize: '16px',
            paddingLeft: '31%'
          }}>
          ${formatCurrency(Number(data?.chargeAmount))}
        </div>
      </div>
      <div
        style={{
          fontFamily: 'ProximaNova',
          fontWeight: 200,
          fontSize: '14px',
          textAlign: 'left',
          width: '85%'
        }}>
        Resident will receive a one-time concession in the above amount for the selected lease term.
      </div>
    </div>
  );
};

export default ConcessionCard;
