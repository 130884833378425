/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useState } from 'react';
import { useSpring, animated, config } from '@react-spring/web';
// @ts-ignore
import IndividualLeaseForm from './individualLease/IndividualLeaseForm';
import FullLeasePackage from './fullLease/FullLeasePackage';
import { AssociateContext } from '../../../../context/AssociateContext';
import { isInvalidCommunity } from '../../../../modules/details/leaseForms/domain/SelectLease';

//router
import { useParams } from 'react-router-dom';

const TypeOfGenerateLease = () => {
  const { currentStep, formLeaseModificationPayload, displaySelection, currentSelection } =
    useContext<any>(AssociateContext);
  const [expdandIndividual, setExpdandIndividual] = useState(true);
  const [expandFull, setExpandFull] = useState(true);
  const [hover, setHover] = useState(false);
  const [hoverFull, setHoverFull] = useState(false);
  const [removePadding, setRemovePadding] = useState(false);
  const hoverAnimation = useSpring({
    border: hover ? '2px solid #1D2F5C' : '2px solid transparent',
    config: config.stiff
  });
  const [step, setStep] = useState(0);
  const { leaseId = '0' } = useParams();

  const expandAnimation = useSpring({
    to: {
      width: expdandIndividual ? '35%' : '100%',
      height: expdandIndividual ? '100%' : '100%'
    },
    from: {
      width: '35%',
      height: '100%'
    },
    config: config.stiff
  });

  const hoverFullAnimation = useSpring({
    border: hoverFull ? '2px solid #1D2F5C' : '2px solid transparent',
    config: config.stiff
  });

  const expandFullAnimation = useSpring({
    to: {
      width: expandFull ? '35%' : '100%',
      height: expandFull ? '100%' : '100%'
    },
    from: {
      width: '35%',
      height: '100%'
    },
    config: config.stiff
  });

  const handleHover = (individual: boolean) => {
    if (!expdandIndividual) return;
    // if (!expandFull) return;
    individual ? setHover((prev) => !prev) : setHoverFull((prev) => !prev);
  };

  const handleClick = (individual: boolean) => {
    if (!expdandIndividual) return;
    if (!expandFull) return;
    setHover(false);
    setRemovePadding(true);
    handleStep(1);
    individual
      ? currentStep({
          displaySelection: false,
          currentSelection: 'Individual Lease',
          lastStep: step
        })
      : currentStep({ displaySelection: false, currentSelection: 'Full Lease', lastStep: step });

    individual ? setExpdandIndividual(false) : setExpandFull(false);
  };

  const handleClose = () => {
    setExpdandIndividual(true);
    setExpandFull(true);
    setRemovePadding(false);
    currentStep({ displaySelection: false, currentSelection: '', lastStep: 0 });
    formLeaseModificationPayload({
      form: []
    });
    setStep(0);
  };

  const handleStep = (value: number) => {
    currentStep({
      displaySelection,
      currentSelection,
      lastStep: step
    });
    if (step > 2) return;
    setStep(step + value);
  };

  const displayFullLease = () => {
    if (
      window.location.origin.includes('qa') ||
      process.env.REACT_APP_ASSOCIATE_URL?.includes('qa')
    ) {
      return false;
    }
    return isInvalidCommunity(leaseId);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: removePadding ? '0%' : '6%'
        }}>
        <div style={{ width: '4%' }}></div>
        <animated.div
          onClick={() => handleClick(false)}
          onMouseEnter={() => handleHover(false)}
          onMouseLeave={() => handleHover(false)}
          style={{
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 0px',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderColor: '#F6F6F7',
            borderWidth: '2px',
            ...expandFullAnimation,
            ...hoverFullAnimation,
            cursor: 'pointer',
            display: displayFullLease() || !expdandIndividual ? 'none' : 'block'
          }}>
          <FullLeasePackage
            expandFull={expandFull}
            step={step}
            handleClose={handleClose}
            handleStep={handleStep}
          />
        </animated.div>
        <div style={{ width: '4%' }}></div>
        <animated.div
          onClick={() => handleClick(true)}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(true)}
          style={{
            backgroundColor: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 0px',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderColor: '#F6F6F7',
            borderWidth: '2px',
            ...expandAnimation,
            ...hoverAnimation,
            cursor: 'pointer',
            display: expandFull ? 'block' : 'none'
          }}>
          <IndividualLeaseForm
            expdandIndividual={expdandIndividual}
            step={step}
            handleClose={handleClose}
            handleStep={handleStep}
          />
        </animated.div>
      </div>
    </>
  );
};

export default TypeOfGenerateLease;
