/* eslint-disable */
import React, { Fragment, useState, useEffect, useRef } from 'react';

// Components
import SelectCommunity from '../components/communities/SelectCommunity';
import ResidentTable from '../residents/ResidentTable';
import ResidentSearch from '../residents/ResidentSearch';
import ResidentPagination from '../residents/ResidentPagination';
// @ts-ignore
import { timeToRefreshSession } from '../../../components/signIn/auth';
import LeaseHeader from '../../../shared/header/LeaseHeader';
// @ts-ignore
import FiltersContainer from '../components/filters/FiltersContainer';

// Domain
import { Community } from '../../../modules/dashboard/communities/domain/Community';
import { addLabel } from '../../../modules/dashboard/residents/domain/ResidentTable';

// Material
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
// @ts-ignore
import { styled } from '@mui/material/styles';

// Hooks
import { useTableAssociate } from '../residents/hooks/useTableResidents';

// Routes
import { useNavigate } from 'react-router-dom';

// utils
// @ts-ignore
import { clearLocalStorage } from '../../../utils/clearLocalStorage';
// @ts-ignore
import { updateKeyValueLocalStorage } from '../../../utils/updateKeyValueLocalStorage';
import { dashboardStyles } from './styles';
import { MultiValue } from 'react-select';

const Dashboard = () => {
  const {
    tableQuery,
    page,
    nextPage,
    searchByLease,
    searchByCommunityCodes,
    checkedHelpRequestFilters,
    communityCodes,
    limit,
    itemsByPage,
    orderByAndOrderDirectionFilter,
    clearFilters
  } = useTableAssociate();

  const navigate = useNavigate();

  const [values, setValues] = useState({
    community: ''
  });

  const [userName, setUserName] = useState<string>('');

  const handleSelection = (newValues: MultiValue<Community>) => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }
    const communityCodes = newValues.map((community) => community.value);
    tableQuery.refetch();
    searchByCommunityCodes(communityCodes);
  };

  const handleSearch = () => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }
    const activeFilters = JSON.parse(localStorage.getItem('activeFilters') || '{}');
    if (activeFilters?.search.length > 0) {
      return searchByLease(activeFilters?.search);
    }
    searchByLease(activeFilters?.search);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }
    e.key === 'Enter' && handleSearch();
  };

  const handleChange = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }

    updateKeyValueLocalStorage('search', e.target.value);

    setValues({ ...values, [prop]: e.target.value });
  };

  const handleChangePage = (e: any, p: number) => {
    timeToRefreshSession() ? navigate('/', { replace: true }) : nextPage(p);
  };

  const handleLogout = () => {
    clearLocalStorage(['activeFilters', 'communities']);
    navigate('/');
  };

  const getToken = () => {
    const accessToken = localStorage.getItem('access_token');
    const userName = localStorage.getItem('userName') ?? '';
    accessToken ? setUserName(userName) : handleLogout();
  };

  const childFilterRef = useRef<any>(null);

  const handleClearButton = () => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }
    if (childFilterRef.current) {
      childFilterRef.current.handleClear();
    }
  };

  const fetchDataWithFilters = async (filters: any, filterBy: string) => {
    if (timeToRefreshSession()) {
      return navigate('/', { replace: true });
    }

    const activeFiltersString = localStorage.getItem('activeFilters');
    const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : null;

    let status = activeFilters?.statusFilter;
    let requestTypes = activeFilters?.requestTypesFilters + ',' + activeFilters?.checkedFilters;
    let leaseEndMonths = activeFilters?.leaseEndMonthsFilter;
    let ntvDueDateMonths = activeFilters?.ntvDueDateMonthsFilter;

    if (filterBy == 'statusState') {
      status = filters;
    }
    if (filterBy == 'requestTypesStatus') {
      requestTypes = filters;
    }

    if (filterBy == 'leaseEndMonths') {
      leaseEndMonths = filters;
    }
    if (filterBy == 'ntvDueDateMonths') {
      ntvDueDateMonths = filters;
    }

    timeToRefreshSession()
      ? navigate('/', { replace: true })
      : checkedHelpRequestFilters(requestTypes, status, leaseEndMonths, ntvDueDateMonths);
  };

  const ClearAllButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#31343A'),
    backgroundColor: '#31343A',
    '&:hover': {
      backgroundColor: '#262626'
    }
  }));

  const handleOrderByAndOrderDirection = (orderBy: string, orderDirection: string) => {
    orderByAndOrderDirectionFilter(orderBy, orderDirection);
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <LeaseHeader
      title="AvalonBay Communities"
      appName="Lease Manager Portal"
      handleLogout={handleLogout}
      userName={userName}>
      <Container maxWidth={false} sx={dashboardStyles.ResidentSearchContainer}>
        <ResidentSearch
          handleSearch={handleSearch}
          handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
          handleChange={(e: any) => handleChange(e)}
        />
        <SelectCommunity handleSelection={handleSelection} communityCodes={communityCodes ?? []} />
      </Container>

      <Container maxWidth={false} sx={dashboardStyles.ClearBtnContainer}>
        <ClearAllButton
          variant="contained"
          onClick={handleClearButton}
          sx={dashboardStyles.TextStyle}>
          Clear all
        </ClearAllButton>
        <FiltersContainer
          ref={childFilterRef}
          fetchDataWithFilters={fetchDataWithFilters}
          tableQuery={tableQuery}
          clearFilters={clearFilters}
        />
      </Container>

      <ResidentTable
        tableData={addLabel(tableQuery?.data?.results)}
        isLoading={tableQuery?.isLoading}
        isError={tableQuery?.isError}
        handleOrderByAndOrderDirection={handleOrderByAndOrderDirection}
      />

      <ResidentPagination
        handleChangePage={handleChangePage}
        tableData={tableQuery?.data}
        page={page}
        limit={limit}
        itemsByPage={itemsByPage}
      />
    </LeaseHeader>
  );
};

export default Dashboard;
