/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import CheckboxesList from './CheckboxesList';
import {
  filterCheckboxesRequestType,
  filterCheckboxesStatus
} from '../../../../modules/dashboard/residents/domain/ResidentFilterResource';
import { filterStyles } from './styles';

export const FilterOptionsCheckboxes = ({
  checkedItems,
  handleChange,
  filterForLeaseEndMonth,
  filterForNTVDueDate
}: any) => {
  return (
    <div>
      <div style={filterStyles.HeadTitleFilter}>Action Needed</div>
      <CheckboxesList
        checkedItems={checkedItems?.requestTypesStatus}
        handleChange={(e) => handleChange(e, 'requestTypesStatus')}
        filterCheckboxes={filterCheckboxesRequestType}
      />
      <div style={filterStyles.HeadTitleFilter}>Status</div>
      <div>
        <CheckboxesList
          checkedItems={checkedItems?.statusState}
          handleChange={(e) => handleChange(e, 'statusState')}
          filterCheckboxes={filterCheckboxesStatus}
        />
      </div>
      <div style={filterStyles.HeadTitleFilter}>Lease End Date</div>
      <div>
        <CheckboxesList
          checkedItems={checkedItems?.leaseEndMonths}
          handleChange={(e) => handleChange(e, 'leaseEndMonths')}
          filterCheckboxes={filterForLeaseEndMonth}
        />
      </div>
      <div style={filterStyles.HeadTitleFilter}>NTV Due Date</div>
      <div>
        <CheckboxesList
          checkedItems={checkedItems?.ntvDueDateMonths}
          handleChange={(e) => handleChange(e, 'ntvDueDateMonths')}
          filterCheckboxes={filterForNTVDueDate}
        />
      </div>
    </div>
  );
};
