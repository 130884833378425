/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, FC } from 'react';

//Libraries
import Select from 'react-select';
import { MultiValue } from 'react-select';
//Icons
import {
  Community,
  formatCommunities
} from '../../../../modules/dashboard/communities/domain/Community';
import { useCommunities } from './hooks/useCommunities';
import { communityStyles } from './styles';

interface selectCommunityProps {
  handleSelection: (newValues: MultiValue<Community>) => void;
  communityCodes: string[];
}

const SelectCommunity: FC<selectCommunityProps> = ({ handleSelection, communityCodes }) => {
  const { communitiesQuery } = useCommunities();

  const [communityCodesArr, setCommunityCodesArr] = useState([]);
  const defaulOptions = () => {
    const activeFiltersString = localStorage.getItem('activeFilters');
    const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : null;

    if (activeFilters?.communities?.length) {
      return activeFilters?.communities?.filter((option: any) =>
        activeFilters?.communityCodes?.includes(option.value)
      );
    }
  };

  useEffect(() => {
    const activeFiltersString = localStorage.getItem('activeFilters');
    const activeFilters = activeFiltersString ? JSON.parse(activeFiltersString) : null;
    setCommunityCodesArr(activeFilters?.communityCodes);
  }, []);

  return (
    <>
      <div style={communityStyles.CommunityContainer}>
        <Select
          closeMenuOnSelect={false}
          isMulti
          placeholder="Community"
          onChange={handleSelection}
          defaultValue={defaulOptions()}
          options={
            !communitiesQuery.isLoading &&
            !communitiesQuery.isError &&
            formatCommunities(communitiesQuery?.data)
          }
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (baseStyles: any) => ({
              ...baseStyles,
              maxHeight: 80,
              overflowY: `${communityCodes?.length > 2 || communityCodesArr?.length > 2 ? 'scroll' : 'hidden'}`
            })
          }}
        />
      </div>
    </>
  );
};

export default SelectCommunity;
