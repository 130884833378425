/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Dialog, { DialogType } from '../../../../../shared/dialog/Dialog';
import { DialogDetailsType } from '../../../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';

import { useNavigate } from 'react-router-dom';
import { AssociateContext } from '../../../../../context/AssociateContext';
import { restoreState } from '../../../../../modules/details/leaseForms/domain/LeaseAdditionalInfo';

const CancelProcess = () => {
  const { currentStep, formLeaseModificationPayload } = useContext<any>(AssociateContext);
  const [hovered, setHovered] = useState(false);

  const [dialogDetails, setDilaogDetails] = useState<DialogDetailsType>({
    type: DialogType.NoIcon,
    headerLabel: '',
    body: '',
    btnLabel1: 'Cancel',
    btnLabel2: 'Confirm',
    isOpen: false,
    btnLabel2Callback: () => Promise.resolve()
  });

  const navigate = useNavigate();

  const displayCancelConfirmation = async () => {
    setDilaogDetails({
      ...dialogDetails,
      type: DialogType.NoIcon,
      customWidth: '547px',
      isLeaseMod: true,
      cancelOpt: true,
      headerLabel: 'Are You Sure You Want to Cancel?',
      body: (
        <div>
          This will clear your progress. To generate lease forms, you will have to return to MRI and
          re-initiate this Docusign process.
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Stay on this page',
      btnLabel2: 'Yes, Cancel',
      btnLabel2Callback: async () => {
        restoreState(currentStep, formLeaseModificationPayload, navigate);
      },
      handleCloseConfirmDialog: () =>
        setDilaogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
  };

  return (
    <Fragment>
      <div style={{ paddingTop: '5%', justifyContent: 'flex-end', display: 'flex' }}>
        <Button
          onClick={displayCancelConfirmation}
          variant={'outlined'}
          sx={{ width: '215px' }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}>
          <CancelIcon
            style={{
              width: '16px',
              transition: 'color 0.3s ease-out',
              color: hovered ? '#C62828' : '#1D2F5C'
            }}
          />
          <div style={{ paddingLeft: '4%', fontWeight: 'bold', textTransform: 'capitalize' }}>
            Cancel Form Generation
          </div>
        </Button>
        <Dialog {...dialogDetails} />
      </div>
      <div style={{ width: '4%' }}></div>
    </Fragment>
  );
};

export default CancelProcess;
