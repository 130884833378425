import React, { FC } from 'react';
import { styled, withStyles } from '@mui/styles';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import clsx from 'clsx';
import { ResidentCustomPagingProps } from '../../../modules/dashboard/residents/domain/Resident';

const ResidentCustomPaging: FC<ResidentCustomPagingProps> = ({ tableData, limit, itemsByPage }) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    itemsByPage(event.target.value);
  };

  const MySelection = styled(Select)({
    maxHeight: '30px',
    borderRadius: '4px',
    width: '114px',
    position: 'relative',
    paddingLeft: '5px',
    paddingBottom: '2px',
    border: '1px solid #ced4da',
    '& .MuiSelect-select': {
      paddingRight: '0px',
      fontSize: '14px'
    }
  });

  const iconStyles = {
    selectIcon: {
      fontSize: '10px',
      color: '#00000040'
    }
  };
  const ExpandMore = withStyles(iconStyles)(({
    className,
    classes,
    ...rest
  }: {
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    classes: any;
  }) => {
    return <KeyboardArrowDownIcon {...rest} className={clsx(className, classes.selectIcon)} />;
  });

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        {tableData?.total > 0 && (
          <MySelection
            defaultValue=""
            value={tableData?.total <= 10 ? 10 : limit}
            onChange={handleChange}
            SelectDisplayProps={{ style: { paddingRight: '0px' } }}
            IconComponent={ExpandMore}>
            {tableData?.total <= 10 && <MenuItem value={10}>10 / page</MenuItem>}
            {tableData?.total >= 10 && <MenuItem value={20}>20 / page</MenuItem>}
            {tableData?.total >= 20 && <MenuItem value={30}>30 / page</MenuItem>}
            {tableData?.total >= 30 && <MenuItem value={50}>50 / page</MenuItem>}
            {tableData?.total > 50 && <MenuItem value={100}>100 / page</MenuItem>}
          </MySelection>
        )}
      </FormControl>
    </div>
  );
};

export default ResidentCustomPaging;
