/* eslint-disable */

export interface CommunityList {
  communities: Community[];
}

export interface Community {
  communityCode: string;
  name: string;
  value: string;
}

export const formatCommunities = (data: any) => {
  return data?.communities?.map((community: Community) => ({
    value: community.communityCode,
    label: `${community.communityCode} - ${community.name}`
  }));
};
