const Model = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 423,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  px: 3,
  py: 2,
  pb: 3
};

const Header = {
  pb: 1,
  borderBottom: 'solid 1px #DDD'
};

const Heading = {
  margin: 0,
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#31343A',
  display: 'flex',
  alignItems: 'center'
};

const CloseIcon = {
  color: '#F5222D',
  mr: 1
};

const SuccessIcon = {
  color: '#52C41A',
  mr: 1
};

const ConfirmIcon = {
  color: 'rgb(250, 140, 22)',
  mr: 1
};

const closeButton = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  ml: 1
};

const Body = {
  color: '#31343A',
  fontSize: '16px',
  my: 2
};

const Footer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  mt: 3
};

const GeneralButton = {
  fontSize: '14px',
  textTransform: 'capitalize',
  height: '32px',
  font: 'normal normal 600 14px/17px ProximaNova'
};

const Button = {
  ...GeneralButton,
  minWidth: '182px',
  backgroundColor: '#1D2F5C'
};

export const styles = {
  Model,
  Header,
  Heading,
  CloseIcon,
  SuccessIcon,
  closeButton,
  Body,
  GeneralButton,
  Button,
  Footer,
  ConfirmIcon
};
