import React from 'react';
import { Skeleton } from '@mui/material';
import { helpRequestStyles } from './styles';

const AccordionSkeleton = () => {
  const items = [1, 2, 3, 4];
  return (
    <div>
      {items.map((_, idx) => (
        <div key={idx} style={{ margin: '20px 0px' }}>
          <Skeleton variant="rectangular" sx={helpRequestStyles.skeletonContainer} height={40} />
        </div>
      ))}
    </div>
  );
};

export default AccordionSkeleton;
