/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from 'react';
import WindowGuard from './WindowGuard';
import { FormsProps } from '../../../../../modules/details/leaseForms/domain/LeaseForms';
import FormsByType from '../components/FormsByType';

interface formModuleProps {
  form: FormsProps;
  title: string;
  handleChange: (value: string, fieldName: string, formName: string) => void;
}

const FormModule: FC<formModuleProps> = ({ title, form, handleChange }) => {
  return (
    <div style={{ paddingTop: '2%' }}>
      <div style={{ paddingBottom: '2%', fontWeight: 'bold' }}>{title}</div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: form.formName.includes('Window') || form.formName.includes('Stove') ? '80%' : '70%'
        }}>
        {form.formName.includes('Window') || form.formName.includes('Stove') ? (
          <WindowGuard type={form.formName} form={form} handleChange={handleChange} />
        ) : (
          <FormsByType form={form} handleChange={handleChange} />
        )}
      </div>
    </div>
  );
};

export default FormModule;
