/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-vars */
// import PropTypes from 'prop-types';
import * as React from 'react';

//Material components
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';

// assets / icons
// @ts-ignore
import userIcon from '../../assets/imgs/user-circle.png';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useRef, FC } from 'react';
import { restoreState } from '../../modules/details/leaseForms/domain/LeaseAdditionalInfo';
import { AssociateContext } from '../../context/AssociateContext';

interface Props {
  title: string;
  appName: string;
  userName?: string;
  generateLeaseForms?: boolean;
  handleLogout: () => void;
  children?: React.ReactNode;
}

const LeaseHeader: FC<Props> = ({
  title,
  appName,
  userName = '',
  handleLogout,
  generateLeaseForms = false,
  children
}) => {
  const { currentStep, formLeaseModificationPayload } = React.useContext<any>(AssociateContext);
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent<HTMLElement>) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleNavigateDash = (generateLeaseForms: boolean) => {
    if (!generateLeaseForms) return;
    restoreState(currentStep, formLeaseModificationPayload, navigate);
  };

  dayjs.extend(advancedFormat);
  const currentDate = dayjs().format('dddd, MMMM Do, YYYY');

  return (
    <div className="header">
      <Grid container direction="column" alignItems="stretch">
        <Grid item>
          <Grid container direction="row" className="topBar">
            <Grid
              item
              xs={4}
              onClick={() => handleNavigateDash(generateLeaseForms)}
              sx={{ cursor: generateLeaseForms ? 'pointer' : 'auto' }}>
              {title}
            </Grid>
            <Grid item xs textAlign="center" className="app-name">
              {appName}
            </Grid>
            <Grid item xs={4} textAlign="right" className="align-center text-end">
              <div
                ref={anchorRef}
                onClick={handleToggle}
                style={{ display: 'flex', cursor: 'pointer' }}>
                <img src={userIcon} alt="userIcon" />
                <p style={{ fontSize: '16px' }}>{userName}</p>
                <KeyboardArrowDownIcon />
              </div>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}>
                          <div style={{ width: '180px' }}>
                            <MenuItem onClick={handleLogout}>Sign out</MenuItem>
                          </div>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" className="titleBar">
            <Grid item xs="auto" sx={{ fontSize: '30px', fontWeight: '300' }}>
              {generateLeaseForms
                ? 'Generate Lease Forms'
                : id
                  ? 'Renewal Details'
                  : 'Hi ' + userName.split(' ')[0] + '!'}
            </Grid>
            <Grid item xs textAlign="right">
              {currentDate}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>{children}</div>
    </div>
  );
};

export default LeaseHeader;
