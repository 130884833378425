/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useContext, useEffect, useState } from 'react';
import TextFieldUtil from '../individualLease/utils/TextFieldUtil';
import DropdownUtil from '../individualLease/utils/DropDownUtil';
import {
  FormsProps,
  LeaseFlows
} from '../../../../../modules/details/leaseForms/domain/LeaseForms';
import { useSearchParams } from 'react-router-dom';
import { AssociateContext } from '../../../../../context/AssociateContext';
import { validateFullLease } from '../../../../../modules/details/leaseForms/domain/LeaseAdditionalInfo';
import DateSelectUtil from '../individualLease/utils/DateSelectUtil';

interface FormsByTypeProps {
  form: FormsProps;
  handleChange: (value: string, fieldName: string, formName: string) => void;
}

enum CurrentSelection {
  'New Lease Package',
  'Renewal Lease Package',
  'Individual Lease'
}

const FormsByType: FC<FormsByTypeProps> = ({ form, handleChange }) => {
  const { currentSelection, leasePayload } = useContext<any>(AssociateContext);
  const [searchParams] = useSearchParams();
  const leaseFlow: string | undefined = searchParams.get('leaseFlow')?.toLowerCase() ?? '';

  const [validateLease, setValidateLease] = useState(true);

  useEffect(() => {
    const result = validateFullLease(form, currentSelection, leaseFlow, leasePayload);
    setValidateLease(result);
  }, []);

  return (
    <div>
      {form?.formSchema?.fields.map((field: any, idx: number) => (
        <div key={idx} style={{ paddingRight: '2%', paddingBottom: '2%' }}>
          {field?.fieldValidation?.pattern.includes('MM/DD/YYYY') && (
            <DateSelectUtil
              validateFullFormInput={validateLease}
              label={field.label}
              value={null}
              validationPattern={field?.fieldValidation?.pattern}
              onChange={(value: string) => handleChange(value, field.fieldName, form.formName)}
            />
          )}
          {field.fieldType === 'input_text' &&
            !field?.fieldValidation?.pattern.includes('MM/DD/YYYY') && (
              <TextFieldUtil
                validateFullFormInput={validateLease}
                label={field.label}
                value={field.value}
                validationPattern={field?.fieldValidation?.pattern}
                onChange={(value: string) => handleChange(value, field.fieldName, form.formName)}
              />
            )}
          {field.fieldType === 'select' && (
            <DropdownUtil
              validateFullFormInput={validateLease}
              label={field.label}
              isBedBugForm={form.docusignActionName.toLowerCase() === 'bedbug'}
              options={field.fieldValues}
              selectedOption={field.value}
              onChange={(value: string) => handleChange(value, field.fieldName, form.formName)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FormsByType;
