/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(
  ({
    className,
    children,
    placement,
    arrow,
    title,
    ...props
  }: {
    className?: string;
    children: any;
    placement: any;
    arrow: any;
    title: string;
  }) =>
    children &&
    title && (
      <Tooltip
        children={children}
        arrow={arrow}
        placement={placement}
        title={title}
        {...props}
        classes={{ popper: className }}
      />
    )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 219
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
      boxShadow: theme.shadows[1]
    },
    color: theme.palette.common.white
  }
}));

interface InfoToolTipProps {
  text: any;
  position: string;
  icon: any;
  widthIcon: number;
}

const InfoToolTip: FC<InfoToolTipProps> = ({ text, position, icon, widthIcon }) => {
  return (
    <LightTooltip placement={position} title={text} arrow>
      <img src={icon} width={widthIcon} />
    </LightTooltip>
  );
};

export default InfoToolTip;
