/* eslint-disable */
import associateAPI from '../../../../api/associateAPI';
import { Filters, Resident } from '../domain/Resident';
import { ResidentRepository } from '../domain/ResidentRepository';

export function createResidentAPIRepository(): ResidentRepository {
  return {
    get
  };
}

const METHOD_SEARCH = 'renewals-associate/search';

export const getActiveFiltersFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('activeFilters') || '{}');
};

const get = async ({
  search = getActiveFiltersFromLocalStorage()?.search,
  page = 1,
  communityCodes = getActiveFiltersFromLocalStorage()?.communityCodes,
  limit = 20,
  orderBy,
  orderDirection,
  helpRequest = getActiveFiltersFromLocalStorage()?.checkedFilters,
  requestTypes = getActiveFiltersFromLocalStorage()?.requestTypesFilters,
  statuses = getActiveFiltersFromLocalStorage()?.statusFilter,
  leaseEndMonths = getActiveFiltersFromLocalStorage()?.leaseEndMonthsFilter,
  ntvDueDateMonths = getActiveFiltersFromLocalStorage()?.ntvDueDateMonthsFilter
}: Filters = {}): Promise<Resident> => {
  const params = new URLSearchParams();
  search = getActiveFiltersFromLocalStorage()?.search;
  if (search?.length) params.append('search', getActiveFiltersFromLocalStorage()?.search);
  if (page) params.append('page', page.toString());
  if (limit) params.append('limit', limit.toString());
  if (communityCodes?.length > 0) {
    const communities = communityCodes.join(',');
    params.append('communityCodes', communities);
  }
  if (helpRequest) params.append('helpRequestTypes', helpRequest);
  if (requestTypes) params.append('requestTypes', requestTypes);
  if (statuses) params.append('statuses', statuses);
  if (leaseEndMonths) params.append('leaseEndMonths', leaseEndMonths);
  if (ntvDueDateMonths) params.append('ntvDueDateMonths', ntvDueDateMonths);
  if (orderBy) params.append('orderBy', orderBy);
  if (orderDirection) params.append('orderDirection', orderDirection);

  const activeFilters = {
    limit,
    search: getActiveFiltersFromLocalStorage()?.search,
    communityCodes,
    communities: getActiveFiltersFromLocalStorage()?.communities,
    checkedFilters: helpRequest,
    requestTypesFilters: requestTypes,
    statusFilter: statuses,
    leaseEndMonthsFilter: leaseEndMonths,
    ntvDueDateMonthsFilter: ntvDueDateMonths
  };

  const activeFiltersJSON = JSON.stringify(activeFilters);
  localStorage.setItem('activeFilters', activeFiltersJSON);

  try {
    const { data } = await associateAPI.get(METHOD_SEARCH, { params });
    return data;
  } catch (error) {
    return error as Resident;
  }
};
