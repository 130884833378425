import dayjs from 'dayjs';

export const filterCheckboxesStatus = [
  { name: 'Select All', displayName: 'Select All', value: 'all' },
  { name: 'Lease Generated', displayName: 'Lease Generated', value: 'lease-generated' },
  { name: 'Lease Initiated', displayName: 'Lease Initiated', value: 'lease-initiated' },
  { name: 'NTV Completed', displayName: 'NTV Completed', value: 'ntv-completed' },
  { name: 'Renewal Completed', displayName: 'Renewal Completed', value: 'renewal-completed' },
  { name: 'Renewal Not Ready', displayName: 'Renewal Not Ready', value: 'renewal-not-ready' },
  { name: 'Renewal Ready', displayName: 'Renewal Ready', value: 'renewal-ready' }
];

export const filterCheckboxesRequestType = [
  { name: 'Select All', displayName: 'Select All', value: 'all' },
  {
    name: 'NTV Requested (No Renewal Offer)',
    sublabel: '(No Renewal Offer)',
    displayName: 'NTV Requested',
    value: 'notice-to-vacate-no-offer'
  },
  {
    name: 'NTV Requested (With Renewal Offer)',
    sublabel: '(With Renewal Offer)',
    displayName: 'NTV Requested',
    value: 'notice-to-vacate-offer'
  },
  { name: 'Add-ons', displayName: 'Add-ons', value: 'addons' },
  { name: 'DocuSign', displayName: 'DocuSign', value: 'docusign' },
  { name: 'Lease Modification', displayName: 'Lease Modification', value: 'lease-modification' },
  { name: 'Process Move Out', displayName: 'Process Move Out', value: 'process-move-out' },
  { name: 'Move Out Inquiry', displayName: 'Move Out Inquiry', value: 'move-out-inquiry' },
  { name: 'Occupancy Change', displayName: 'Occupancy Change', value: 'occupancy-change' },
  { name: 'Rate/Term', displayName: 'Rate/Term', value: 'rate-term' },
  { name: 'Renewal Billing', displayName: 'Renewal Billing', value: 'renewal-billing' },
  { name: "Renter's Insurance", displayName: "Renter's Insurance", value: 'renters-insurance' },
  { name: 'Transfer Apartments', displayName: 'Transfer Apartments', value: 'transfer-apartments' },
  { name: 'Window Guard', displayName: 'Window Guard', value: 'window-guard' }
];

export const filterCheckboxesCurrentLease = (isNtvDueDate: boolean) => {
  const currentDate = dayjs();

  let months;
  isNtvDueDate
    ? (months = Array.from({ length: 9 }, (_, idx) => ({
        name:
          idx == 0
            ? 'Prior to ' + currentDate.add(idx, 'month').format('MMM YYYY')
            : idx == 8
              ? currentDate.add(idx - 1, 'month').format('MMM YYYY') + ' and later'
              : currentDate.add(idx - 1, 'month').format('MMM YYYY'),
        displayName:
          idx == 0
            ? 'Prior to ' + currentDate.add(idx, 'month').format('MMM YYYY')
            : idx == 8
              ? currentDate.add(idx - 1, 'month').format('MMM YYYY') + ' and later'
              : currentDate.add(idx - 1, 'month').format('MMM YYYY'),
        value:
          idx == 0
            ? 'prior'
            : idx == 8
              ? 'later'
              : currentDate.add(idx - 1, 'month').format('MM/YYYY')
      })))
    : (months = Array.from({ length: 9 }, (_, idx) => ({
        name:
          idx == 8
            ? currentDate.add(idx, 'month').format('MMM YYYY') + ' and later'
            : currentDate.add(idx, 'month').format('MMM YYYY'),
        displayName:
          idx == 8
            ? currentDate.add(idx, 'month').format('MMM YYYY') + ' and later'
            : currentDate.add(idx, 'month').format('MMM YYYY'),
        value: idx == 8 ? 'later' : currentDate.add(idx, 'month').format('MM/YYYY')
      })));
  const initialValue = { name: 'Select All', displayName: 'Select All', value: 'all' };
  return [initialValue, ...months];
};
