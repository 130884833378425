/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeaseFormsRepository } from '../domain/LeaseFormsRepository';

export async function postGenerateForms(
  leaseFormsRepository: LeaseFormsRepository,
  leaseId: string | null,
  data: any
): Promise<any> {
  return await leaseFormsRepository.postGenerateForms(leaseId, data);
}
