/* eslint-disable */
import React, { useState, FC, useEffect } from 'react';

import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ListTerms from './ListTerms';

import { useParams } from 'react-router-dom';

import CloseBtn from './CloseBtn';
// @ts-ignore
import { SummaryCardstyles } from './styles';
// @ts-ignore
import Question from '../../../../assets/imgs/detailsIcons/Question.png';
import Alert from '@mui/material/Alert';

interface offerSelectedQueryProps {
  isLoading: boolean;
  isError: boolean;
  data: any;
  isFetching: boolean;
}

interface Offer {
  term: string;
  totalRenewalRate: number;
  concession: number;
  selected: boolean;
  bestRate: boolean;
}

interface SummaryCardProps {
  onClose: () => void;
  open: boolean;
  offers: Offer[] | any;
  offerSelected: (id: string, selectedTerm: string) => void;
  offerSelectedQuery: offerSelectedQueryProps;
  isNYC421a: boolean | undefined;
  isBlockedMTM: boolean | undefined;
}

const SummaryCard: FC<SummaryCardProps> = (props) => {
  const {
    onClose,
    open,
    offers,
    offerSelected,
    offerSelectedQuery,
    isNYC421a = false,
    isBlockedMTM = false
  } = props;

  const [selectedTerm, setSelectedTerm] = useState<string>('');
  const [renderWarningBox, setRenderWarningBox] = useState(false);
  const { id } = useParams();

  const handleClose = () => {
    setSelectedTerm('');
    setRenderWarningBox(false);
    onClose();
  };

  const handleTermSelected = (selectedEvent: string) => {
    if (selectedEvent.toUpperCase() === 'MTM' && isBlockedMTM) {
      setRenderWarningBox(true);
    }
    setSelectedTerm(selectedEvent);
  };

  const handleConfirmSelection = () => {
    if (!id) return;
    offerSelected(id, selectedTerm);
  };

  const BlackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#31343A'),
    backgroundColor: '#31343A',
    '&:hover': {
      backgroundColor: '#262626'
    }
  }));

  const message = () => {
    return (
      <>
        <div style={{ fontWeight: 'bold' }}>
          Note: Month to Month terms are not available for resident selection at this property.{' '}
        </div>
        <div>Ensure you have proper approval to generate a MTM lease.</div>
      </>
    );
  };
  useEffect(() => {
    if (!offerSelectedQuery.isLoading && !offerSelectedQuery.isError) {
      handleClose();
    }
  }, [offerSelectedQuery.data]);

  return (
    <Dialog
      open={open}
      sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ paddingBottom: '0px' }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                margin: '0px 0px 0px 20px'
              }}>
              <div style={{ display: 'flex', margin: '12px  0px 12px 20px' }}>
                <img width={'24px'} height={'24px'} src={Question} alt="questionIcon" />
              </div>
              <div style={{ margin: '0px 15px 0px 12px' }}>
                <p
                  style={{
                    fontSize: '12px',
                    fontFamily: 'ProximaNova',
                    letterSpacing: '0.15px',
                    color: '#31343A'
                  }}>
                  Click on <span style={{ fontWeight: 600 }}>Select Term</span> then select{' '}
                  <span style={{ fontWeight: 600 }}>Confirm </span> to see the updated base rent and
                  fee allocation in the <span style={{ fontWeight: 600 }}>Renewal Plan</span> for
                  the selected term.
                </p>
              </div>
            </div>
            <div style={{ paddingRight: '3%' }}>
              <CloseBtn handleClose={handleClose} />
            </div>
          </div>
        </DialogTitle>
      </div>
      <div style={{ maxHeight: '80%', overflowY: 'scroll', overflowX: 'hidden' }}>
        <ListTerms
          offers={offers}
          onTermSelected={handleTermSelected}
          isNYC421a={isNYC421a}
          isBlockedMTM={isBlockedMTM}
        />
      </div>
      {renderWarningBox && (
        <div>
          <div style={{ padding: '5% 13% 0px 7%' }}>
            <Alert variant="outlined" severity="error" sx={{ backgroundColor: '#FBEAEA' }}>
              {message()}
            </Alert>
          </div>
        </div>
      )}
      <div style={{ ...SummaryCardstyles.confirmSelectionBtn, paddingBottom: '5%' }}>
        <BlackButton
          onClick={handleConfirmSelection}
          disabled={!selectedTerm}
          style={
            !selectedTerm || offerSelectedQuery.isFetching
              ? {
                  ...SummaryCardstyles.DisableGeneralButton,
                  textTransform: 'capitalize',
                  pointerEvents: 'none'
                }
              : { ...SummaryCardstyles.GeneralButton, textTransform: 'capitalize' }
          }>
          {!offerSelectedQuery.isFetching ? (
            'Confirm Selection'
          ) : (
            <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
          )}
        </BlackButton>
      </div>
    </Dialog>
  );
};

export default SummaryCard;
