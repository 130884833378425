interface FlexContainerProps {
  display?: 'flex';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | undefined;
  justifyItems?: string;
  justifyContent?: string;
  alignItems?: string;
  columnGap?: string;
  gap?: string;
}

// ArrowIcons

const ArrowIconsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '25px',
  cursor: 'pointer',
  paddingLeft: '11px'
};

const ArrowIconsAlignment: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'right'
};

const PositionArrowIcon = {
  zIndex: '10',
  marginTop: '-13px'
};

const HeaderText = {
  color: 'white',
  fontSize: '12px',
  lineHeight: '1.4'
};

const ArrowsContainer: FlexContainerProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '9px'
};

// StatusLabel

const LabelContainer: FlexContainerProps = {
  display: 'flex',
  gap: '5px',
  flexDirection: 'column'
};

export const TableStyles = {
  // ArrowIcons
  ArrowIconsContainer,
  ArrowIconsAlignment,
  PositionArrowIcon,
  HeaderText,
  ArrowsContainer,
  //StatusLabel
  LabelContainer
};
