import React, { FC } from 'react';

import { SummaryCardstyles } from './styles';

interface CloseBtnProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClose?: () => any;
  withoutBorderRound?: boolean;
}

const CloseBtn: FC<CloseBtnProps> = ({ handleClose, withoutBorderRound = false }) => {
  return (
    <div
      onClick={handleClose}
      style={{
        ...SummaryCardstyles.closeContainerBtn,
        border: withoutBorderRound ? '0' : ('1px solid #979AA0' as string)
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={
          withoutBorderRound ? SummaryCardstyles.closeIconNoBorder : SummaryCardstyles.closeIcon
        }
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  );
};

export default CloseBtn;
