import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { createAPILeaseDetailsRepository } from '../../../../../modules/details/leaseDetails/infrastructure/APILeaseDetailsRepository';
import { postForcedLease } from '../../../../../modules/details/leaseDetails/application/postForcedLease';

const repository = createAPILeaseDetailsRepository();

export const useForcedLease = () => {
  const [leaseId, setLeaseId] = useState<string | null>(null);

  const forcedLeaseQuery = useQuery({
    queryKey: ['forcedLease', leaseId],
    queryFn: () => postForcedLease(repository, leaseId),
    enabled: !!leaseId
  });

  const forcedLease = (leaseId: string) => {
    setLeaseId(leaseId);
  };

  return {
    forcedLeaseQuery,
    //Methods
    forcedLease
  };
};
