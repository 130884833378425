import React from 'react';

export const montgomery = () => {
  return (
    <>
      <div>
        <span style={{ fontWeight: 'bold' }}>AvalonBay is required</span> by law to install window
        guards on any window in the apartment that can be opened and is located above a ground floor
        if a child 10 years of age or younger lives in the apartment, or if the resident asks to
        have window guards put in at any time. (The resident does not need to give a reason.)
      </div>
      <div style={{ paddingTop: '2%' }}>
        <span style={{ fontWeight: 'bold' }}>AvalonBay is not required</span> to install window
        guards: on windows that do not open, if the apartment is located on the ground-level floor
        of the building, on any window that has an air conditioning unit permanently bolted to the
        window and not surrounded by an open space exceeding 4 inches, or on windows leading to a
        fire escape ladder or an exterior balcony.{' '}
      </div>
      <div
        style={{
          fontWeight: 'bold',
          paddingTop: '2%'
        }}>
        Please ask the resident the following questions so that the DocuSign lease can be prepared
        with their selections for signature. Select their answers here:
      </div>
    </>
  );
};

export const yonkers = () => {
  return (
    <>
      <div>
        AvalonBay is required by law to install window guards on any window in the apartment that
        can be opened and is located above a ground floor if a child 10 years of age or younger
        lives in the apartment, or if the resident asks to have window guards put in at any time.
        (The resident does not need to give a reason.)
      </div>
      <div
        style={{
          fontWeight: 'bold',
          paddingTop: '2%'
        }}>
        Please ask the resident the following questions so that the DocuSign lease can be prepared
        with their selections for signature. Select their answers here:
      </div>
    </>
  );
};

export const footerMontgomery = () => {
  return (
    <div style={{ paddingTop: '2%' }}>
      <div style={{ fontWeight: 'bold' }}>Next steps:</div>
      <ul>
        <li>
          <span style={{ fontWeight: 'bold' }}>AvalonBay is required to </span>demonstrate how to
          safely operate the window guards in the apartment. If window guards are required,{' '}
          <span style={{ fontWeight: 'bold' }}>
            reference Salesforce and send out template to share a video link with the resident
            demonstrating how to operate window guards.
          </span>
          The resident can also request an in-person demonstration upon move-in. By clicking
          “Generate and Sign” on this form, you confirm that you have sent the resident information
          about how to safely operate window guards.
        </li>
        <li>
          If resident&apos;s answers indicate that a{' '}
          <span style={{ fontWeight: 'bold' }}>window guard installation</span> is required,
          <span style={{ fontWeight: 'bold' }}> enter a service ticket </span> for maintenance
          response.
        </li>
      </ul>
    </div>
  );
};

export const footerYonkers = () => {
  return (
    <div style={{ paddingTop: '2%' }}>
      <div style={{ fontWeight: 'bold' }}>
        If resident&apos;s answers indicate that a window guard installation is required:
      </div>
      <ul>
        <li>Enter a service ticket for maintenance response.</li>
      </ul>
    </div>
  );
};

export const NYC = () => {
  return (
    <>
      AvalonBay is required by law to install window guards on any window in the apartment that can
      be opened and is located above ground floor if a child 10 years of age or younger lives in the
      apartment, or if the resident asks to have window guards put in at any time. (The resident
      does not need to give a reason.)
      <div
        style={{
          fontWeight: 'bold',
          paddingTop: '2%'
        }}>
        Please ask the resident the following questions so that the DocuSign lease can be prepared
        with their selections for signature. Select their answers here:
      </div>
    </>
  );
};

export const knob = () => {
  return (
    <div style={{ paddingBottom: '2%' }}>
      The owner of this building is required by law to provide stove knob covers or permanent stove
      safety knobs with integrated locking mechanisms for each knob located on the front of each
      gas-powered stove to tenants in each dwelling unit in which a child under six years of age
      resides when requested to do so in writing by the tenant, unless there is no available stove
      knob cover or permanent stove safety knobs with integrated locking mechanisms that is
      compatible with the knobs on ths stove.
    </div>
  );
};
