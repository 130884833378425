import MainHeader from '../mainHeader/MainHeader';
import { styles } from './styles';

import bgImage from '../../assets/imgs/home-bg.png';

const SignIn = () => {
  return (
    <div style={styles.Container}>
      <MainHeader />
      <img
        src={bgImage}
        style={{ width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden' }}
        alt="avalonBay"
      />
    </div>
  );
};

export default SignIn;
