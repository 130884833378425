/* eslint-disable */

import React, { FC } from 'react';
import { occupantDetailsStyles } from './styles';

// @ts-ignore
import occupantIcon from '../../../../assets/imgs/requestTypes/icon_OccupancyChange.png';

const OccupantDetails: FC<any> = ({ details }) => {
  return (
    <>
      <h2 style={occupantDetailsStyles.h2}>
        <img src={occupantIcon} width={'21px'} alt="occupant Icon" />
        <span style={{ paddingLeft: '10px' }}>OCCUPANT DETAILS</span>
      </h2>
      <div style={occupantDetailsStyles.occupantDetailsContainer}>
        <div className="od-name" style={occupantDetailsStyles.col}>
          Name
        </div>
        <div className="od-sig-completed" style={{ width: '15%' }}>
          Renewal Signature
        </div>
        <div className="od-type" style={occupantDetailsStyles.col}>
          Type
        </div>
        <div className="od-email" style={occupantDetailsStyles.colLarge}>
          Email
        </div>
        <div className="od-phone" style={{ width: '15%' }}>
          Phone
        </div>
      </div>
      <div style={occupantDetailsStyles.content}>
        {details?.map((option: any, idx: number) => (
          <div style={occupantDetailsStyles.occupantContent} key={idx}>
            <div style={occupantDetailsStyles.col}>
              {[option.firstName, option.lastName].join(' ')}
            </div>
            {option?.status == 'N/A' || option?.status == undefined ? (
              <div style={{ width: '15%', color: '#31343A' }}>N/A</div>
            ) : (
              <div
                style={{
                  width: '15%',
                  color: option?.status.toUpperCase() === 'NO' ? '#F5222D' : '#52C41A'
                }}>
                {option.status}
              </div>
            )}
            <div style={occupantDetailsStyles.col}>{option.type}</div>
            <div style={occupantDetailsStyles.colLarge}>{option.email}</div>
            <div style={{ width: '15%' }}>{option.phoneNumber}</div>
          </div>
        ))}
      </div>
    </>
  );
};
export default OccupantDetails;
