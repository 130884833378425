/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect } from 'react';
import { AssociateContext } from '../../../../context/AssociateContext';
import SelectLeaseModification from '../typeOfGenerateLease/individualLease/SelectLeaseModification';

const SelectedLease = () => {
  const { currentSelection } = useContext<any>(AssociateContext);

  return (
    <div style={{ paddingBottom: '2%' }}>
      <div
        style={{
          borderStyle: 'solid',
          borderColor: '#E0E0E0',
          borderWidth: '2px',
          borderRadius: '4px'
        }}>
        <p
          style={{
            color: '#31343A',
            fontSize: '18px',
            fontWeight: 500,
            textTransform: 'uppercase',
            paddingLeft: '2%'
          }}>
          {currentSelection === 'Individual Lease'
            ? 'Selected Lease Modifications'
            : currentSelection}
        </p>

        {currentSelection === 'Individual Lease' && (
          <div style={{ paddingBottom: '2%' }}>
            <SelectLeaseModification
              handleClose={() => {}}
              handleStep={() => {}}
              isSecondScreen={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedLease;
