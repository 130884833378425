/* eslint-disable */
import React, { Fragment, useContext, FC } from 'react';
// utils
// @ts-ignore
import AccordionAssociate from './AccordionAssociate';
import dayjs from 'dayjs';
// @ts-ignore
import doneIcon from '../../../../assets/imgs/detailsIcons/boldCheck.png';
import {
  Action,
  getAnswers,
  tableBodyContent
} from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import { AssociateContext } from '../../../../context/AssociateContext';
import { HelpRequestType } from '../../../../modules/details/helpRequest/domain/HelpRequestRepository';

const ResolveHelpRequest: FC<any> = ({ resolvedRequests, leaseDetails }) => {
  const { answersWindowGuard } = useContext<any>(AssociateContext);

  const LABEL_STATUS = {
    voided: 'Void in DocuSign',
    completed: 'Forced Complete',
    'lease-generated': 'Generated Lease in DocuSign'
  };

  const formatDate = (dateStr: any) => {
    return dateStr ? dayjs(dateStr).format('MM/DD/YYYY') : '';
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '25px',
          paddingTop: '25px'
        }}>
        <img src={doneIcon} width={'21px'} alt="checkIcon" />
        <p
          style={{
            fontWeight: '600',
            fontSize: '20px',
            paddingLeft: '10px'
          }}>
          RESOLVED HELP REQUEST(S) & OTHER ACTIONS
        </p>
      </div>
      {resolvedRequests.map((action: any, idx: number) =>
        action.primaryReason !== undefined ? (
          <Fragment key={action?.id ?? idx}>
            <AccordionAssociate
              labels={[action?.vacateAutomated ? 'Process Move Out' : 'NTV Requested']}
              subLabel={
                !action?.vacateAutomated &&
                (action?.requestType === 'notice-to-vacate-no-offer'
                  ? ['(No Renewal Offer)']
                  : ['(With Renewal Offer)'])
              }
              status={'Resolved'}
              date={dayjs(action?.resolvedDate).format('MM/DD/YYYY')}
              tableBody={{
                submittedBy: action?.submittedBy,
                submittedOn: action?.submittedOn,
                moveOutDate: dayjs(action?.moveOutDate).format('MM/DD/YYYY'),
                leaseEndDate: dayjs(action?.leaseEndDate).format('MM/DD/YYYY'),
                primaryReasonMovingOut: action?.primaryReason,
                residentType: action?.moveInType,
                forwardingAddress: {
                  street: action?.forwardingAddress?.street,
                  city: action?.forwardingAddress?.city,
                  addressLine2: action?.forwardingAddress?.addressLine2,
                  state: action?.forwardingAddress?.state,
                  zip: action?.forwardingAddress?.zip,
                  country: action?.forwardingAddress?.country
                },
                feedBack: action.feedback,
                electronicSignature: action?.signature,
                statusMRI:
                  action.enteredInMri === true ? 'Entered NTV in MRI' : 'Not Entered NTV in MRI',
                resolvedBy: action?.resolvedByName !== null && action?.resolvedByName,
                dateTimeResolved: action.resolvedDate,
                MRIStatus: false,
                isHelpRequest: true,
                daysNotice: action?.vacateAutomated && action?.daysNotice,
                isProcessMoveOut: action?.vacateAutomated,
                feedback: action?.vacateAutomated && action?.feedback
              }}
              isRequiredDaysNoticeMet={action?.daysNotice >= action?.requiredDaysNoticeMet}
              defaultOpen={false}
            />
          </Fragment>
        ) : action?.helpRequestStatus !== undefined ? (
          <AccordionAssociate
            key={action?.id ?? idx}
            labels={action?.helpRequestTypes?.map(
              ({ displayName }: { displayName: string }) => displayName
            )}
            status={action?.helpRequestStatus.action}
            date={dayjs(action?.resolvedDate).format('MM/DD/YYYY')}
            tableBody={
              Boolean(
                action?.helpRequestTypes?.find(
                  (hrt: HelpRequestType) => hrt.key === 'lease-modification'
                )
              )
                ? {
                    resolvedBy: action?.resolvedByName !== null && action?.resolvedByName,
                    dateTimeResolved: action.resolvedDate,
                    documentStatus: action?.documentStatus || 'document-voided',
                    generatedOn: dayjs(action?.createdDate).format('MM/DD/YYYY'),
                    leaseModifications: action?.notes,
                    MRIStatus: false,
                    windowGuard: action?.formData?.formName?.includes('Window Guard'),
                    isLeaseModification: true,
                    isHelpRequest: true,
                    isResolved: true
                  }
                : {
                    submittedBy: `${leaseDetails?.resident?.firstName} ${leaseDetails?.resident?.lastName} `,
                    submittedOn: `${dayjs(action?.createdDate).format('MM/DD/YYYY')}`,
                    description: action?.notes,
                    resolvedBy: action?.resolvedByName !== null && action?.resolvedByName,
                    dateTimeResolved: action.resolvedDate,
                    MRIStatus: false,
                    windowGuard: action?.formData?.formName?.includes('Window Guard'),
                    windowGuardIsResolved: true,
                    isLeaseModification: false,
                    isHelpRequest: false
                  }
            }
            residentResponse={
              action?.formData?.formName?.includes('Window Guard')
                ? getAnswers(resolvedRequests, answersWindowGuard)[idx]
                : []
            }
            occupantDetails={action.signerStatus}
            defaultOpen={false}
            leaseType={leaseDetails?.lease?.leaseType.toUpperCase()}
            requireWetSignature={leaseDetails?.data?.requireWetSignature}
          />
        ) : (
          <AccordionAssociate
            key={idx}
            title={LABEL_STATUS[action.action?.action as keyof typeof LABEL_STATUS] || ''}
            status={action.action?.action}
            labels={[]}
            date={formatDate(action.actionDate)}
            tableBody={{ ...tableBodyContent(action), isHelpRequest: true }}
            defaultOpen={false}
          />
        )
      )}
    </>
  );
};

export default ResolveHelpRequest;
