/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { FormObject } from '../../../../../modules/details/leaseForms/domain/LeaseModification';

import FormModule from '../individualLease/FormModule';
import { CircularProgress, Divider } from '@mui/material';
import { AssociateContext } from '../../../../../context/AssociateContext';
import Dialog, { DialogType } from '../../../../../shared/dialog/Dialog';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DialogDetailsType } from '../../../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';

import { displayConfirmation } from '../../../../../modules/details/leaseDetails/domain/LeaseDetailsDialogs';
import { fullLeaseStyles } from './styles';
import { useGenerateForms } from '../hooks/useGenerateForms';
import {
  getTerm,
  isValid,
  restoreState,
  validateFullLease
} from '../../../../../modules/details/leaseForms/domain/LeaseAdditionalInfo';
import { FormsProps } from '../../../../../modules/details/leaseForms/domain/LeaseForms';

interface fullLeaseAdditionalProps {
  handleStep: (value: number) => void;
}

const FullLeaseAdditionalInfo: FC<fullLeaseAdditionalProps> = ({ handleStep }) => {
  const { currentSelection, currentStep, leasePayload, formData, formLeaseModificationPayload } =
    useContext<any>(AssociateContext);
  const [requiredForms, setRequiredForms] = useState<FormsProps[] | []>([]);
  const [forms, setForms] = useState<any>();
  const [isValidForm, setIsValidForm] = useState(true);

  // router
  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const leaseFlow: string | undefined = searchParams.get('leaseFlow')?.toLowerCase() ?? '';

  const { leaseId = '0' } = params;
  // queries
  const { generateFormsQuery, generateForms } = useGenerateForms();

  // Dialogs
  const [dialogDetails, setDilaogDetails] = useState<DialogDetailsType>({
    type: DialogType.NoIcon,
    headerLabel: '',
    body: '',
    btnLabel1: 'Cancel',
    btnLabel2: 'Confirm',
    isOpen: false,
    btnLabel2Callback: () => Promise.resolve()
  });

  const handleCloseConfirmDialog = () => {
    setDilaogDetails({
      ...dialogDetails,
      isOpen: false
    });
  };

  const handleChildError = (errorDetails: any) => {
    setDilaogDetails({
      ...dialogDetails,
      ...errorDetails,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleChildSuccess: any = (
    successDetails: any,
    successAction: () => void,
    btnLabel2Callback: any
  ) => {
    successAction();
    setDilaogDetails({
      ...dialogDetails,
      ...successDetails,
      btnLabel2Callback: btnLabel2Callback,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleGenerateLease = () => {
    const data = {
      refId: leasePayload.refId,
      formData: formData ?? [],
      typeForm: currentSelection,
      leaseTerm: getTerm(leasePayload.lease.leaseTerm),
      action: leaseFlow
    };

    setDilaogDetails((prevDetails) => ({
      ...prevDetails,
      type: DialogType.NoIcon,
      customWidth: '455px',
      isLeaseMod: true,
      headerLabel: 'Are you sure you want to generate these forms?',
      body: (
        <div>
          The forms you selected will be compiled into a DocuSign package and sent to responsible
          residents and guarantors.{' '}
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Yes, Generate and send',
      btnLabel2Callback: async () => generateForms(leaseId, data),
      handleCloseConfirmDialog: () =>
        setDilaogDetails((prev) => ({
          ...prev,
          isOpen: false
        }))
    }));
  };

  const displayConfirmationGenerateForms = async () => {
    displayConfirmation({
      query: generateFormsQuery,
      successCode: 204,
      successMessage: 'The forms were submitted successfully',
      successContent: <div>This form has been successfully created.</div>,
      errorMessage: 'Failed to Submit the Form!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {
        restoreState(currentStep, formLeaseModificationPayload, navigate);
      },
      handleChildSuccess,
      handleChildError
    });
    generateForms(null, null);
  };

  const createFormData = (item: any) => {
    return item.formSchema.fields.length > 0
      ? item.formSchema.fields.map((field: any) => ({
          fieldName: field.fieldName,
          answerValue: field.value
        }))
      : [];
  };

  const formatPayload = (form: any) => {
    let res: any = [];
    res = form.map((item: any) => {
      const formDataItem = {
        formId: item.formId,
        formGroup: item.formGroupName,
        formData: createFormData(item)
      };

      return formDataItem;
    });
    return res;
  };

  const getRequiredForms = () => {
    const data = leasePayload;
    let res = [];

    res = data.forms?.filter((form: FormsProps) => {
      return validateFullLease(form, currentSelection, leaseFlow, leasePayload);
    });

    if (res.length) {
      const payload = formatPayload(res);
      formLeaseModificationPayload({
        form: payload
      });
    }
    setRequiredForms(res);
  };

  const handleChange = (newValue: string, fieldName: string, formName: string) => {
    const newValues = [...requiredForms];

    const indexElement = newValues.findIndex((element) => element.formName === formName);

    if (indexElement !== -1) {
      newValues[indexElement].formSchema.fields.forEach((field: any) => {
        if (field['fieldName'] === fieldName) {
          field.value = newValue;
        }
      });

      const payload = formatPayload(newValues);

      formLeaseModificationPayload({
        form: payload
      });
      setForms({ forms, fieldName, formName, currentSelection });
    }
  };

  useEffect(() => {
    displayConfirmationGenerateForms();
  }, [generateFormsQuery?.data]);

  useEffect(() => {
    getRequiredForms();
  }, []);

  useEffect(() => {
    if (formData) {
      const isValidForm = isValid(formData);
      setIsValidForm(isValidForm);
    }
  }, [formData]);

  return (
    <div style={{ padding: '2% 3%' }}>
      <div style={{ fontSize: '24px', color: '#31343A', textTransform: 'capitalize' }}>
        Complete additional information
      </div>
      <div style={{ fontSize: '16px', color: '#72767F', paddingTop: '1%' }}>
        All fields are required before you can generate the document package. Reference the Lease
        Summary to ensure that all changes have been made in MRI prior to generating these forms.
      </div>

      <Fragment>
        {!requiredForms?.length ? (
          <div>
            <p style={{ fontWeight: 400, fontSize: '18px' }}>
              No additional information required. Proceed to document generation.
            </p>
          </div>
        ) : (
          requiredForms?.map((form) => (
            <div key={form.formId}>
              <div>
                <FormModule title={form.formName} form={form} handleChange={handleChange} />
                <Divider variant="fullWidth" />
              </div>
            </div>
          ))
        )}
      </Fragment>
      <div
        style={{
          padding: '2%',
          paddingTop: '15%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <div>
          <Button onClick={() => handleStep(-1)}>Back</Button>
        </div>
        <div>
          {generateFormsQuery?.isFetching ? (
            <Button
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              style={fullLeaseStyles.DisabledButton}
              disabled={true}>
              <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
            </Button>
          ) : (
            <Button variant="contained" disabled={!isValidForm} onClick={handleGenerateLease}>
              Generate And Send
            </Button>
          )}
        </div>
      </div>
      <Dialog {...dialogDetails} />
    </div>
  );
};

export default FullLeaseAdditionalInfo;
