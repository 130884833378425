/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssociateInitialState } from './AssociateProvider';

interface UpdateFormStatusAction {
  type: 'updateFormStatus';
  payload: {
    prepFormValue: string | null;
    errorPrepForm: boolean;
    multipleWindowGuardForm: any | null;
    singleWindowGuardForm: any | null;
    errorMultipleGuardForm: boolean;
    isValidForm: boolean;
  };
}

interface LeaseDetailsAction {
  type: 'leaseDetails';
  payload: any;
}

interface AddendumAction {
  type: 'addendumsDetails';
  payload: any;
}

interface ResetFormAction {
  type: 'resetForm';
}

interface IndividualLeaseAction {
  type: 'individualLeaseForm';
  payload: any;
}

interface CurrentStepAction {
  type: 'currentStep';
  payload: {
    lastStep: number;
    displaySelection: boolean;
    currentSelection: string;
  };
}

interface FormLeaseModificationPayloadAction {
  type: 'formLeaseModificationPayload';
  payload: any;
}

type AssociateAction =
  | UpdateFormStatusAction
  | AddendumAction
  | ResetFormAction
  | LeaseDetailsAction
  | IndividualLeaseAction
  | CurrentStepAction
  | FormLeaseModificationPayloadAction;

const AssociateReducer = (state: AssociateInitialState, action: AssociateAction) => {
  switch (action.type) {
    case 'updateFormStatus': {
      return {
        ...state,
        prepFormValue: action.payload.prepFormValue,
        errorPrepForm: action.payload.errorPrepForm,
        multipleWindowGuardForm: action.payload.multipleWindowGuardForm,
        singleWindowGuardForm: action.payload.singleWindowGuardForm,
        errorMultipleGuardForm: action.payload.errorMultipleGuardForm,
        isValidForm: action.payload.isValidForm
      };
    }
    case 'addendumsDetails': {
      return {
        ...state,
        addendumPayload: action.payload.addendumPayload,
        answersWindowGuard: action.payload.answersWindowGuard,
        videos: action.payload.videos
      };
    }
    case 'leaseDetails': {
      return {
        ...state,
        leasePayload: action.payload.leasePayload,
        formsByCategory: action.payload.formsByCategory,
        forms: action.payload.forms
      };
    }
    case 'individualLeaseForm': {
      return {
        ...state,
        metadataForms: action.payload.metadataForms
      };
    }
    case 'currentStep': {
      return {
        ...state,
        lastStep: action.payload.lastStep,
        displaySelection: action.payload.displaySelection,
        currentSelection: action.payload.currentSelection
      };
    }
    case 'formLeaseModificationPayload': {
      return {
        ...state,
        formData: action.payload.formData
      };
    }
    default:
      return state;
  }
};

export default AssociateReducer;
