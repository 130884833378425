import { useQuery } from '@tanstack/react-query';
import { createAPILeaseDetailsRepository } from '../../../../../modules/details/leaseDetails/infrastructure/APILeaseDetailsRepository';
import { getLeaseDetails } from '../../../../../modules/details/leaseDetails/application/getLeaseDetails';

const repository = createAPILeaseDetailsRepository();

export const useLeaseDetails = (leaseId: string) => {
  const leaseDetailsQuery = useQuery({
    queryKey: ['leaseDetails', leaseId],
    queryFn: () => getLeaseDetails(repository, leaseId),
    refetchOnWindowFocus: false
  });

  return {
    leaseDetailsQuery,
    //Getter
    leaseId
  };
};
