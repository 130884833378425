import { createBrowserRouter } from 'react-router-dom';

// import RenewalDetails from '../components/RenewalDetails';
import SignIn from '../components/signIn/SignIn';
// import Dashboard from '../components/dashboard/Dashboard';
import Dashboard from '../sections/dashboard/views/Dashboard';
import LeaseDetailsView from '../sections/leaseDetails/views/LeaseDetailsView';
import FormsView from '../sections/forms/views/FormsView';

export const router = createBrowserRouter([
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/details/:id',
    element: <LeaseDetailsView />
  },
  {
    path: '/lease-details/:leaseId',
    element: <FormsView />
  },
  {
    path: '/',
    element: <SignIn />
  }
]);
