import React, { useContext } from 'react';
import { includesCommunity } from '../../../../modules/details/leaseDetails/domain/LeaseDetails';
import { AssociateContext } from '../../../../context/AssociateContext';
import { leaseDetailsStyles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WindowGuardSelectionSection = ({ form, guidelineSelection }: any) => {
  const { addendumPayload, prepFormValue, errorMultipleGuardForm } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useContext<any>(AssociateContext);

  return (
    <>
      {(includesCommunity(addendumPayload[0]?.name, 'Yonkers/Harrison') || prepFormValue) && (
        <div>
          <div style={leaseDetailsStyles.guideLineText}>{guidelineSelection}</div>
          <div style={{ paddingTop: '8px' }}>{form}</div>
          {errorMultipleGuardForm && (
            <div className="text-xs text-[#F5222D] pl-3 pb-1">Please select one</div>
          )}
        </div>
      )}
    </>
  );
};

export default WindowGuardSelectionSection;
