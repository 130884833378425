/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, Fragment, useEffect } from 'react';
import {
  HeaderKeyLease,
  HeaderKeyFee,
  isHeaderKeyLease,
  matchHeaderLease,
  matchHeaderFee
} from '../../../../modules/details/leaseForms/domain/LeaseSummary';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { number } from 'prop-types';

type HeaderMapFeeBase = {
  [key in HeaderKeyFee]: string;
};

type HeaderMapFee = HeaderMapFeeBase & {
  [key: string]: string; // Add index signature to allow any string key
};
interface LeaseSummaryProps {
  leaseDetails: any;
  leaseContent: {
    [key in HeaderKeyLease]?: string | any;
  };
  feesContent?: {
    fees: {
      securityDeposit: any;
      oneTime: OneTimeFee[];
    };
  };
  monthlyCharges: {
    monthlyCharges: MonthlyCharges[];
  };
}

type MonthlyCharges = {
  chargeAmount: number;
  chargeCode: string;
  chargeName: string;
  optional: boolean;
};

type OneTimeFee = {
  name: string;
  amount: number;
};
const LeaseSumary: FC<LeaseSummaryProps> = ({ monthlyCharges, leaseContent, feesContent }) => {
  useEffect(() => {
    localStorage.setItem('isFormRendered', 'true');
  }, []);

  return (
    <div style={{ paddingTop: '5%' }}>
      <div
        style={{
          borderStyle: 'solid',
          borderColor: '#E0E0E0',
          borderWidth: '2px',
          borderRadius: '4px'
        }}>
        <p
          style={{
            color: '#31343A',
            fontSize: '18px',
            fontWeight: 500,
            textTransform: 'uppercase',
            paddingLeft: '2%'
          }}>
          Lease Summary
        </p>

        <div style={{ padding: '0 2% 2% 2%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              {Object.keys(leaseContent).map((key, idx) => {
                if (!isHeaderKeyLease(key)) return null;
                return (
                  <Fragment key={idx}>
                    <div>
                      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        <li style={{ fontSize: '14px', color: '#31343A' }}>
                          {!Array.isArray(leaseContent[key]) &&
                            leaseContent[key] &&
                            matchHeaderLease(key as HeaderKeyLease)}
                        </li>
                        <li style={{ fontSize: '16px', color: '#31343A', paddingTop: '3%' }}>
                          {Array.isArray(leaseContent[key])
                            ? leaseContent[key].map((charge: any, chargeIdx: any) => (
                                <div key={chargeIdx}>
                                  <div style={{ fontSize: '14px' }}>{charge.chargeName}</div>
                                  <div style={{ paddingTop: '2%' }}>
                                    ${formatCurrency(charge.chargeAmount)}
                                  </div>
                                </div>
                              ))
                            : leaseContent[key]}
                        </li>
                      </ul>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            <div style={{ width: '50%' }}>
              <p style={{ fontSize: '12px', color: '#31343A', fontWeight: 600 }}>
                One Time Charges
              </p>
              {feesContent?.fees?.securityDeposit && (
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  <li style={{ fontSize: '14px', color: '#31343A' }}>Security Deposit</li>
                  <li style={{ fontSize: '16px', color: '#31343A', paddingTop: '3%' }}>
                    ${formatCurrency(feesContent?.fees?.securityDeposit.amount)}
                  </li>
                </ul>
              )}
              {feesContent?.fees?.oneTime?.map((fee: OneTimeFee) => (
                <Fragment key={fee.name}>
                  <div>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px', color: '#31343A' }}>{fee.name}</li>
                      <li style={{ fontSize: '16px', color: '#31343A', paddingTop: '3%' }}>
                        ${formatCurrency(fee.amount)}
                      </li>
                    </ul>
                  </div>
                </Fragment>
              ))}
              <div>
                <p style={{ fontSize: '12px', color: '#31343A', fontWeight: 600 }}>
                  Monthly Recurring Charges
                </p>

                {monthlyCharges.monthlyCharges?.map((charge: MonthlyCharges) => (
                  <div key={charge.chargeName}>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px', color: '#31343A' }}>{charge.chargeName}</li>
                      <li style={{ fontSize: '16px', color: '#31343A', paddingTop: '3%' }}>
                        ${formatCurrency(charge.chargeAmount)}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaseSumary;
