import { RouterProvider } from 'react-router-dom';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import AssociateProvider from './context/AssociateProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const client = new QueryClient();

import { router } from './router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  return (
    <QueryClientProvider client={client}>
      <AssociateProvider>
        <ReactQueryDevtools />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router}></RouterProvider>
        </LocalizationProvider>
      </AssociateProvider>
    </QueryClientProvider>
  );
}

export default App;
