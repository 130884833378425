import React, { useContext, useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { AssociateContext } from '../../../../context/AssociateContext';
import { Answer } from '../../../../modules/details/leaseDetails/domain/LeaseDetails';

const MultipleGuardForm: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { prepFormValue, updateFormStatus, answersWindowGuard } = useContext<any>(AssociateContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [checkboxes, setCheckboxes] = useState<any[]>([]);

  const createOptions = (answers: Answer[], method: number) => {
    const options = [...answers].map((value: Answer) => ({
      ...value,
      key: false
    }));

    method === 1 ? options.splice(2, 1) : options.pop();

    return options;
  };

  const selectOptionsToDisplay = () => {
    const optionsToDisplay =
      prepFormValue[0].id == 1
        ? createOptions(answersWindowGuard, 1)
        : createOptions(answersWindowGuard, 2);

    setCheckboxes(optionsToDisplay);
  };

  useEffect(() => {
    selectOptionsToDisplay();
  }, [prepFormValue]);

  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].key = !updatedCheckboxes[index].key;

    const selectedCheckboxes = updatedCheckboxes.filter((checkbox) => checkbox.key);

    updateFormStatus({
      prepFormValue,
      errorPrepForm: false,
      multipleWindowGuardForm: selectedCheckboxes,
      errorMultipleGuardForm: false,
      singleWindowGuardForm: []
    });

    setCheckboxes(updatedCheckboxes);
  };

  return (
    <div>
      {checkboxes?.map((option, index) => (
        <FormGroup key={index}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ paddingTop: 0 }}
                size="medium"
                checked={option.key}
                onChange={() => handleCheckboxChange(index)}
              />
            }
            label={option.answerText}
          />
        </FormGroup>
      ))}
    </div>
  );
};

export default MultipleGuardForm;
