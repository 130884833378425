/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeaseDetails } from './LeaseDetails';

export interface LeaseStatusCardProps {
  leaseDetails: LeaseDetails | undefined;
  leaseDetailsQuery?: any;
  voidLease?: any;
  voidLeaseQuery: any;
  forcedLeaseQuery: any;
  forcedLease: any;
  displayLeaseDetailsViewLocally: () => void;
}

export const isVoidEnabled = (residentStatus: string, requireWetSignature: boolean) => {
  if (
    residentStatus === 'generated' ||
    (residentStatus === 'lease-generated' && !requireWetSignature)
  ) {
    return true;
  } else if (residentStatus == 'lease-generated' && requireWetSignature) {
    return false;
  }
};

export const isForceCompleteEnabled = (residentStatus: string, requireWetSignature: boolean) =>
  residentStatus == 'renewal-ready' ||
  residentStatus == 'forced-mtm' ||
  (residentStatus == 'lease-generated' && requireWetSignature);

export const displayHoverAction = (residentStatus: string, requireWetSignature: boolean) => {
  return (
    residentStatus === 'generated' ||
    residentStatus === 'lease-initiated' ||
    residentStatus === 'initiated' ||
    (residentStatus === 'lease-generated' && !requireWetSignature)
  );
};

export const disableForcedCompleteByCommunity = (community: string) => {
  return community.toUpperCase().includes('CA043');
};

export type DialogDetailsType = {
  type: number;
  headerLabel: string | React.ReactNode;
  customWidth?: string;
  cancelOpt?: boolean;
  isLeaseMod?: boolean;
  body: any;
  btnLabel1: string;
  btnLabel2: string;
  isOpen: boolean;
  btnLabel2Callback: () => Promise<void> | any;
  handleCloseConfirmDialog?: () => void;
};
