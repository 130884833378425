/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { helpRequestStyles } from './styles';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

const NoticeToVacateMRI = ({
  tableBody,
  resolveNoticeToVacateQuery,
  selectedButtonIndex,
  handleResolve,
  idx
}: any) => {
  const disableResolve = () => {
    return enteredNTV == '';
  };

  const [enteredNTV, setEnteredNTV] = useState('');

  return (
    tableBody?.MRIStatus && (
      <div>
        <span style={helpRequestStyles.fontStyleMRI}>
          MRI Status *<span style={helpRequestStyles.boldStyle}>(Select one)</span>
        </span>

        <div style={{ ...helpRequestStyles.fontStyleMRI, fontSize: '16px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '40%' }}>
              <label
                style={{
                  display: 'flex'
                }}>
                <div style={helpRequestStyles.containerInput}>
                  <input
                    type="checkbox"
                    name="enteredNTV"
                    checked={enteredNTV === 'enteredNTV'}
                    onChange={() => setEnteredNTV('enteredNTV')}
                    className="checkbox-round"
                  />
                </div>
                <div>
                  <p style={{ paddingLeft: '8px' }}>Entered NTV in MRI</p>
                </div>
              </label>
            </div>
            <div>
              <label style={helpRequestStyles.centerLabel}>
                <div style={helpRequestStyles.containerInput}>
                  <input
                    type="checkbox"
                    name="notEnteredNTV"
                    checked={enteredNTV === 'notEnteredNTV'}
                    onChange={() => setEnteredNTV('notEnteredNTV')}
                    className="checkbox-round"
                  />
                </div>
                <p style={{ paddingLeft: '8px' }}>Not Entered NTV in MRI</p>
              </label>
            </div>
          </div>
          <div style={helpRequestStyles.buttonAlignment}>
            {resolveNoticeToVacateQuery.isFetching && selectedButtonIndex === idx ? (
              <Button
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                style={helpRequestStyles.DisabledButton}
                disabled={true}>
                <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ textTransform: 'capitalize' }}
                style={
                  disableResolve()
                    ? helpRequestStyles.DisabledButton
                    : helpRequestStyles.PrimaryButton
                }
                onClick={() => handleResolve(enteredNTV)}
                disabled={disableResolve()}>
                <p style={helpRequestStyles.resolveButtonStyle}>Resolve</p>
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default NoticeToVacateMRI;
