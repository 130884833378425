import React, { useContext } from 'react';
import { AssociateContext } from '../../../../context/AssociateContext';

interface WindowGuardDescriptionProps {
  description: boolean;
}

const WindowGuardDescription: React.FC<WindowGuardDescriptionProps> = ({ description }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { addendumPayload } = useContext<any>(AssociateContext);

  return (
    <div>
      {description ? (
        addendumPayload[0]?.description
      ) : (
        <span>{addendumPayload[0]?.disclaimer}</span>
      )}
    </div>
  );
};

export default WindowGuardDescription;
