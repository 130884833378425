/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { helpRequestStyles } from './styles';

const WindowGuardResolutionAndSelection = ({ residentResponse, isResolved }: any) => {
  return (
    <Fragment>
      <div>
        <span style={helpRequestStyles.fontStyleMRI}>Resident selections:</span>
        <div style={helpRequestStyles.fontStyleResponse}>
          {residentResponse?.map((response: any, id: number) => (
            <ul key={id} style={helpRequestStyles.ulStyle}>
              <li>{response}</li>
            </ul>
          ))}
        </div>
      </div>
      <>
        <div style={{ padding: '20px 0' }}>
          <p
            style={{
              ...helpRequestStyles.fontStyleMRI,
              lineHeight: '0px'
            }}>
            Resolution
          </p>
          <span style={{ ...helpRequestStyles.fontStyleResponse, padding: '0px' }}>
            {!isResolved
              ? 'Enter a service ticket for maintenance response, and then resolve the help request.'
              : 'Entered a service ticket for maintenance response.'}
            <br />
            Note: Maintenance inspection may result in not installing a window guard due to
            jurisdictional requirements.
          </span>
        </div>
      </>
    </Fragment>
  );
};

export default WindowGuardResolutionAndSelection;
