/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import WindowGuardCheckBoxes from './utils/WindowGuardCheckBoxes';
import WindowGuardRadio from './utils/WindowGuardRadio';
import {
  NYC,
  footerMontgomery,
  footerYonkers,
  knob,
  montgomery,
  yonkers
} from '../../../../../modules/details/leaseForms/domain/WindowGuardModification';
import FormsByType from '../components/FormsByType';

interface windowGuardProps {
  type: string;
  form: any;
  handleChange: (value: string, fieldName: string, formName: string) => void;
}

const WindowGuard: FC<windowGuardProps> = ({ type, form, handleChange }) => {
  const [checkboxOpt] = useState<any[]>(
    form?.formSchema?.fields?.filter((type: any) => type.fieldType === 'checkbox')
  );

  const [radioOpt] = useState<any[]>(
    form?.formSchema?.fields?.filter((type: any) => type.fieldType === 'radio')
  );

  const getFooter = (type: string) => {
    const COMMUNITY_NAME: any = {
      'Window Guards - Yonkers/Harrison': footerYonkers(),
      'Window Guards - Montgomery County': footerMontgomery()
    };

    return COMMUNITY_NAME[type] || <></>;
  };
  const getContent = (type: string) => {
    const COMMUNITY_NAME: any = {
      'Window Guards - Yonkers/Harrison': yonkers(),
      'Window Guards - Montgomery County': montgomery(),
      'Window Guard Form - NYC': NYC(),
      'Stove Knob Covers': knob()
    };

    return COMMUNITY_NAME[type] || <></>;
  };

  const getPrepForm = (type: string) => {
    const COMMUNITY_NAME: any = {
      'Window Guards - Yonkers/Harrison': (
        <WindowGuardRadio answers={radioOpt ? radioOpt[0] : []} form={form} />
      ),
      'Window Guards - Montgomery County': (
        <WindowGuardRadio answers={radioOpt ? radioOpt[0] : []} form={form} />
      ),
      'Window Guard Form - NYC': <></>,
      'Stove Knob Covers': <></>
    };

    return COMMUNITY_NAME[type] || <></>;
  };

  const getForm = (type: string) => {
    const COMMUNITY_NAME: any = {
      'Window Guards - Yonkers/Harrison': (
        <WindowGuardRadio answers={radioOpt ? radioOpt[1] : []} form={form} />
      ),
      'Window Guards - Montgomery County': (
        <WindowGuardCheckBoxes answers={checkboxOpt ? checkboxOpt : []} form={form} />
      ),
      'Window Guard Form - NYC': (
        <WindowGuardRadio answers={radioOpt ? radioOpt[0] : []} form={form} />
      ),
      'Stove Knob Covers': <WindowGuardCheckBoxes answers={form?.formSchema?.fields} form={form} />
    };

    return COMMUNITY_NAME[type] || <></>;
  };

  return (
    <div>
      <div>{getContent(type)}</div>
      {!type.includes('Stove') && (
        <p style={{ fontWeight: 'bold' }}>{radioOpt && radioOpt[0]?.fieldName}</p>
      )}
      {getPrepForm(type)}
      {type.includes('Montgomery') && (
        <p style={{ fontWeight: 'bold' }}>{checkboxOpt && checkboxOpt[0]?.fieldName}</p>
      )}
      {type.includes('Yonkers') && (
        <p style={{ fontWeight: 'bold' }}>{radioOpt && radioOpt[1]?.fieldName}</p>
      )}
      {getForm(type)}
      <FormsByType form={form} handleChange={handleChange} />
      <div>{getFooter(type)}</div>
    </div>
  );
};

export default WindowGuard;
