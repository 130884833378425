import React, { Fragment } from 'react';

export const resolutionText = () => {
  return (
    <Fragment>
      <p style={{ marginBottom: 0 }}>
        <strong>If the document is in “Generated” status and you need to make a change:</strong>
      </p>
      <ul style={{ margin: 0 }}>
        <li>
          Select <strong>Void in DocuSign</strong>.
        </li>
        <li>Complete your changes in MRI.</li>
        <li>Regenerate documents from MRI.</li>
      </ul>
      <p style={{ marginBottom: 0 }}>
        <strong>If the document is in “Completed” status:</strong>
      </p>
      <ul style={{ margin: 0 }}>
        <li>
          <strong>Complete this process in MRI.</strong>
        </li>
        <li>
          <strong>Resolve</strong> this request.
        </li>
      </ul>
      <p style={{ marginBottom: 0 }}>
        <strong>If the document is in “Expired” status:</strong>
      </p>
      <ul style={{ margin: 0 }}>
        <li>
          <strong>Resolve</strong> this request.
        </li>
        <li>
          <strong>Regenerate documents from MRI.</strong>
        </li>
      </ul>
      <p style={{ marginBottom: 0 }}>
        <strong>If the document is in &quot;Voided&quot; status:</strong>
      </p>
      <div>
        Scroll to the Resolved Help Requests at the bottom of this page to see if an associate
        voided the document.
      </div>
      <p style={{ marginBottom: 0 }}>If an associate voided the document:</p>
      <ul style={{ margin: 0 }}>
        <span>
          <li>
            <strong>Resolve </strong> this request.
          </li>
        </span>
      </ul>
      <p style={{ marginBottom: 0 }}>
        If an associate did not void the document, then the resident completed the void action.
      </p>
      <ul style={{ margin: 0 }}>
        <li>Contact Resident to identify reason for the decline.</li>
        <li>Determine if an MRI change is required and/or new documents generated.</li>
        <li>
          <strong>Resolve</strong> this request.
        </li>
      </ul>
    </Fragment>
  );
};

export const emailNullResolutionText = () => {
  return (
    <Fragment>
      <p style={{ marginBottom: 0 }}>This lease has been generated for manual signature.</p>
      <p style={{ marginBottom: 0 }}>
        <strong>After all manual signatures have been completed:</strong>
      </p>{' '}
      <ol style={{ margin: 0 }}>
        <li>Complete this process in MRI by checking &ldquo;Signed&rdquo; under Amend Lease.</li>
        <li>Upload the document to the resident&rsquo;s account in Salesforce.</li>
        <li>
          <strong>Resolve </strong> this request.
        </li>
      </ol>
      <p style={{ marginBottom: 0 }}>
        <strong>If you need to make a change to the lease document:</strong>
      </p>
      <ol style={{ margin: 0 }}>
        <li>Make the change in MRI.</li>
        <li>Re-generate the lease document.</li>
        <li>
          <strong>Resolve</strong> this request. A new task card will be created for your new
          document.
        </li>
        <li>Ensure you select the most recently generated lease for manual signature</li>
      </ol>
      <p>
        <strong>NOTE: </strong>
        You will not be able to void or resend leases sent for manual signature.
      </p>
    </Fragment>
  );
};
