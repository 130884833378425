/* eslint-disable */
// @ts-ignore
import { leaseDetailsStyles } from './styles';
import dayjs from 'dayjs';

//utils
// @ts-ignore
import filterCharges from '../../../../utils/filterCharges';
// @ts-ignore
import parseToDecimalOrInt from '../../../../utils/parseToDecimalOrInt';

// navigation
import { useParams } from 'react-router-dom';

// material
import { CircularProgress } from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import ConcessionCard from '../leaseDetailsCards/ConcessionCard';
import OneTimeFeeCard from '../leaseDetailsCards/OneTimeFeeCard';
import React from 'react';
import NYC421Card from '../leaseDetailsCards/NYC421Card';
import { disableForcedCompleteByCommunity } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';
const LeaseInformationCard = ({
  data,
  openSummaryCard,
  isRenewalPlan = false,
  residentStatus,
  offersQuery,
  handleGenerateLease,
  generateLeaseQuery,
  bestOffer,
  hideLeaseEndDate,
  requireWetSignature,
  isNYC421a = false
}: any) => {
  const formatCurrency = (input: any) => {
    return input?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  };

  const { id } = useParams();

  const getSubTotalCharges = () => {
    let totalCharges = 0;

    const notOptional = data?.monthlyCharges?.filter(
      ({ optional }: any) => optional?.includes('N') || optional == null
    );

    const sumWithInitial = notOptional?.reduce((acc: any, currentVal: any) => {
      const chargeAmount = parseFloat(currentVal.chargeAmount);
      return !isNaN(chargeAmount) ? acc + chargeAmount : acc;
    }, totalCharges);

    const formatSum = parseToDecimalOrInt(sumWithInitial);

    return formatCurrency(+formatSum);
  };

  const renderVariance = (variancePercentage: any) => {
    if (Math.sign(variancePercentage) === -1) {
      return (
        <>
          <NorthIcon
            style={{
              color: '#52C41A',
              width: '16px',
              height: '18px',
              transform: 'rotateX(180deg)'
            }}
          />
          {variancePercentage}%
        </>
      );
    } else {
      return (
        <>
          <NorthIcon style={{ color: '#F5222D', width: '16px', height: '18px' }} />+
          {Math.abs(variancePercentage)}%
        </>
      );
    }
  };

  return (
    <div style={leaseDetailsStyles.DetailsCard}>
      <div
        style={
          isRenewalPlan ? leaseDetailsStyles.RenewCardTopBg : leaseDetailsStyles.LeaseCardTopBg
        }></div>
      <div style={leaseDetailsStyles.CardContent}>
        <h2 style={leaseDetailsStyles.Card_H2}>
          {isRenewalPlan ? 'Renewal Plan' : 'Current Lease'}{' '}
        </h2>
        <span style={leaseDetailsStyles.Info}>
          {isRenewalPlan ? (
            <span
              style={{
                display: `${residentStatus === 'renewal-completed' ? 'none' : 'block'}`
              }}>
              The best rate is for{' '}
              <b>
                {bestOffer}.<br />
                Click Offer Pricing
              </b>{' '}
              below to select a different term.
            </span>
          ) : (
            'These are the current lease details of the selected unit.'
          )}
        </span>
        <div
          style={{
            fontFamily: 'ProximaNova',
            fontSize: '20px',
            fontWeight: '600',
            margin: '30px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: isRenewalPlan ? '13px' : '34px'
          }}>
          {!hideLeaseEndDate && (
            <>
              <span
                style={{
                  color: isRenewalPlan ? '#00A3E0' : '#C64D5B',
                  fontFamily: 'ProximaNova',
                  fontSize: '20px',
                  fontWeight: '600',
                  paddingRight: '5px'
                }}>
                Lease Term&nbsp;
              </span>
              <b>{data?.leaseTerm}</b>
            </>
          )}
          {isRenewalPlan && !isNYC421a && (
            <div
              style={{
                fontFamily: 'ProximaNova',
                fontSize: '16px',
                fontWeight: '600',
                paddingLeft: '15px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center'
              }}>
              {renderVariance(data?.variancePercentage)}
            </div>
          )}
        </div>
        <p style={{ fontSize: '20px', fontWeight: '600', marginTop: 0, marginBottom: '22px' }}>
          {formatCurrency(data?.totalCharges)}
          {isNYC421a && isRenewalPlan ? (
            ' + 421-a Surcharge* / month'
          ) : (
            <span style={{ fontSize: '14px', color: '#31343A', opacity: '0.5' }}> / month</span>
          )}
        </p>
        {data?.leaseStartDate && (
          <p style={{ margin: '0', fontSize: '16px', fontWeight: '300' }}>
            <span style={leaseDetailsStyles.LabelFieldDate}>Lease Start Date:</span>{' '}
            <span style={leaseDetailsStyles.ValueFieldDate}>
              {dayjs(data?.leaseStartDate).format('MMM Do, YYYY')}
            </span>
          </p>
        )}
        <p
          style={{
            margin: '0',
            paddingTop: !data?.leaseStartDate ? '28px' : '24px',
            paddingBottom: data?.leaseStartDate ? '20px' : '42px'
          }}>
          <span style={leaseDetailsStyles.LabelFieldDate}>
            {hideLeaseEndDate ? 'Rate Expires:' : 'Lease End Date:'}
          </span>{' '}
          <span style={leaseDetailsStyles.ValueFieldDate}>
            {dayjs(data?.leaseExpirationDate).format('MMM Do, YYYY')}
          </span>
        </p>
        <div style={leaseDetailsStyles.TableView}>
          <div style={leaseDetailsStyles.Divider}></div>
          <div style={leaseDetailsStyles.Row}>
            <span style={leaseDetailsStyles.LabelField}>Base rent:</span>
            <span style={leaseDetailsStyles.ValueField}>
              {formatCurrency(data?.baseRent)} / Month
            </span>
          </div>
          {data?.bulkInternet && (
            <div style={leaseDetailsStyles.Row}>
              <span style={leaseDetailsStyles.LabelField}>Bulk Internet &amp; Video:</span>
              <span style={leaseDetailsStyles.ValueField}>+${data?.bulkInternet}</span>
            </div>
          )}
          {data &&
            filterCharges('no-optional', data?.monthlyCharges, isNYC421a, isRenewalPlan)?.map(
              (option: any, idx: number) => {
                return (
                  <div style={leaseDetailsStyles.Row} key={idx}>
                    <span style={leaseDetailsStyles.LabelField}>
                      {option.chargeDescription.startsWith('421')
                        ? '421-a Surcharge' + (isRenewalPlan ? '' : '*')
                        : option.chargeDescription}
                    </span>
                    <span style={leaseDetailsStyles.ValueField}>
                      {option.chargeAmount ? (
                        <>
                          {option.chargeAmount < 0 ? '-' : '+'}
                          {formatCurrency(option.chargeAmount)}
                        </>
                      ) : (
                        isRenewalPlan && (
                          <>{option.chargeDescription.startsWith('421') && 'Review lease*'}</>
                        )
                      )}
                    </span>
                  </div>
                );
              }
            )}
          <div style={leaseDetailsStyles.Divider}></div>
          <div style={{ ...leaseDetailsStyles.Row, whiteSpace: 'nowrap' }}>
            <span style={{ ...leaseDetailsStyles.LabelField, fontSize: '16px' }}>
              Monthly Charges Sub-Total:
            </span>
            <span style={{ ...leaseDetailsStyles.ValueField, paddingLeft: '2%' }}>
              {getSubTotalCharges() + (isNYC421a && isRenewalPlan ? ' + 421-a Surcharge*' : '')}
            </span>
          </div>
          <h2 style={{ fontSize: '16px', marginTop: '55px', textAlign: 'left' }}>
            Optional Add-ons
          </h2>
          {data &&
            data.monthlyCharges
              ?.filter((option: any) => option.optional === 'Y')
              .map((option: any) => {
                return (
                  <div style={leaseDetailsStyles.Row} key={option.chargeCode}>
                    <span style={leaseDetailsStyles.LabelField}>{option.chargeDescription}</span>
                    <span style={leaseDetailsStyles.ValueField}>
                      {option.chargeAmount < 0 ? '' : '+'}
                      {formatCurrency(option.chargeAmount)}
                    </span>
                  </div>
                );
              })}
          <div style={leaseDetailsStyles.Divider}></div>
          <div style={leaseDetailsStyles.Row}>
            <span style={{ ...leaseDetailsStyles.LabelField, fontSize: '16px', fontWeight: 600 }}>
              Total Monthly Charges:
            </span>
            <span style={leaseDetailsStyles.ValueField}>
              {formatCurrency(data?.totalCharges)}
              {isNYC421a && isRenewalPlan && ' + 421-a Surcharge*'}
            </span>
          </div>
        </div>
        {isRenewalPlan ? (
          <div>
            <div>
              {filterCharges('one-time-fee', data?.monthlyCharges).length > 0 && (
                <OneTimeFeeCard data={data} />
              )}
              {isNYC421a && (
                <div style={{ ...leaseDetailsStyles.CenterRows, paddingTop: '5%' }}>
                  <NYC421Card isRenewalPlan={isRenewalPlan} />
                </div>
              )}
              {data?.concession && (
                <div style={{ ...leaseDetailsStyles.CenterRows, paddingTop: '5%' }}>
                  <ConcessionCard data={data?.concession} />
                </div>
              )}
              <button
                onClick={openSummaryCard}
                style={
                  offersQuery.isFetching ||
                  offersQuery.data?.offers?.length === 0 ||
                  residentStatus === 'renewal-completed'
                    ? leaseDetailsStyles.DisableGeneralButton
                    : leaseDetailsStyles.GeneralButton
                }
                disabled={offersQuery.data?.offers?.length === 0}>
                {offersQuery.isFetching ? (
                  <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                ) : (
                  'Select Lease Term'
                )}
              </button>
            </div>
            <div>
              {(residentStatus === 'renewal-ready' ||
                residentStatus === 'forced-mtm' ||
                (residentStatus === 'lease-generated' && requireWetSignature)) &&
                (!generateLeaseQuery.isFetching ? (
                  <button
                    disabled={disableForcedCompleteByCommunity(id ?? '0')}
                    onClick={handleGenerateLease}
                    style={
                      disableForcedCompleteByCommunity(id ?? '0')
                        ? leaseDetailsStyles.DisableGeneralButton
                        : leaseDetailsStyles.GeneralButton
                    }>
                    {'Generate Lease in DocuSign'}
                  </button>
                ) : (
                  <button style={{ ...leaseDetailsStyles.DisableGeneralButton }} disabled={true}>
                    <div style={leaseDetailsStyles.CenterRows}>
                      <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                    </div>
                  </button>
                ))}
            </div>
          </div>
        ) : (
          <div>
            {isNYC421a && (
              <div style={{ ...leaseDetailsStyles.CenterRows, paddingTop: '5%' }}>
                <NYC421Card isRenewalPlan={isRenewalPlan} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaseInformationCard;
